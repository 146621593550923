import {
  Button,
  Collapse,
  Form,
  FormInstance,
  Input,
  message,
  Typography
} from 'antd';
import { SHA256 } from 'crypto-js';
import { FC } from 'react';
import request from '@/apps/utils';

const layout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 12
  }
};

interface ChangePasswordProps {
  onCancel: () => void;
  form: FormInstance<any>;
}

const ChangePassword: FC<ChangePasswordProps> = ({ onCancel, form }) => {
  const handleSubmit = (values: any) => {
    const body = JSON.stringify({
      password: SHA256(values.password).toString(),
      oldPassword: SHA256(values.oldPassword).toString()
    });
    request({
      url: '/auth/change-password',
      config: { method: 'PUT', body },
      messageTitle: '修改密码'
    })
      .then(() => {
        onCancel();
        form.resetFields();
        message.success({ content: '修改密码成功' });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Collapse ghost>
      <Collapse.Panel header="修改密码" key="1">
        <Form
          {...layout}
          form={form}
          size="large"
          onFinish={(values) => handleSubmit(values)}
        >
          <Form.Item
            name="oldPassword"
            label={<Typography.Text type="secondary">旧密码</Typography.Text>}
            rules={[
              {
                required: true,
                message: '请输入旧密码'
              }
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            name="password"
            label={<Typography.Text type="secondary">新密码</Typography.Text>}
            rules={[
              {
                required: true,
                message: '请输入新密码'
              },
              {
                min: 8,
                max: 8,
                message: '请输入8位字符'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('oldPassword') === value) {
                    return Promise.reject(
                      new Error('不能与旧密码相同，请重新输入')
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label={<Typography.Text type="secondary">二次确定</Typography.Text>}
            rules={[
              {
                required: true,
                message: '请确认新密码'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('password') !== value) {
                    return Promise.reject(
                      new Error('两次输入不一致，请重新输入')
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 20 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default ChangePassword;
