import {
  forwardRef,
  // ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  ReloadOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons';
import { Loading } from '@/components';
import {
  DocumentationType,
  NetronRef,
  PropertiesType,
  SearchResult
} from '../types';
import {
  Wrapper,
  RenderContent,
  Content,
  Toolbox,
  ErrorMsg,
  ErrorIcon
} from './styles';

type NetronProps = {
  files: File[] | null;
  // uploader: ReactNode;
  showAttributes: boolean;
  showInitializers: boolean;
  showNames: boolean;
  onRendered?: () => unknown;
  onSearch?: (data: SearchResult) => unknown;
  onShowModelProperties?: (data: PropertiesType) => unknown;
  onShowNodeProperties?: (data: PropertiesType) => unknown;
  onShowNodeDocumentation?: (data: DocumentationType) => unknown;
};

const Netron = forwardRef<NetronRef, NetronProps>(
  (
    {
      files,
      // uploader,
      showAttributes,
      showInitializers,
      showNames,
      onRendered,
      onSearch,
      onShowModelProperties,
      onShowNodeProperties,
      onShowNodeDocumentation
    },
    ref
  ) => {
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rendered, setRendered] = useState(false);
    const iframe = useRef<HTMLIFrameElement>(null);

    const [error, setError] = useState<string | undefined>(undefined);

    const handler = useCallback(
      (event: MessageEvent) => {
        if (event.data) {
          const { type, data } = event.data;
          switch (type) {
            case 'status':
              switch (data) {
                case 'ready':
                  return setReady(true);
                case 'loading':
                  return setLoading(true);
                case 'rendered':
                  setLoading(false);
                  setRendered(true);
                  onRendered?.();
                  return undefined;
                default:
                  return undefined;
              }
            case 'search':
              return onSearch?.(data);
            case 'cancel':
              return setLoading(false);
            case 'error':
              setError(data);
              setLoading(false);
              return undefined;
            case 'show-model-properties':
              return onShowModelProperties?.(data);
            case 'show-node-properties':
              return onShowNodeProperties?.(data);
            case 'show-node-documentation':
              return onShowNodeDocumentation?.(data);
            default:
              return undefined;
          }
        }
        return undefined;
      },
      [
        onRendered,
        onSearch,
        onShowModelProperties,
        onShowNodeProperties,
        onShowNodeDocumentation
      ]
    );

    const dispatch = useCallback((type: string, data?: unknown) => {
      iframe.current?.contentWindow?.postMessage(
        { type, data },
        `${window.location.protocol}//${window.location.host}`
      );
    }, []);

    useEffect(() => {
      window.addEventListener('message', handler);
      dispatch('ready');
      return () => window.removeEventListener('message', handler);
    }, [handler]);

    useEffect(
      () => (ready && dispatch('change-files', files)) || undefined,
      [dispatch, files, ready]
    );
    useEffect(
      () =>
        (ready && dispatch('toggle-attributes', showAttributes)) || undefined,
      [dispatch, showAttributes, ready]
    );
    useEffect(
      () =>
        (ready && dispatch('toggle-initializers', showInitializers)) ||
        undefined,
      [dispatch, showInitializers, ready]
    );
    useEffect(
      () => (ready && dispatch('toggle-names', showNames)) || undefined,
      [dispatch, showNames, ready]
    );

    useImperativeHandle(ref, () => ({
      export(type) {
        dispatch('export', type);
      },
      search(value) {
        dispatch('search', value);
      },
      select(item) {
        dispatch('select', item);
      },
      showModelProperties() {
        dispatch('show-model-properties');
      },
      showNodeDocumentation(data) {
        dispatch('show-node-documentation', data);
      }
    }));

    const content = useMemo(() => {
      if (!ready || loading) {
        return <Loading />;
      }
      if (ready && !rendered) {
        return (
          <ErrorMsg
            icon={<ErrorIcon />}
            status="error"
            subTitle={error || 'error'}
          />
        );
      }
      return null;
    }, [ready, loading, rendered, error]);

    return (
      <Wrapper>
        {content}
        <RenderContent show={!loading && rendered}>
          <Toolbox
            reversed
            items={[
              {
                key: 'restore-size',
                icon: <ReloadOutlined />,
                tooltip: '重置大小',
                onClick: () => dispatch('zoom-reset')
              },
              {
                key: 'zoom-out',
                icon: <ZoomOutOutlined />,
                tooltip: '缩小',
                onClick: () => dispatch('zoom-out')
              },
              {
                key: 'zoom-in',
                icon: <ZoomInOutlined />,
                tooltip: '放大',
                onClick: () => dispatch('zoom-in')
              }
            ]}
          />
          <Content>
            <iframe
              title="iframe"
              ref={iframe}
              src="/graph/index.html"
              frameBorder={0}
              scrolling="no"
              marginWidth={0}
              marginHeight={0}
            />
          </Content>
        </RenderContent>
      </Wrapper>
    );
  }
);

export default Netron;
