import { Button } from 'antd';
import styled from '@/theme';

export const Wrapper = styled.div`
  background: #fff;
  position: relative;
`;

export const UploadWrapper = styled.div`
  display: flex;
  height: 350px;
  margin: 0 auto;
  border-bottom: 1px solid rgb(245 246 249);
`;

export const Btn = styled(Button)`
  margin: 10px;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  .error {
    color: ${(props) => props.theme.palette.primary.error};
  }
`;
