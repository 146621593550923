import { notification } from 'antd';

export const openNotification = (title: string, desc: string = '暂无') => {
  notification.error({
    message: title,
    description: desc,
    duration: 10,
    style: {
      maxHeight: 300,
      overflow: 'scroll'
    }
  });
};
