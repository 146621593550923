import { RouteType } from '@/components/routers/types';
import AlgorithmSetting from './Algorithm';

const settingRoutes: RouteType[] = [
  {
    key: 'algorithm',
    path: '/manage-algorithm',
    component: AlgorithmSetting
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/manage-algorithm'
  }
];

export default settingRoutes;
