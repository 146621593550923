import { Result, Typography } from 'antd';
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  SyncOutlined
} from '@ant-design/icons';
import styled from '@/theme';

export const Btn = styled(Typography.Text).attrs({ ellipsis: true })`
  color: ${(props) => props.theme.palette.primary.main};
`;

export const IconWrapper = styled.div`
  min-height: 669px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .wrapper {
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desc {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const LoadingIcon = styled(LoadingOutlined)`
  font-size: 24px;
  color: ${(props) => props.theme.palette.primary.main};
  margin-right: 8px;
`;

export const ErrorIcon = styled(ExclamationCircleOutlined)`
  font-size: 36px !important;
`;

export const ErrorMsg = styled(Result)`
  .ant-result-subtitle {
    word-break: break-all;
  }
`;

export const Stop = styled(StopOutlined)`
  font-size: 36px;
  color: ${(props) => props.theme.palette.primary.main};
  &.icon {
    margin-right: 8px;
    color: ${(props) => props.theme.palette.primary.error};
  }
`;

export const Retry = styled(SyncOutlined)`
  font-size: 36px;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const Wrapper = styled.div`
  .ant-anchor-ink::before {
    width: 0;
  }
  .ant-anchor-link {
    padding-left: 0;
  }
`;
