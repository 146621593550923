import { ChunkType } from '../../types';
import * as actionTypes from './actionTypes';

export const setChunks = (chunks: ChunkType[], fileId: string) => ({
  type: actionTypes.UPDATE_CHUNKS,
  value: chunks,
  fileId
});

export const setFileStatus = (status: string, fileId: string) => ({
  type: actionTypes.UPDATE_STATUS,
  value: status,
  fileId
});

export const setLabel = (label: string, fileId: string) => ({
  type: actionTypes.UPDATE_LABEL,
  value: label,
  fileId
});

export const initialize = (label: string, fileId: string) => ({
  type: actionTypes.INITIALIZE_FILE,
  value: label,
  fileId
});

export const setPercent = (percent: string, fileId: string) => ({
  type: actionTypes.UPDATE_PERCENT,
  value: percent,
  fileId
});

export const setHashAndOriginFile = (
  fileHash: string,
  originFile: File,
  fileId: string
) => ({
  type: actionTypes.UPDATE_HASH_AND_ORIGIN_FILE,
  value: { fileHash, originFile },
  fileId
});

export const removeFile = (fileId: string) => ({
  type: actionTypes.REMOVE_FILE,
  fileId
});

export const resetFiles = () => ({ type: actionTypes.RESET_FILES, fileId: '' });
