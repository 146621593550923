import {
  StockOutlined,
  BarChartOutlined,
  AppstoreOutlined,
  DatabaseOutlined,
  ApartmentOutlined
} from '@ant-design/icons';
import { RouteType } from '@/components/routers/types';
import ModelDetail from '../modelDetail';
import ModelEvaluate from '../modelEvaluate';
import TrainDetail from '../trainDetail';
import { Detail as DatasetDetail } from '../../dataset/datasetDetail';
import Graph from '../graph';

const trainRoutes: RouteType[] = [
  {
    key: 'trainDetail',
    path: '/detail',
    component: TrainDetail,
    sidebarName: '训练详情',
    icon: <StockOutlined />
  },
  {
    key: 'modelEvaluate',
    path: '/evaluate',
    component: ModelEvaluate,
    sidebarName: '模型评估',
    icon: <BarChartOutlined />
  },
  {
    key: 'modelDetail',
    path: '/model',
    component: ModelDetail,
    sidebarName: '模型使用',
    icon: <AppstoreOutlined />
  },
  {
    key: 'datasetDetail',
    path: '/dataset',
    component: DatasetDetail,
    sidebarName: '数据集详情',
    icon: <DatabaseOutlined />
  },
  {
    key: 'modelGraph',
    path: '/graph',
    component: Graph,
    sidebarName: '网络结构',
    icon: <ApartmentOutlined />
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/detail'
  }
];

export default trainRoutes;
