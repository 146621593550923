import React, { createContext } from 'react';
import { UserType } from './types';
import useProvideAuth from './useProvideAuth';

interface AuthContextType {
  currentUser: UserType | null;
  login: (data: { username: string; password: string }, cb: () => void) => void;
  logout: (cb: () => void) => void;
  error: string | undefined;
  setUser: (user: UserType) => void;
}

export const authContext = createContext<AuthContextType>({
  currentUser: null,
  login: () => {},
  logout: () => {},
  error: undefined,
  setUser: () => {}
});

interface ProvideAuthProps {
  children: React.ReactNode;
}

const ProvideAuth: React.FC<ProvideAuthProps> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default ProvideAuth;
