/* eslint-disable jsx-a11y/alt-text */
import { FC } from 'react';
import { Empty, Typography } from 'antd';
import { AlgorithmType } from '@/apps/dashboard/model/modelCreate/types';
import algorithmDesc from './algorithmDesc';

interface DescProps {
  algorithm?: AlgorithmType;
}

const Desc: FC<DescProps> = ({ algorithm }) => {
  if (!algorithm) {
    return <Empty description="暂无数据" />;
  }
  const { Paragraph, Title } = Typography;
  let desc: { desc: string; img?: string } = { desc: '' };
  const { name } = algorithm;
  name
    .toUpperCase()
    .split('_')
    .forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(algorithmDesc, item)) {
        desc = algorithmDesc[item];
      }
    });
  return (
    <>
      <Typography>
        <Paragraph>{desc.desc}</Paragraph>
        <Title level={5}>模型结构</Title>
        <img src={desc.img} />
      </Typography>
    </>
  );
};

export default Desc;
