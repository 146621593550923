import { useRouteMatch } from 'react-router-dom';
import Switch from '@/components/routers';
import settingRoutes from './routes';

const Setting = () => {
  const { path } = useRouteMatch();
  return <Switch routes={settingRoutes} basePath={path} />;
};

export default Setting;
