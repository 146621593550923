import { useRouteMatch } from 'react-router-dom';
import Switch from '@/components/routers';
import routes from './routes';

const Account = () => {
  const { path } = useRouteMatch();
  return <Switch routes={routes} basePath={path} />;
};

export default Account;
