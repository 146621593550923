import { Button, Form, message } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { find, some } from 'lodash';
import { ChunkType, DatasetType, FormValueType, StatusMap } from '../types';
import request from '@/apps/utils';
import { handleFileUpload, openNotification } from './utils';
import BaseForm from './BaseForm';
import reducer from './store/reducer';
import LocalUpload from './localUpload';
import OnlineUpload from './onlineUpload';
import { actionCreators } from './store';
import { LinkBtn } from './styles';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 12
  }
};

const DatasetForm = () => {
  const [form] = Form.useForm<FormValueType>();
  const history = useHistory();
  const uploadType = Form.useWatch('uploadType', form);
  const taskType = Form.useWatch('taskType', form);
  const [dataset, setDataset] = useState<DatasetType>();
  const [fileList, dispatch] = useReducer(reducer, []);
  const [showPrompt, setShowPrompt] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (some(fileList, { status: StatusMap.active })) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
  }, [fileList]);

  useEffect(() => {
    const listener = (ev: any) => {
      ev.preventDefault();
      ev.returnValue = '文件上传中，确定离开吗？';
    };
    if (showPrompt) {
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
    }
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [showPrompt]);

  const onClick = (linkId: string) => {
    history.push(`/manage-dataset/${linkId}`);
  };

  const onFinish = (values: FormValueType) => {
    setSubmitting(true);
    setShowPrompt(true);
    try {
      const body = JSON.stringify({
        title: values.title,
        dataType: values.dataType,
        taskType: values.taskType,
        tags: values.tags,
        uploadType: values.uploadType,
        fileInfo:
          values.uploadType === 'local'
            ? values.files.map((item) => {
                const label = Array.isArray(item.label)
                  ? item.label.join(',')
                  : item.label;
                const file = find(fileList, { fileId: item.fileId });
                if (file) {
                  return {
                    label,
                    file: file.originFile?.name,
                    fileHash: file.fileHash
                  };
                }
                return undefined;
              })
            : values.files.map((item) => ({
                label: Array.isArray(item.label)
                  ? item.label.join(',')
                  : item.label,
                file: item.fileAddress
              }))
      });

      request({ url: '/dataset', config: { method: 'POST', body } }).then(
        ({ data }) => {
          setDataset(data);
          const fileUpload = fileList.map((item) => {
            const itemLabel = Array.isArray(item.label)
              ? item.label.join(',')
              : item.label;
            return handleFileUpload({
              fileHash: item.fileHash,
              fileName: item.originFile?.name,
              chunks: item.chunks || [],
              subsetId:
                find(data.subsets, { dataLabel: itemLabel })?.subsetId || '',
              setChunks: (value: ChunkType[]) => {
                dispatch(actionCreators.setChunks(value, item.fileId));
              },
              setFileStatus: (status: string) => {
                dispatch(actionCreators.setFileStatus(status, item.fileId));
              }
            });
          });
          Promise.all(fileUpload)
            .then(() => {
              message.success({
                content: (
                  <>
                    新建数据集成功，
                    <LinkBtn onClick={() => onClick(data.datasetId || '')}>
                      立即查看
                    </LinkBtn>
                  </>
                )
              });
              form.resetFields();
              dispatch(actionCreators.resetFiles());
              setSubmitting(false);
            })
            .catch((err) => {
              setSubmitting(false);
              openNotification(String(err));
            });
        }
      );
    } catch (err: any) {
      setSubmitting(false);
      openNotification(String(err));
    }
  };

  return (
    <>
      <Form
        {...layout}
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={{ dataType: 'text' }}
      >
        <BaseForm form={form} />
        {uploadType === 'local' && (
          <LocalUpload
            form={form}
            dispatch={dispatch}
            fileList={fileList}
            dataset={dataset}
            taskType={taskType}
          />
        )}
        {uploadType === 'online' && (
          <OnlineUpload taskType={taskType} form={form} />
        )}
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={showPrompt || submitting}
          >
            上传
          </Button>
        </Form.Item>
      </Form>
      <Prompt when={showPrompt} message={() => '文件上传中，确定离开吗？'} />
    </>
  );
};
export default DatasetForm;
