import { find, uniqBy } from 'lodash';
import { ActionType, FileType, StatusMap } from '../../types';
import * as actionTypes from './actionTypes';

const reducer = (state: FileType[] = [], action: ActionType): FileType[] => {
  const file = find(state, { fileId: action.fileId });
  switch (action.type) {
    case actionTypes.RESET_FILES: {
      return [];
    }
    case actionTypes.INITIALIZE_FILE: {
      return file
        ? state
        : [
            ...state,
            {
              fileId: action.fileId,
              label: action.value,
              status: StatusMap.wait,
              hashPercent: 0
            }
          ];
    }
    case actionTypes.REMOVE_FILE: {
      return [...state].filter((item) => item.fileId !== action.fileId);
    }
    case actionTypes.UPDATE_HASH_AND_ORIGIN_FILE: {
      return file
        ? uniqBy([{ ...file, ...action.value }, ...state], 'fileId')
        : state;
    }
    case actionTypes.UPDATE_PERCENT: {
      return file
        ? uniqBy([{ ...file, hashPercent: action.value }, ...state], 'fileId')
        : state;
    }
    case actionTypes.UPDATE_CHUNKS: {
      return file
        ? uniqBy([{ ...file, chunks: action.value }, ...state], 'fileId')
        : state;
    }
    case actionTypes.UPDATE_STATUS: {
      return file
        ? uniqBy([{ ...file, status: action.value }, ...state], 'fileId')
        : state;
    }
    case actionTypes.UPDATE_LABEL: {
      return file
        ? uniqBy([{ ...file, label: action.value }, ...state], 'fileId')
        : state;
    }
    default:
      throw new Error(`not find this action type: ${action.type}`);
  }
};

export default reducer;
