import { Descriptions as DescriptionsBase, DescriptionsProps } from 'antd';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';
import { ReactNode, FC } from 'react';

interface PropertyType {
  key: string;
  value: ReactNode;
  label?: ReactNode;
  props?: Omit<DescriptionsItemProps, 'children'>;
}

interface Props extends DescriptionsProps {
  properties: PropertyType[];
  title?: ReactNode;
}

const Descriptions: FC<Props> = ({ properties, title, ...rest }) => {
  return (
    <DescriptionsBase title={title} {...rest}>
      {properties.map(({ key, value, label, props }) => {
        return (
          <DescriptionsBase.Item key={key} label={label} {...props}>
            {value}
          </DescriptionsBase.Item>
        );
      })}
    </DescriptionsBase>
  );
};

export default Descriptions;
