import { Button } from 'antd';
import { AsideSection } from '@/apps/dashboard/model/graph/aside/styles';
import styled from '@/theme';

export const Section = styled.section`
  display: flex;
`;

export const Article = styled.article`
  flex: auto;
  min-height: calc(100vh - 320px);
`;

export const Aside = styled.aside`
  flex: none;
  background-color: #fff;
  height: calc(100vh - 320px);
  overflow-x: hidden;
  overflow-y: auto;
  position: sticky;
  border-left: 2px solid #f0f2f5;
`;

//
export const Input = styled.input.attrs({
  hidden: true,
  type: 'file',
  accept: 'file',
  id: 'upload'
})``;

export const UploadWrapper = styled.label.attrs({ htmlFor: 'upload' })`
  align-items: center;
  border: 1px dashed #666;
  display: flex;
  flex-direction: column;
  height: 180px;
  padding: 50px 0;
  width: 470px;
  &.disabled {
    .icon {
      color: #666;
    }
    cursor: not-allowed;
  }
`;

//

export const SearchSection = styled(AsideSection)`
  max-height: calc(100% - 40px);
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    padding-bottom: 0;
  }
`;

export const FullWidthButton = styled(Button)`
  width: 100%;
`;

export const ExportButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    flex: 1 1 auto;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;
