import styled from '@/theme';

export const Wrapper = styled.div``;

export const Spacing = styled.div`
  margin: ${(props) => 3 * props.theme.spacing}px 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  .icon {
    margin-left: 4px;
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 16px;
  }
`;
