import { FC, ReactNode } from 'react';
import { Link as LinkBase } from 'react-router-dom';
import { useAuth } from '@/apps/authentication/hooks';

interface LinkProps {
  to: string;
  children: ReactNode;
  permission?: string;
}

const Link: FC<LinkProps> = ({ to, permission, children }) => {
  const { currentUser } = useAuth();

  if (
    permission &&
    currentUser &&
    !currentUser.permissions.includes(permission)
  ) {
    return typeof children === 'string' ? (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {children}
      </div>
    ) : (
      <>{children}</>
    );
  }

  return (
    <LinkBase
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      to={to}
    >
      {children}
    </LinkBase>
  );
};

export default Link;
