import * as ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from '@/theme/base.theme';
import Apps from './apps';

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <Apps />
    </ThemeProvider>
  </>,
  document.getElementById('root')
);
