/* eslint-disable no-bitwise */
function rgbToHex(r: number, g: number, b: number) {
  const hex = ((r << 16) | (g << 8) | b).toString(16);
  return `#${new Array(Math.abs(hex.length - 7)).join('0')}${hex}`;
}
// hex to rgb
function hexToRgb(hex: string) {
  const rgb = [];
  for (let i = 1; i < 7; i += 2) {
    rgb.push(parseInt(`0x${hex.slice(i, i + 2)}`, 16));
  }
  return rgb;
}
// 计算渐变过渡色
export function gradient(startColor: string, endColor: string, step: number) {
  if (
    startColor.length !== 7 ||
    endColor.length !== 7 ||
    typeof step !== 'number' ||
    Number.isNaN(step)
  ) {
    return [];
  }
  // 将 hex 转换为rgb
  const sColor = hexToRgb(startColor);
  const eColor = hexToRgb(endColor);
  // 计算R\G\B每一步的差值
  const rStep = (eColor[0] - sColor[0]) / step;
  const gStep = (eColor[1] - sColor[1]) / step;
  const bStep = (eColor[2] - sColor[2]) / step;

  const gradientColorArr = [startColor];
  for (let i = 1; i < step - 1; i += 1) {
    // 计算每一步的hex值
    gradientColorArr.push(
      rgbToHex(
        parseInt(`${rStep * i + sColor[0]}`, 10),
        parseInt(`${gStep * i + sColor[1]}`, 10),
        parseInt(`${bStep * i + sColor[2]}`, 10)
      )
    );
  }
  gradientColorArr.push(endColor);
  return gradientColorArr;
}

type PiecesType = { min: number; max: number }[];

export const getRange = (length: number, min: number, max: number) => {
  if (length < 1 || max < min) {
    return [];
  }
  if (min > 0) {
    min = Math.floor(Math.log10(min));
  }
  if (max > 0) {
    max = Math.ceil(Math.log10(max));
  }
  const val = Math.round((max - min) / length);
  const pieces: PiecesType = [{ min, max: min + val }];
  for (let i = 1; i < length; i += 1) {
    const itemMax = pieces[i - 1].max;
    pieces.push({
      min: itemMax,
      max: i === length - 1 ? Number.MAX_VALUE : itemMax + val
    });
  }
  return pieces;
};

export const getStyle = (
  colors: string[],
  range: PiecesType,
  value: number
) => {
  if (value === 0) {
    return { background: '#ffffff' };
  }
  value = Math.round(Math.log10(value));
  for (let i = 0; i < range.length; i += 1) {
    if (value < range[i].max && value >= range[i].min) {
      return i === colors.length - 1
        ? { background: colors[i], color: '#ffffff' }
        : { background: colors[i] };
    }
  }
  return { background: '#ffffff' };
};
