import { Typography } from 'antd';
import { useState, FC } from 'react';
import { DataSourceType } from '../types';
import Content from './Content';
import { Drawer } from './styles';

interface FileContentProps {
  setFileContentVisible: (fileContentVisible: boolean) => void;
  fileContentVisible: boolean;
  selectedRow: DataSourceType;
  label: string;
  datasetId: string;
}

const FileContent: FC<FileContentProps> = ({
  setFileContentVisible,
  fileContentVisible,
  selectedRow,
  label,
  datasetId
}) => {
  const [page, setPage] = useState(1);
  const [hasMore, updateHasMore] = useState(true);
  const [content, setContent] = useState<string[]>([]);
  const { fileName, path } = selectedRow;

  return (
    <Drawer
      title={
        <Typography.Text style={{ width: '530px' }} ellipsis>
          {`查看 ${fileName}`}
        </Typography.Text>
      }
      placement="right"
      onClose={() => {
        setFileContentVisible(false);
        setPage(1);
        setContent([]);
        updateHasMore(true);
      }}
      visible={fileContentVisible}
      width={600}
    >
      <Content
        datasetId={datasetId}
        label={label}
        path={path}
        page={page}
        setPage={setPage}
        content={content}
        setContent={setContent}
        fileContentVisible={fileContentVisible}
        hasMore={hasMore}
        updateHasMore={updateHasMore}
      />
    </Drawer>
  );
};

export default FileContent;
