import { Button } from 'antd';
import styled from '@/theme';

export const Btn = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  margin-left: 24px;
  &:focus {
    color: ${(props) => props.theme.palette.primary.main};
    background: transparent;
  }
  &:hover {
    color: #fff;
    background: ${(props) => props.theme.palette.primary.main};
  }
`;
