import { RouteType } from '@/components/routers/types';
import ModelList from './modelList';
import ModelCreate from './modelCreate';
import Detail from './detail';

const trainRoutes: RouteType[] = [
  {
    key: 'list',
    path: '/list',
    component: ModelList
  },
  {
    key: 'create',
    path: '/create',
    component: ModelCreate
  },
  {
    key: 'detail',
    path: '/:trainId',
    component: Detail
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/list'
  }
];

export default trainRoutes;
