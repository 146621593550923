import { Menu as MenuBase } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import routes from './routes';

const Menu = () => {
  const history = useHistory();
  const params = useParams<{ algorithmId: string }>();

  const pathArr = history.location.pathname?.split('/').filter((i) => i);
  const currentPath = pathArr[pathArr.length - 1] || routes[0].path;

  const menuItems = routes.map((route) => {
    if ('redirect' in route || route.hidden) {
      return null;
    }
    return {
      key: route.path,
      icon: route.icon,
      label: (
        <Link
          to={`/setting/manage-algorithm/${params.algorithmId}${route.path}`}
        >
          {route.sidebarName}
        </Link>
      )
    };
  });

  return (
    <MenuBase
      className="menu"
      selectedKeys={[`/${currentPath}`]}
      mode="horizontal"
      items={menuItems}
    />
  );
};

export default Menu;
