import { RouteType } from '@/components/routers/types';
import AccountList from './accountList';
import createAccount from './createAccount';

const accountRoutes: RouteType[] = [
  {
    key: 'list',
    path: '/list',
    component: AccountList
  },
  {
    key: 'create',
    path: '/create',
    component: createAccount
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/list'
  }
];

export default accountRoutes;
