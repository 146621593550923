import classnames from 'classnames';
import { ReactNode } from 'react';
import TextEllipsis from '../textEllipsis';
import { PropertiesWrapper, Property, Title } from './styles';

export interface PropertyType {
  key: string;
  content: ReactNode;
  title?: ReactNode;
  attachment?: ReactNode;
}

interface Props {
  properties: PropertyType[];
  propertyWidth?: string;
}

const Properties = ({ properties, propertyWidth }: Props) => {
  return (
    <PropertiesWrapper className="properties" id="properties">
      {properties.map((property) => {
        const { content, attachment, title, key } = property;
        return (
          (content !== undefined || content !== null) && (
            <Property
              className={classnames('property', `${key}_property`)}
              key={key}
              style={{ width: propertyWidth || '50%' }}
            >
              {title && <Title>{title}：</Title>}
              {typeof content === 'string' ? (
                <TextEllipsis text={content} />
              ) : (
                content
              )}
              {attachment}
            </Property>
          )
        );
      })}
    </PropertiesWrapper>
  );
};

export default Properties;
