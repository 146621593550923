import { FC } from 'react';
import { PropertiesType } from '@/apps/dashboard/model/graph/types';
import GraphSidebar from './GraphSidebar';
import Properties from './Properties';

type NodePropertiesSidebarProps = {
  data?: PropertiesType | null;
  onClose?: () => unknown;
  showNodeDocumentation?: () => unknown;
};

const NodePropertiesSidebar: FC<NodePropertiesSidebarProps> = ({
  data,
  onClose,
  showNodeDocumentation
}) => {
  return (
    <GraphSidebar title="节点属性" onClose={onClose}>
      <Properties {...data} showNodeDocumentation={showNodeDocumentation} />
    </GraphSidebar>
  );
};

export default NodePropertiesSidebar;
