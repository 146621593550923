/* eslint-disable max-len */
const FONT_BASE = 'PingFang SC, Microsoft YaHei, Helvetica, Arial, sans-serif';

export const theme = {
  spacing: 4,
  palette: {
    primary: {
      main: '#FAAD14',
      success: '#52c41a',
      active: '#1990ff',
      error: '#ff4d4e'
    },
    hoverBg: '#fffbe6',
    background: {
      default: '#F8F9FB',
      card: '#f5f6f9'
    },
    typography: {
      text: {
        color: '#424858',
        fontFamily: FONT_BASE,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px'
      },
      h1: {
        color: '#313741',
        fontFamily: FONT_BASE,
        fontSize: 24
      }
    },
    border: {
      color: '#ECECEE',
      radius: '4px'
    },
    header: {
      backgroundColor: '#fff'
    }
  },
  shadow: {
    header: '0 1px 4px rgb(0 21 41 / 8%)',
    sidebar: '2px 0 8px 0 rgb(29 35 41 / 5%)'
  }
};

export type ThemeType = typeof theme;
