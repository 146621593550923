import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { differenceBy } from 'lodash';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  message,
  Modal
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import request from '@/apps/utils';
import { DatasetType } from '../types';
import { Btn, OptionsWrapper, RelatedComponentWrapper } from './styles';
import { TrainType } from '../../model/types';

interface Props {
  title: string;
  datasetId: string;
  setDatasetList: Dispatch<SetStateAction<DatasetType[]>>;
}

const DeleteDataset: FC<Props> = ({ title, datasetId, setDatasetList }) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [relatedTrains, setRelatedTrains] = useState<TrainType[]>([]);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [plainOptions, setPlainOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (confirmVisible) {
      request({
        url: '/train',
        messageTitle: '项目关联训练列表请求',
        config: { method: 'GET' },
        params: { datasetId, status: 'DONE' }
      })
        .then(({ data }) => {
          setRelatedTrains(data);
          if (data) {
            const temp = data.map((item: TrainType) => {
              return {
                label: `${item.title}（${item.trainId}）`,
                value: item.trainId
              };
            });
            setPlainOptions(temp);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [confirmVisible]);

  const handleOk = () => {
    setConfirmVisible(false);
    const body = JSON.stringify({ trainIds: checkedList });
    request({
      url: `/dataset/${datasetId}`,
      config: { method: 'DELETE', body },
      messageTitle: `删除 ${datasetId}`
    })
      .then(({ data }) => {
        setDatasetList((pre) => differenceBy([...pre], [data], 'datasetId'));
        message.success('删除成功');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCancel = () => {
    setConfirmVisible(false);
  };
  const handleClick = () => {
    setConfirmVisible(true);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked ? plainOptions.map((item) => item.value) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  return (
    <>
      <Btn onClick={handleClick}>删除</Btn>
      <Modal
        title="删除数据集"
        visible={confirmVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            删除
          </Button>
        ]}
      >
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <p>{`是否将数据集${title}（${datasetId}）删除？`}</p>
          <Collapse ghost>
            {relatedTrains.length > 0 && (
              <Collapse.Panel
                header={<span>该项目关联以下模型，是否需要删除？</span>}
                key="1"
              >
                <RelatedComponentWrapper>
                  {relatedTrains.length > 1 && (
                    <>
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                      >
                        全部删除
                      </Checkbox>
                      <Divider />
                    </>
                  )}
                  <OptionsWrapper>
                    <Checkbox.Group
                      options={plainOptions}
                      value={checkedList}
                      onChange={onChange}
                    />
                  </OptionsWrapper>
                </RelatedComponentWrapper>
              </Collapse.Panel>
            )}
          </Collapse>
        </Card>
      </Modal>
    </>
  );
};

export default DeleteDataset;
