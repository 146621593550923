import { useEffect, useRef, useState } from 'react';
import { Button, Empty } from 'antd';
import { isEmpty } from 'lodash';
import { Link, PageWrapper } from '@/components';
import request from '@/apps/utils';
import { TrainType } from '../types';
import ModelCard from './modelCard';
import { AddIcon, ButtonWrapper, Wrapper } from './styles';

const ModelList = () => {
  const [trains, setTrains] = useState<TrainType[]>([]);
  const [loading, setLoading] = useState(true);

  const timer = useRef<any>();
  const trainsRef = useRef<TrainType[]>();
  trainsRef.current = trains;

  const getList = () => {
    if (trainsRef.current) {
      request({
        url: '/train?verbose=true',
        config: { method: 'GET' },
        messageTitle: '训练列表请求'
      })
        .then(({ data }) => {
          setTrains(data);
        })
        .catch((err) => {
          console.error(err);
          if (timer.current) {
            clearInterval(timer.current);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getList();
    timer.current = setInterval(() => {
      getList();
    }, 5000);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !loading &&
      trains.every(
        (train) => train.status !== 'RUNNING' && train.status !== 'WAITING'
      ) &&
      timer.current
    ) {
      clearInterval(timer.current);
      timer.current = null;
    }
    if (
      !loading &&
      trains.some(
        (train) => train.status === 'RUNNING' || train.status === 'WAITING'
      ) &&
      !timer.current
    ) {
      timer.current = setInterval(() => {
        getList();
      }, 5000);
    }
  }, [trains]);

  return (
    <PageWrapper
      headerProps={{ title: '模型列表' }}
      childrenCardProps={{ loading }}
    >
      <Wrapper className="cardList">
        <ButtonWrapper>
          <Link to="create">
            <Button type="primary">
              <AddIcon />
              新建
            </Button>
          </Link>
        </ButtonWrapper>
        {isEmpty(trains) ? (
          <Empty description="暂无数据" />
        ) : (
          trains.map((train) => {
            return (
              <ModelCard
                key={train.trainId}
                train={train}
                setTrains={setTrains}
              />
            );
          })
        )}
      </Wrapper>
    </PageWrapper>
  );
};

export default ModelList;
