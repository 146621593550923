import {
  Redirect,
  RouteComponentProps,
  Route,
  Switch as SwitchBase
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { RouteType } from './types';

interface SwitchProps {
  routes: RouteType[];
  basePath?: string;
  routeProp?: object;
}

const Switch = ({ routes, basePath = '', routeProp }: SwitchProps) => {
  return (
    <SwitchBase>
      {routes.map((route) => {
        const { path, key } = route;
        if ('redirect' in route) {
          return (
            <Redirect
              from={basePath + path}
              to={basePath + route.to}
              key={key}
            />
          );
        }
        const { component: Component } = route;
        if (route.permission) {
          return (
            <PrivateRoute
              key={key}
              route={route}
              path={basePath + path}
              checkPermission={(userPermissions) => {
                return userPermissions.includes(route.permission || '');
              }}
            />
          );
        }
        return (
          <Route
            key={key}
            path={basePath + path}
            render={(routeComponentProps: RouteComponentProps) => (
              <Component {...routeComponentProps} {...routeProp} />
            )}
          />
        );
      })}
    </SwitchBase>
  );
};

export default Switch;
