import styled from '@/theme';

export const LearningCurveWrapper = styled.div`
  display: flex;
`;

export const ConfusionMatrixTableWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .subtitle {
    margin: 12px;
  }
  .normal {
    width: 100%;
  }
  .multi {
    width: 49%;
    display: flex;
    flex-direction: column;
  }
  .empty {
    width: 100%;
  }
`;
