import { PlusOutlined } from '@ant-design/icons';
import styled from '@/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .card {
    margin-bottom: ${(props) => 4 * props.theme.spacing}px;
    &:last-child {
      margin-bottom: 0;
    }
    .properties {
      width: 80%;
    }
    .ant-card-body {
      padding: 12px;
    }
  }
  .cardBody {
    display: flex;
  }
`;

export const Btn = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    cursor: pointer;
    color: #feca56;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${(props) => 4 * props.theme.spacing}px;
  float: right;
  margin-left: auto;
  display: flex;
`;

export const AddIcon = styled(PlusOutlined)``;
