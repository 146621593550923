import { List } from 'antd';
import { FC, useEffect, useState } from 'react';
import request from '@/apps/utils';

interface TextContentProps {
  datasetId: string;
  label: string;
  path: string;
}

const TextContent: FC<TextContentProps> = ({ datasetId, label, path }) => {
  const [fileContent, setFileContent] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getContent = (p: number, pSize: number) => {
    setLoading(true);
    request({
      url: `/dataset/${datasetId}/browse-file`,
      config: {
        method: 'GET'
      },
      params: {
        label,
        path,
        pageSize: pSize,
        page: p
      }
    })
      .then(({ data }) => {
        setFileContent(data.lines);
        setTotal(data.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getContent(1, pageSize);
  }, []);

  const onPaginationChange = (p: number, pSize: number) => {
    setPageSize(pSize);
    getContent(p, pSize);
  };

  return (
    <List
      loading={loading}
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: onPaginationChange,
        total,
        pageSize
      }}
      dataSource={fileContent}
      renderItem={(item: string) => {
        return <List.Item key={item}>{item}</List.Item>;
      }}
    />
  );
};
export default TextContent;
