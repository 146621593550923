import { DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FC } from 'react';
import { RcFile } from '../types';
import ListItem from './ListItem';
import { Wrapper, Title, ListWrapper, Btn, Header } from './styles';

interface Props {
  uploadList: RcFile[];
  setUploadList: (uploadList: RcFile[]) => void;
}

const FileList: FC<Props> = ({ uploadList, setUploadList }) => {
  const handleDelete = () => {
    setUploadList([]);
  };
  return (
    <Wrapper>
      <Header>
        <Title>{`已上传文件（${uploadList.length}）`}</Title>
        <Btn onClick={handleDelete}>
          <Tooltip title="全部清空">
            <DeleteOutlined />
          </Tooltip>
        </Btn>
      </Header>
      <ListWrapper>
        {uploadList.map((file) => {
          return (
            <ListItem
              key={file.uid}
              uploadList={uploadList}
              file={file}
              setUploadList={setUploadList}
            />
          );
        })}
      </ListWrapper>
    </Wrapper>
  );
};

export default FileList;
