import { message, Modal, Tooltip } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { uniqBy } from 'lodash';
import request from '@/apps/utils';
import { Retry, Stop } from './styles';
import { TrainType } from '../../../types';

interface ActionProps {
  action: 'RETRY' | 'STOP';
  trainId: string;
  tooltip: string;
  tooltipVisible: boolean;
  setTrains: Dispatch<SetStateAction<TrainType[]>>;
}

interface ActionComponentProps {
  trainId: string;
  trainStatus: 'DONE' | 'RUNNING' | 'STOPPED' | 'WAITING' | 'ERROR';
  actionIconShown: boolean;
  handleShown: () => void;
  setTrains: Dispatch<SetStateAction<TrainType[]>>;
}

const actionMap = {
  RETRY: '重试',
  STOP: '停止'
};

interface ConfirmModalProps {
  title: string;
  msg: string;
  visible: boolean;
  onCancel: any;
  onOk: any;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  msg,
  visible,
  onCancel,
  onOk
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText="确定"
      cancelText="取消"
    >
      <p>{msg}</p>
    </Modal>
  );
};

const Action: FC<ActionProps> = ({
  action,
  tooltip,
  tooltipVisible,
  trainId,
  setTrains
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const onCancel = () => {
    setConfirmVisible(false);
  };

  const handleAction = () => {
    request({
      url: `/train/${trainId}`,
      params: { action },
      config: { method: 'PUT' },
      messageTitle: `${actionMap[action]} ${trainId}`
    })
      .then(({ data }) => {
        setTrains((pre) => uniqBy([data, ...pre], 'trainId'));
        message.success(`${actionMap[action]}训练成功`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Tooltip title={tooltip} visible={tooltipVisible}>
        {action === 'STOP' ? (
          <Stop
            onClick={() => {
              setConfirmVisible(true);
            }}
          />
        ) : (
          <Retry
            onClick={() => {
              setConfirmVisible(true);
            }}
          />
        )}
      </Tooltip>
      <ConfirmModal
        visible={confirmVisible}
        onCancel={onCancel}
        onOk={() => {
          setConfirmVisible(false);
          handleAction();
        }}
        title={`${actionMap[action]}训练`}
        msg={`是否确定${actionMap[action]}训练${trainId}？`}
      />
    </>
  );
};

const ActionComponent: FC<ActionComponentProps> = ({
  trainStatus,
  actionIconShown,
  trainId,
  setTrains,
  handleShown
}) => {
  if (trainStatus === 'WAITING' || trainStatus === 'RUNNING') {
    return (
      <div onMouseEnter={handleShown} onMouseLeave={handleShown}>
        <Action
          action="STOP"
          tooltip="点击停止训练"
          tooltipVisible={actionIconShown}
          trainId={trainId}
          setTrains={setTrains}
        />
      </div>
    );
  }
  return (
    <div onMouseEnter={handleShown} onMouseLeave={handleShown}>
      <Action
        action="RETRY"
        tooltip="点击重试"
        tooltipVisible={actionIconShown}
        trainId={trainId}
        setTrains={setTrains}
      />
    </div>
  );
};

export default ActionComponent;
