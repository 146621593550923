/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import styled from '@/theme';
import { SearchItem, SearchResult } from '@/apps/dashboard/model/graph/types';
import Field from './Field';
import useSearchValue from '@/apps/utils/hooks/useSearchValue';
import { SearchIcon } from './styles';

const SearchField = styled(Field)`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :first-child {
    flex: auto;
  }

  > a:last-child {
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
    margin-left: 12px;
    flex: none;
  }
`;

const Empty = styled.div`
  padding: 12px 0;
  text-align: center;
  color: #666;
`;

const Wrapper = styled.div`
  overflow: auto;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;

  > span {
    flex: auto;
    margin-left: 12px;
  }

  &:hover {
    background-color: #f6f6f6;
  }
`;

type SearchProps = {
  text?: string;
  data: SearchResult;
  onChange?: (value: string) => unknown;
  onSelect?: (item: SearchItem) => unknown;
  onActive?: () => unknown;
  onDeactive?: () => unknown;
};

const Search: FC<SearchProps> = ({
  text,
  data,
  onChange,
  onSelect,
  onActive,
  onDeactive
}) => {
  const [search, setSearch] = useState(text ?? '');
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchItem[]>(data.result);
  const debouncedSearchText = useSearchValue(search);
  useEffect(() => setSearch(text ?? ''), [text]);
  useEffect(() => {
    if (searching) {
      onChange?.(debouncedSearchText);
    } else {
      setSearchResult([]);
    }
  }, [debouncedSearchText, searching, onChange]);
  useEffect(() => {
    if (data.text === search) {
      setSearchResult(data.result);
    }
  }, [data, search]);

  const focus = useCallback(() => {
    setSearching(true);
    onActive?.();
  }, [onActive]);

  const cancel = useCallback(() => {
    setSearch('');
    onChange?.('');
    setSearching(false);
    onDeactive?.();
  }, [onChange, onDeactive]);

  const select = useCallback(
    (item: SearchItem) => {
      setSearch(item.name);
      onSelect?.(item);
      setSearching(false);
      onDeactive?.();
    },
    [onSelect, onDeactive]
  );

  return (
    <>
      <SearchField>
        <Input
          allowClear
          placeholder="搜索"
          prefix={<SearchIcon />}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onFocus={focus}
        />
        {searching && <a onClick={cancel}>取消</a>}
      </SearchField>
      {searching &&
        (searchResult.length ? (
          <Wrapper>
            <List>
              {searchResult.map((item) => {
                return (
                  <Item
                    key={item.id}
                    onClick={() => select(item)}
                    title={item.name}
                  >
                    <span>{item.name}</span>
                  </Item>
                );
              })}
            </List>
          </Wrapper>
        ) : (
          <Empty>无匹配的内容</Empty>
        ))}
    </>
  );
};

export default Search;
