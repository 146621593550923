import { InboxOutlined } from '@ant-design/icons';
import styled from '@/theme';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 184px;
  justify-content: center;
  margin: auto 0;
  position: relative;
  width: 470px;
`;

export const UploadWrapper = styled.label.attrs({ htmlFor: 'upload' })`
  align-items: center;
  border: 1px dashed #666;
  display: flex;
  flex-direction: column;
  height: 180px;
  padding: 50px 0;
  width: 470px;
  &.disabled {
    .icon {
      color: #666;
    }
    cursor: not-allowed;
  }
`;

export const Input = styled.input.attrs({
  hidden: true,
  type: 'file',
  accept: 'file',
  id: 'upload'
})``;

export const Tip = styled.div`
  color: #b3b3b3;
  font-size: 12px;
  max-width: 470px;
  position: absolute;
  text-align: center;
  top: 110px;
`;

export const Desc = styled.div`
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 78px;
`;

export const Icon = styled(InboxOutlined)`
  position: absolute;
  top: 40px;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 24px;
`;
