/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, ReactNode } from 'react';
import { Content, Sidebar, Title } from './styles';

type GraphSidebarProps = {
  children: ReactNode;
  title: string;
  onClose?: () => unknown;
};

const GraphSidebar: FC<GraphSidebarProps> = ({ title, onClose, children }) => {
  return (
    <Sidebar>
      <Title>
        <span>{title}</span>
        <a className="close" onClick={() => onClose?.()}>
          关闭
        </a>
      </Title>
      <Content>{children}</Content>
    </Sidebar>
  );
};

export default GraphSidebar;
