import { FC, useEffect, useState } from 'react';
import { Descriptions, Empty, Table, Typography } from 'antd';
import cs from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { flattenDepth } from 'lodash';
import { Title } from '@/components';
import DescCard from '@/components/descCard';
import request from '@/apps/utils';
import { ModelEvaluateType, ModelType } from '../types';
import LearningCurve from './LearningCurve';
import { toFixed } from '@/apps/utils/utils';
import ROCandPRC from './ROCandPRC';
import { getStyle, getRange, gradient } from './utils';
import { ConfusionMatrixTableWrapper } from './styles';

interface Props {
  modelId: string;
}

const ModelEvaluate: FC<Props> = ({ modelId }) => {
  const [evaluateValue, setEvaluateValue] = useState<ModelEvaluateType>();
  const [model, setModel] = useState<ModelType>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMount = true;
    request({
      url: `/models/${modelId}/evaluate`,
      config: { method: 'GET' },
      messageTitle: '模型评估详情请求'
    })
      .then(({ data }) => isMount && setEvaluateValue(data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => isMount && setLoading(false));

    request({
      url: `/models/${modelId}?verbose=true`,
      config: { method: 'GET' },
      messageTitle: '模型详情请求'
    })
      .then(({ data }) => isMount && setModel(data))
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMount = false;
    };
  }, []);

  const evaluationMatrixData = evaluateValue?.evaluationMatrix?.map(
    (item, index) => {
      const obj = { key: index, label: evaluateValue.labels[index] };
      Object.keys(item).forEach((key) => {
        obj[key] = toFixed(item[key], 4);
      });
      return obj;
    }
  );

  const evaluationMatrixColumns = [
    {
      title: '标签',
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: '精确率',
      dataIndex: 'precision',
      key: 'precision'
    },
    {
      title: '召回率',
      dataIndex: 'recall',
      key: 'recall'
    },
    {
      title: 'F1值',
      dataIndex: 'f1-score',
      key: 'f1-score'
    },
    {
      title: '样本数',
      dataIndex: 'support',
      key: 'support'
    }
  ];

  const getConfusionMatrixTable = (value?: { [key: string]: number[][] }) => {
    if (!value) {
      return [];
    }
    return Object.keys(value).map((key) => {
      const columns: ColumnsType<any> = [
        { title: '', dataIndex: 'label', key: 'label' }
      ];
      const temp = flattenDepth(value[key], 2);
      const maxValue = Math.max(...temp);
      const colorArrLength = Math.round(Math.log10(maxValue));
      const colorArr = gradient('#E6EFF8', '#396AAB', colorArrLength);
      const dataSource = [];
      const range = getRange(colorArr.length, 0, maxValue);
      for (let i = 0; i < value[key].length; i += 1) {
        columns.push({
          title: `预测=${i}`,
          dataIndex: `predicted=${i}`,
          key: `predicted=${i}`,
          onCell: (record) => {
            const style = getStyle(colorArr, range, record[`predicted=${i}`]);
            return { style };
          }
        });
        let dataTemp = {};
        for (let j = 0; j < value[key].length; j += 1) {
          dataTemp = {
            ...dataTemp,
            key: i,
            label: `实际=${i}`,
            [`predicted=${j}`]: toFixed(value[key][i][j], 0)
          };
        }
        dataSource.push(dataTemp);
      }
      return { dataSource, columns, key };
    });
  };

  const properties = [
    {
      key: 'id',
      title: 'id',
      content: evaluateValue?.modelId
    },
    {
      key: 'title',
      title: '名称',
      content: model?.title
    }
  ];
  const confusionMatrixTableArr = getConfusionMatrixTable(
    evaluateValue?.confusionMatrix
  );
  let parameters = {};
  try {
    parameters =
      (evaluateValue?.parameter && JSON.parse(evaluateValue.parameter)) || {};
  } catch (err) {
    console.log(err);
  }

  return (
    <DescCard loading={loading} properties={properties} propertyWidth="100%">
      <Title title="使用参数">
        <Descriptions bordered size="middle" column={2}>
          {Object.keys(parameters).map((key) => {
            return (
              <Descriptions.Item
                key={key}
                contentStyle={{ background: '#fff' }}
                label={key}
              >
                {toFixed(parameters[key], 4)}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </Title>
      <Title title="学习曲线">
        <LearningCurve
          trainLoss={evaluateValue?.learningCurve?.train.loss}
          trainAccuracy={evaluateValue?.learningCurve?.train.accuracy}
          validLoss={evaluateValue?.learningCurve?.valid.loss}
          validAccuracy={evaluateValue?.learningCurve?.valid.accuracy}
        />
      </Title>
      <Title title="评估矩阵">
        <Table
          className="evaluationMatrix"
          pagination={false}
          dataSource={evaluationMatrixData}
          columns={evaluationMatrixColumns}
        />
      </Title>
      <Title title="混淆矩阵">
        <ConfusionMatrixTableWrapper>
          {confusionMatrixTableArr.length ? (
            confusionMatrixTableArr.map(({ dataSource, columns, key }) => {
              return (
                <div
                  key={key}
                  className={cs('normal', {
                    multi: confusionMatrixTableArr.length > 1
                  })}
                >
                  <Typography.Text strong className="subtitle">
                    {evaluateValue?.labels[key]}
                  </Typography.Text>
                  <Table
                    className="confusionMatrix"
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              );
            })
          ) : (
            <Empty className="empty" description="暂无数据" />
          )}
        </ConfusionMatrixTableWrapper>
      </Title>
      {evaluateValue?.prcCurve && evaluateValue?.rocCurve && (
        <Title title="PRC/ROC">
          <ROCandPRC
            prcCurve={evaluateValue.prcCurve}
            rocCurve={evaluateValue.rocCurve}
          />
        </Title>
      )}
    </DescCard>
  );
};

export default ModelEvaluate;
