import { PageWrapper } from '@/components';
import DatasetForm from './DatasetForm';
import { FormWrapper } from './styles';

const DatasetSetting = () => {
  return (
    <PageWrapper
      headerProps={{
        title: '新建数据集'
      }}
    >
      <FormWrapper>
        <DatasetForm />
      </FormWrapper>
    </PageWrapper>
  );
};

export default DatasetSetting;
