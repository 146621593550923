import { Button } from 'antd';
import styled from '@/theme';

export const Wrapper = styled.div`
  .ant-dropdown-trigger {
    width: 25px;
  }
`;

export const UploadWrapper = styled.div`
  overflow: hidden;
  .ant-upload-list {
    overflow: auto;
    max-height: 300px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .button {
    margin: 8px 20px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
`;

export const Btn = styled(Button)`
  margin: 0 12px 12px 0;
`;
