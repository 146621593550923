export interface TrainStatusType {
  status: 'DONE' | 'RUNNING' | 'STOPPED' | 'WAITING' | 'ERROR';
  errorMsg?: string;
  trialStat: {
    total: number;
    succeeded: number;
    failed: number;
    stopped: number;
    running: number;
    waiting: number;
  };
  ratio?: number;
  duration?: number;
  modelId?: string;
  hyperParamsTrace?: HyperParamsTraceDataType;
}

export interface HyperParamsTraceDataType {
  keys: string[];
  values: number[][];
}

export const TrainStatusMap = {
  total: '总数',
  succeeded: '成功',
  failed: '失败',
  stopped: '已停止',
  running: '运行中',
  waiting: '等待中'
};

export interface TrialType {
  trialId: string;
  trainId: string;
  status: 'SUCCEEDED' | 'RUNNING' | 'STOPPED' | 'WAITING' | 'FAILED';
  hyperParameters: string;
  duration: number;
  startTime: string;
  endTime: string;
  sequenceId: string;
  createTime: string;
  updateTime: string;
  score?: number;
}

export interface TrialsOrderType {
  duration?: 'DESC' | 'ASC';
  sequenceId?: 'DESC' | 'ASC';
  score?: 'DESC' | 'ASC';
}
