import { message, Modal, Tooltip } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import request from '@/apps/utils';
import { DatasetType } from '../types';
import { Retry, Stop, IconWrapper } from './styles';

interface ActionProps {
  action: 'RETRY' | 'STOP';
  subsetId: string;
  tooltip: string;
  tooltipVisible: boolean;
  setDataset: Dispatch<SetStateAction<DatasetType | undefined>>;
}

interface ActionComponentProps {
  dataLabel: string;
  subsetId: string;
  status: 'DONE' | 'STOPPED' | 'WAITING' | 'ERROR';
  actionIconShown: boolean;
  setActionIconShown: (label: string, value: boolean) => void;
  setDataset: Dispatch<SetStateAction<DatasetType | undefined>>;
}

const actionMap = {
  RETRY: '重试',
  STOP: '停止'
};

interface ConfirmModalProps {
  title: string;
  msg: string;
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  msg,
  visible,
  onCancel,
  onOk
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText="确定"
      cancelText="取消"
    >
      <p>{msg}</p>
    </Modal>
  );
};

const Action: FC<ActionProps> = ({
  action,
  tooltip,
  tooltipVisible,
  subsetId,
  setDataset
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const onCancel = () => {
    setConfirmVisible(false);
  };

  const handleAction = () => {
    request({
      url: `/subset/${subsetId}`,
      params: { action },
      config: { method: 'PUT' },
      messageTitle: `${actionMap[action]} ${subsetId}`
    })
      .then(({ data }) => {
        setDataset(data.dataset);
        message.success(`${actionMap[action]}成功`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Tooltip title={tooltip} visible={tooltipVisible}>
        {action === 'STOP' ? (
          <Stop
            onClick={() => {
              setConfirmVisible(true);
            }}
          />
        ) : (
          <Retry
            onClick={() => {
              setConfirmVisible(true);
            }}
          />
        )}
      </Tooltip>
      <ConfirmModal
        visible={confirmVisible}
        onCancel={onCancel}
        onOk={() => {
          setConfirmVisible(false);
          handleAction();
        }}
        title={`${actionMap[action]}数据集预处理`}
        msg={`是否确定${actionMap[action]}样本${subsetId}预处理？`}
      />
    </>
  );
};

const ActionComponent: FC<ActionComponentProps> = ({
  status,
  actionIconShown,
  subsetId,
  setDataset,
  setActionIconShown,
  dataLabel
}) => {
  if (status === 'WAITING') {
    return (
      <IconWrapper>
        <div
          className="wrapper"
          onMouseLeave={() => setActionIconShown(dataLabel, false)}
        >
          <Action
            action="STOP"
            tooltip="点击停止预处理"
            tooltipVisible={actionIconShown}
            subsetId={subsetId}
            setDataset={setDataset}
          />
        </div>
        <div className="desc">暂停</div>
      </IconWrapper>
    );
  }
  return (
    <IconWrapper>
      <div
        className="wrapper"
        onMouseLeave={() => setActionIconShown(dataLabel, false)}
      >
        <Action
          action="RETRY"
          tooltip="点击重试预处理"
          tooltipVisible={actionIconShown}
          subsetId={subsetId}
          setDataset={setDataset}
        />
      </div>
      <div className="desc">重试</div>
    </IconWrapper>
  );
};

export default ActionComponent;
