import { Drawer, message, Modal, Table, Tooltip } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Dispatch, SetStateAction, useState } from 'react';
import { uniqBy } from 'lodash';
import { formatSeconds, toFixed } from '@/apps/utils/utils';
import request from '@/apps/utils';
import { TrialsOrderType, TrialType } from './types';
import TrialLogs from './TrialLogs';
import {
  DoneIcon,
  StoppedIcon,
  FailedIcon,
  WaitingIcon,
  RunIcon,
  TextBtn,
  KillTrialWrapper
} from './styles';

const statusMap = {
  SUCCEEDED: <DoneIcon />,
  RUNNING: <RunIcon />,
  STOPPED: <StoppedIcon />,
  WAITING: <WaitingIcon />,
  FAILED: <FailedIcon />,
  USER_CANCELED: <StoppedIcon />
};

const TrialTable = (props: {
  trials: TrialType[];
  trainId: string;
  setTrialsOrder: (trialsOrder: TrialsOrderType) => void;
  setTrials: Dispatch<SetStateAction<TrialType[]>>;
}) => {
  const { trials, trainId, setTrialsOrder, setTrials } = props;
  const [selectedTrialId, setSelectedTrialId] = useState<string>();
  const [logVisible, setLogVisible] = useState(false);
  const [isKillTrial, setIsKillTrial] = useState(false);
  const [killTrialId, setKillTrialId] = useState('');
  const showDrawer = (trialId: string) => {
    setSelectedTrialId(trialId);
    setLogVisible(true);
  };
  const onClose = () => {
    setLogVisible(false);
  };

  const handleKillTrail = (trialId: string) => {
    setKillTrialId(trialId);
    setIsKillTrial(true);
  };

  const handleKillTrialOk = () => {
    setIsKillTrial(false);
    request({
      url: `/train/${killTrialId}/killTrialJob`,
      config: { method: 'PUT' },
      messageTitle: `停止试验 ${killTrialId}`
    })
      .then(({ data: trial }) => {
        setTrials((pre) => uniqBy([trial, ...pre], 'trialId'));
        message.success('停止试验成功');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setKillTrialId('');
      });
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'sequenceId',
      key: 'sequenceId',
      width: 70,
      ellipsis: true,
      sorter: true
    },
    { title: 'ID', dataIndex: 'trialId', key: 'trialId', ellipsis: true },
    {
      title: '耗时',
      dataIndex: 'duration',
      key: 'duration',
      ellipsis: true,
      width: 100,
      sorter: true
    },
    {
      title: '评分',
      dataIndex: 'score',
      key: 'score',
      ellipsis: true,
      sorter: true
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 60,
      render: (text: string, record: any) => {
        if (text === 'RUNNING') {
          return (
            <Tooltip title="点击停止试验">
              <KillTrialWrapper onClick={() => handleKillTrail(record.trialId)}>
                {statusMap[text]}
              </KillTrialWrapper>
            </Tooltip>
          );
        }
        return statusMap[text];
      }
    },
    {
      title: '操作',
      key: 'action',
      width: 90,
      render: (text: any) => {
        return (
          <TextBtn onClick={() => showDrawer(text.trialId)}>查看日志</TextBtn>
        );
      }
    }
  ];

  const data = trials.map((i) => ({
    ...i,
    key: i.trialId,
    status: i.status,
    duration: i.duration ? formatSeconds(i.duration / 1000) : 0,
    score: i.score ? toFixed(i.score, 4) : ''
  }));

  const onChange = (pagination: any, filters: any, sorter: any) => {
    setTrialsOrder({
      [sorter.field]: sorter.order === 'descend' ? 'DESC' : 'ASC'
    } as any);
  };
  return (
    <>
      <Table
        style={{
          height: '700px',
          background: '#f5f6f9',
          padding: '16px',
          borderRadius: '4px'
        }}
        scroll={{ y: 612 }}
        pagination={false}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: (record) => {
            let hyperParametersObj: any;
            if (record.hyperParameters) {
              hyperParametersObj = JSON.parse(record.hyperParameters);
              Object.keys(hyperParametersObj).forEach((key: string) => {
                hyperParametersObj[key] = toFixed(hyperParametersObj[key], 4);
              });
              const str = JSON.stringify(hyperParametersObj);
              return <p style={{ margin: 0 }}>{str}</p>;
            }
            return <p style={{ margin: 0 }}>无</p>;
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            )
        }}
        onChange={onChange}
      />
      {selectedTrialId && (
        <Drawer
          title={`查看${selectedTrialId}日志`}
          placement="right"
          onClose={onClose}
          visible={logVisible}
          width={600}
        >
          <TrialLogs
            trainId={trainId}
            trialId={selectedTrialId}
            logVisible={logVisible}
          />
        </Drawer>
      )}
      <Modal
        title="停止试验"
        visible={isKillTrial}
        onCancel={() => {
          setIsKillTrial(false);
          setKillTrialId('');
        }}
        onOk={handleKillTrialOk}
        okText="确定"
        cancelText="取消"
      >
        <p>{`是否停止试验 ${killTrialId}`}</p>
      </Modal>
    </>
  );
};

export default TrialTable;
