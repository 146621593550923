import JSZip from 'jszip';

export const formatSeconds = (seconds: number) => {
  let secondTime = seconds;
  let min = 0;
  let hour = 0;
  if (secondTime > 60) {
    min = parseInt(String(secondTime / 60), 10);
    secondTime = parseInt(String(secondTime % 60), 10);
    if (min > 60) {
      hour = parseInt(String(min / 60), 10);
      min = parseInt(String(min % 60), 10);
    }
  }
  let result = '';
  if (secondTime > 0) {
    result = `${secondTime}秒`;
  }
  if (min > 0) {
    result = `${min}分${result}`;
  }
  if (hour > 0) {
    result = `${hour}小时${result}`;
  }
  return result;
};

export function generateZipFile(
  files: File[],
  folderName: string,
  options: JSZip.JSZipGeneratorOptions = {
    type: 'blob',
    compression: 'DEFLATE'
  },
  zipName?: string
): Promise<File> {
  return new Promise((resolve) => {
    const zip = new JSZip();
    for (let i = 0; i < files.length; i += 1) {
      zip.file(`${folderName}/${files[i].name}`, files[i]);
    }

    zip.generateAsync(options).then((blob: any) => {
      const name = zipName || `${Date.now()}_fe.zip`;
      const zipFile = new File([blob], name, {
        type: 'application/zip'
      });
      resolve(zipFile);
    });
  });
}

export const toFixed = (num: number, digits: number) => {
  if (num === undefined || num === null) {
    return '';
  }
  return Number(num.toFixed(digits));
};
