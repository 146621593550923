import { FC, useState } from 'react';
import { Empty } from 'antd';
import { find, uniqBy } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { Title } from '@/components';
import { DataSourceType, ListProps } from './types';
import ActionComponent from './ActionComponent';
import {
  Btn,
  IconWrapper,
  LoadingIcon,
  ErrorIcon,
  ErrorMsg,
  Stop
} from './styles';
import FilesTable from './filesTable';

const List: FC<ListProps> = ({ datasetId, dataset, setDataset }) => {
  const [fileActionList, setFileActionList] = useState(
    dataset.dataLabels.map((label) => ({
      label,
      actionIconShow: false
    }))
  );

  const columns: ColumnsType<DataSourceType> = [
    {
      title: '文件名',
      dataIndex: 'fileName',
      key: 'fileName',
      ellipsis: true,
      render: (text: string) => {
        return dataset.dataType === 'file' ? <Btn>{text}</Btn> : text;
      }
    },
    {
      title: '文件路径',
      dataIndex: 'path',
      key: 'path',
      ellipsis: true
    },
    {
      title: '文件大小',
      dataIndex: 'size',
      key: 'size',
      ellipsis: true
    },
    {
      title: '更新时间',
      dataIndex: 'lastModified',
      key: 'lastModified',
      ellipsis: true
    }
  ];

  const setActionIconShown = (label: string, value: boolean) => {
    setFileActionList(
      uniqBy([{ label, actionIconShow: value }, ...fileActionList], 'label')
    );
  };

  const getContent = (dataLabel: string, actionIconShown: boolean) => {
    const subsets = find(dataset.subsets, { dataLabel });

    if (!subsets) {
      return <Empty />;
    }
    const { subsetId, status } = subsets;

    if (actionIconShown) {
      return (
        <ActionComponent
          dataLabel={dataLabel}
          subsetId={subsetId}
          status={status}
          actionIconShown={actionIconShown}
          setActionIconShown={setActionIconShown}
          setDataset={setDataset}
        />
      );
    }
    if (status === 'DONE') {
      return (
        <FilesTable
          columns={columns}
          subsetId={subsetId}
          datasetId={datasetId}
          dataLabel={dataLabel}
          dataType={dataset.dataType}
        />
      );
    }
    if (status === 'ERROR') {
      return (
        <ErrorMsg
          icon={<ErrorIcon />}
          status="error"
          subTitle={subsets.errorMsg || 'error'}
          style={{ height: '200px' }}
        />
      );
    }
    if (status === 'STOPPED') {
      return (
        <IconWrapper>
          <div
            className="wrapper"
            onMouseEnter={() => setActionIconShown(dataLabel, true)}
          >
            <Stop className="icon" />
          </div>
          <div className="desc">{subsetId} 已停止</div>
        </IconWrapper>
      );
    }
    return (
      <IconWrapper>
        <div
          className="wrapper"
          onMouseEnter={() => setActionIconShown(dataLabel, true)}
        >
          <LoadingIcon />
        </div>
        <div className="desc">{subsetId} 正在处理中...</div>
      </IconWrapper>
    );
  };
  return (
    <>
      {dataset.dataLabels.map((item) => {
        const fileAction = find(fileActionList, { label: item });
        return (
          <Title
            id={item.replace(',', '')}
            key={item}
            title={`${item}样本采样`}
            className="list"
          >
            {fileAction && getContent(item, fileAction.actionIconShow)}
          </Title>
        );
      })}
    </>
  );
};
export default List;
