import { BaseItemType } from './types';

export const handleSearchSpace = (searchSpace?: BaseItemType[]) => {
  if (!searchSpace) {
    return {};
  }
  const temp = {};
  searchSpace.forEach((item) => {
    if (item.type === 'uniform') {
      const [first, second] = item.value;
      temp[item.key] = {};
      temp[item.key].min = first;
      temp[item.key].max = second;
    } else {
      temp[item.key] = Array.isArray(item.value)
        ? item.value.join(',')
        : item.value;
    }
  });
  return temp;
};
