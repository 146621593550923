import { Menu as MenuBase } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import routes from './routes';

const Menu = (modelId?: string) => {
  const history = useHistory();
  const params = useParams<{ trainId: string }>();

  const pathArr = history.location.pathname?.split('/').filter((i) => i);
  const currentPath = pathArr[pathArr.length - 1] || routes[0].path;

  const menuItems = routes.map((route) => {
    if ('redirect' in route || route.hidden) {
      return null;
    }
    if (modelId === undefined && route.key.startsWith('model')) {
      return null;
    }
    return {
      key: route.path,
      icon: route.icon,
      label: (
        <Link to={`/manage-model/${params.trainId}${route.path}`}>
          {route.sidebarName}
        </Link>
      )
    };
  });

  return (
    <MenuBase
      className="menu"
      selectedKeys={[`/${currentPath}`]}
      mode="horizontal"
      items={menuItems}
    />
  );
};

export default Menu;
