export interface UserType {
  accountId: string;
  displayName: string;
  email: string;
  role: 'admin' | 'user' | 'guest';
  isValid: boolean;
  createTime: string;
  updateTime: string;
  permissions: string[];
}

export const RoleMap = {
  admin: ['管理员', '数据集管理/模型管理/用户管理'],
  user: ['用户', '数据集管理/模型管理'],
  guest: ['访客', '模型管理']
};
