import { message, Modal } from 'antd';
import { differenceBy } from 'lodash';
import { FC, useState, Dispatch, SetStateAction } from 'react';
import request from '@/apps/utils';
import { TrainType } from '../../types';
import { Btn } from './styles';

interface DeleteModelProps {
  title: string;
  id: string;
  setTrains: Dispatch<SetStateAction<TrainType[]>>;
}

const DeleteModel: FC<DeleteModelProps> = ({ title, id, setTrains }) => {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleOk = () => {
    setConfirmVisible(false);
    request({
      url: `/train/${id}`,
      config: { method: 'DELETE' },
      messageTitle: `删除 ${id}`
    })
      .then(({ data }) => {
        setTrains((pre) => differenceBy([...pre], [data], 'trainId'));
        message.success('删除成功');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancel = () => {
    setConfirmVisible(false);
  };

  const handleClick = () => {
    setConfirmVisible(true);
  };

  return (
    <>
      <Btn onClick={handleClick}>删除</Btn>
      <Modal
        title="删除模型"
        visible={confirmVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="确定"
        cancelText="取消"
      >
        <p>{`是否将模型${title}（${id}）删除？`}</p>
      </Modal>
    </>
  );
};

export default DeleteModel;
