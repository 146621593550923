import { Form } from 'antd';
import cs from 'classnames';
import { FC } from 'react';
import { DebounceSelect } from '@/components';
import { FormValueType } from '../../types';
import request from '@/apps/utils';

interface Props {
  name: number;
  fileId: string;
}

async function fetchAddress(value: string) {
  return request({
    url: '/dataset/browse-minio-list',
    config: { method: 'GET' },
    params: { keyword: value },
    messageTitle: '样本列表'
  })
    .then(({ data }) => {
      return data.map((item: string) => ({
        label: item,
        value: item
      }));
    })
    .catch((err) => {
      console.log(err);
    });
}

const FileAddressSelect: FC<Props> = ({ name, fileId }) => {
  const form = Form.useFormInstance<FormValueType>();
  const taskType = Form.useWatch('taskType', form);
  return (
    <Form.Item
      className={cs('center', {
        multiLabelCenter: taskType === 'multiLabel'
      })}
      name={[name, 'fileAddress']}
      rules={[
        {
          required: true,
          whitespace: true,
          message: '请输入样本路径'
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            let isReject = false;
            getFieldValue('files').forEach((item: any) => {
              if (
                value &&
                item.fileAddress === value &&
                item.fileId !== fileId
              ) {
                isReject = true;
              }
            });
            return isReject
              ? Promise.reject(new Error('样本文件不能相同'))
              : Promise.resolve();
          }
        })
      ]}
    >
      <DebounceSelect fetchOptions={fetchAddress} />
    </Form.Item>
  );
};

export default FileAddressSelect;
