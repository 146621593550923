import styled from '@/theme';

export const Wrapper = styled.div`
  .card {
    margin-bottom: ${(props) => 4 * props.theme.spacing}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
