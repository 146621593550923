/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  MinusOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { ArgumentType, PropertyType } from '@/apps/dashboard/model/graph/types';

const Wrapper = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;

  & + & {
    margin-top: 12px;
  }

  > .argument-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    line-height: 1.5;

    > .argument-text {
      flex: auto;
      overflow: hidden;
      word-break: break-all;
    }

    > .argument-raw {
      overflow: auto;
      width: 100%;

      pre {
        margin: 0;
      }
    }

    > .argument-operation {
      flex: none;
      cursor: pointer;
      font-size: 14px;
      margin-left: 12px;
      color: #999;

      &:hover,
      &:active {
        color: #666;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid #ddd;
    }
  }
`;

type ArgumentProps = {
  value: ArgumentType | PropertyType;
  expand?: boolean;
  showNodeDocumentation?: () => unknown;
};

const Argument: FunctionComponent<ArgumentProps> = ({
  value,
  expand,
  showNodeDocumentation
}) => {
  const [expanded, setExpanded] = useState(expand ?? false);

  const expandable = useMemo(() => {
    const argument = value as ArgumentType;
    return !!(argument.children && argument.children.length);
  }, [value]);
  return (
    <Wrapper>
      <div className="argument-row">
        <span className="argument-text">
          {value?.name ? (
            <>
              {value.name}: <b>{value.value}</b>
            </>
          ) : (
            value?.value?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <br />}
                {line}
              </React.Fragment>
            ))
          )}
        </span>
        {(value as PropertyType).documentation && (
          <a
            className="argument-operation"
            onClick={() => showNodeDocumentation?.()}
          >
            <QuestionCircleOutlined />
          </a>
        )}
        {expandable && (
          <a
            className="argument-operation"
            onClick={() => setExpanded((e) => !e)}
          >
            {expanded ? <MinusOutlined /> : <PlusOutlined />}
          </a>
        )}
      </div>
      {expandable &&
        expanded &&
        (value as ArgumentType)?.children?.map((item, index) => (
          <div className="argument-row" key={index}>
            {item.type === 'raw' ? (
              <span className="argument-raw">
                <pre>{item.value}</pre>
              </span>
            ) : (
              <span className="argument-text">
                {item.name ? `${item.name}: ` : ''}
                <b>
                  {item.type === 'code' ? (
                    <code>{item.value}</code>
                  ) : (
                    item.value
                  )}
                </b>
              </span>
            )}
          </div>
        ))}
    </Wrapper>
  );
};

export default Argument;
