import styled from '@/theme';

export const Btn = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    cursor: pointer;
    color: #feca56;
  }
`;

export const StatusWrapper = styled.div`
  width: 20%;
  padding: ${(props) => 2 * props.theme.spacing}px;
  border-left: 1px solid #e0e2e6;
  display: flex;
  align-items: center;
  justify-content: center;
`;
