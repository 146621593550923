import { Result } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@/theme';
import ToolboxBase from '../../../../../components/toolbox';

const toolboxHeight = 40;

export const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 320px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RenderContent = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 0 : -1)};
  pointer-events: ${(props) => (props.show ? 'auto' : 'none')};
`;

export const Content = styled.div`
  height: calc(100% - ${toolboxHeight}px);
  user-select: none;
  > iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const Toolbox = styled(ToolboxBase)`
  height: ${toolboxHeight}px;
  padding: 0 20px;
`;

export const ErrorIcon = styled(ExclamationCircleOutlined)`
  font-size: 36px !important;
`;

export const ErrorMsg = styled(Result)`
  .ant-result-subtitle {
    word-break: break-all;
  }
`;
