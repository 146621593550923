import { Form, FormInstance } from 'antd';
import { Dispatch, FC } from 'react';
import {
  FormValueType,
  ActionType,
  FileType,
  DatasetType,
  TaskType
} from '../../types';
import UploadForm from './UploadForm';

interface LocalUploadProps {
  form: FormInstance<FormValueType>;
  dispatch: Dispatch<ActionType>;
  fileList: FileType[];
  taskType: TaskType;
  dataset?: DatasetType;
}

const localInitValue = [{ label: 'white' }, { label: 'black' }];

const LocalUpload: FC<LocalUploadProps> = ({
  form,
  dispatch,
  fileList,
  dataset,
  taskType
}) => {
  return (
    <Form.List
      name="files"
      initialValue={
        taskType === 'dichotomy'
          ? localInitValue
          : taskType === 'multiLabel'
          ? [{ label: [] }, { label: [] }]
          : [{ label: '0' }, { label: '1' }]
      }
      rules={[
        {
          validator: async (_, value) => {
            if (!value || value.length < 2) {
              return Promise.reject(new Error('至少添加两个类别文件'));
            }
            return Promise.resolve();
          }
        }
      ]}
    >
      {(fields, operation, { errors }) => {
        return (
          <UploadForm
            form={form}
            dataset={dataset}
            fileList={fileList}
            dispatch={dispatch}
            fields={fields}
            operation={operation}
            errors={errors}
            disabled={taskType === 'dichotomy'}
          />
        );
      }}
    </Form.List>
  );
};

export default LocalUpload;
