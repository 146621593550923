import styled from '@/theme';

export const BackTopBtn = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 28px;
  box-shadow: 0 0 5px rgb(0 0 0 / 5%);
`;
