import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Tabs } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { DescCard } from '@/components';
import request from '@/apps/utils';
import { formatSeconds } from '@/apps/utils/utils';
import { ModelType } from '../types';
import Predict from './predict';
import { ExportBtn } from './styles';
import { openNotification } from './utils';

interface Props {
  modelId: string;
}

const ModelDetail: FC<Props> = ({ modelId }) => {
  const [model, setModel] = useState<ModelType>();
  const [loading, setLoading] = useState(true);

  const [downloading, setDownloading] = useState(false);
  const [tensorDownloading, setTensorDownloading] = useState(false);

  useEffect(() => {
    let isMount = true;
    request({
      url: `/models/${modelId}?verbose=true`,
      config: { method: 'GET' },
      messageTitle: '模型详情请求'
    })
      .then(({ data }) => isMount && setModel(data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => isMount && setLoading(false));
    return () => {
      isMount = false;
    };
  }, []);

  const properties = [
    {
      key: 'id',
      title: 'id',
      content: model?.modelId
    },
    {
      key: 'title',
      title: '名称',
      content: model?.title
    },
    {
      key: 'description',
      title: '描述',
      content: model?.description || '无'
    },
    {
      key: 'duration',
      title: '训练时长',
      content: model?.duration && formatSeconds(model.duration / 1000)
    },
    {
      key: 'createTime',
      title: '创建时间',
      content: moment(model?.createTime).format('YYYY-MM-DD[\n]HH:mm:ss')
    },
    {
      key: 'updateTime',
      title: '更新时间',
      content: moment(model?.updateTime).format('YYYY-MM-DD[\n]HH:mm:ss')
    }
  ];

  const onDownload = (
    type: string,
    setLoadingStatus: (status: boolean) => void
  ) => {
    setLoadingStatus(true);
    fetch(`/api/models/${modelId}/${type}`, {
      method: 'GET'
    })
      .then(async (res) => {
        if (!res.ok) {
          const response = await res.json();
          openNotification('导出失败', String(response.message));
          return;
        }
        const str = res.headers.get('content-disposition');
        const filename = str?.split('filename=')[1] || 'default';
        const blobUrl = window.URL.createObjectURL(await res.blob());
        const tagA = document.createElement('a');
        document.body.appendChild(tagA);
        tagA.href = blobUrl;
        tagA.download = decodeURI(filename);
        tagA.click();
        tagA.remove();
      })
      .catch((err) => {
        openNotification('导出失败', String(err.message));
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  return (
    <DescCard loading={loading} properties={properties}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="在线使用" key="upload">
          <Predict modelId={modelId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="离线使用" key="download">
          <ExportBtn
            type="primary"
            icon={<CloudDownloadOutlined />}
            onClick={() => onDownload('download', setDownloading)}
            disabled={downloading}
          >
            {downloading ? '导出中...' : '导出模型文件'}
          </ExportBtn>
          <span style={{ margin: '0 20px' }}>或</span>
          <ExportBtn
            type="primary"
            icon={<CloudDownloadOutlined />}
            onClick={() => onDownload('torch-download', setTensorDownloading)}
            disabled={tensorDownloading}
          >
            {tensorDownloading ? '导出中...' : '导出TorchServe模型文件'}
          </ExportBtn>
        </Tabs.TabPane>
      </Tabs>
    </DescCard>
  );
};

export default ModelDetail;
