import { FC } from 'react';
import ReactEcharts from 'echarts-for-react';
import { toFixed } from '@/apps/utils/utils';

interface HyperParamsTraceProps {
  keys: string[];
  data?: { values: any[][]; ids: string[] };
}

const HyperParamsTrace: FC<HyperParamsTraceProps> = ({ data, keys }) => {
  const { values, ids } = data || { values: [], ids: [] };
  const scores: number[] = [];
  values.forEach((item) => {
    scores.push(item[item.length - 1]);
  });

  const option = {
    title: {
      text: '探索轨迹',
      top: 0,
      left: 0
    },
    grid: {
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      containLabel: false
    },
    parallelAxis: keys.map((key, index) => ({
      dim: index,
      name: key,
      min: function min(value: { min: number; max: number }) {
        return value.min;
      }
    })),
    parallel: {
      left: '5%',
      bottom: '10%',
      top: '20%'
    },

    series: [
      {
        name: '',
        type: 'parallel',
        data: values
          .filter((value) => value.filter((i) => i).length === keys.length)
          .map((i) => i.map((j) => toFixed(j, 4))),
        smooth: true,
        lineStyle: {
          width: 2
        },
        emphasis: {
          lineStyle: {
            color: '#6672E8',
            width: 2
          }
        }
      }
    ],
    visualMap: {
      show: true,
      min: Math.min(...scores.filter((i) => i)),
      max: scores.length > 0 ? Math.max(...scores) : 1,
      precision: 4,
      dimension: keys.length > 0 ? keys.length - 1 : 0,
      inRange: {
        color: ['#d94e5d', '#eac736', '#50a3ba'].reverse()
      },
      bottom: 0
    },
    tooltip: {
      padding: 10,
      borderColor: 'transparent',
      formatter: (params: {
        data: number[];
        marker: any;
        dataIndex: number;
      }) => {
        if (!params) return '';
        const { data: paramsData, marker } = params;
        let str = `${marker} No.${ids[params.dataIndex]}</br>`;
        for (let i = 0; i < paramsData.length; i += 1) {
          str = `${str + keys[i]}: ${paramsData[i]} </br>`;
        }
        return str;
      }
    }
  };
  return (
    <div
      style={{
        height: '432px',
        background: '#f5f6f9',
        padding: '16px',
        borderRadius: '4px'
      }}
    >
      <ReactEcharts
        style={{ height: '400px', width: '100%' }}
        option={option}
      />
    </div>
  );
};

export default HyperParamsTrace;
