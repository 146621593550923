import { FC } from 'react';
import { Form, FormInstance } from 'antd';
import InputForm from './InputForm';
import { FormValueType, TaskType } from '../../types';

interface OnlineUploadProps {
  taskType: TaskType;
  form: FormInstance<FormValueType>;
}

const localInitValue = [{ label: 'white' }, { label: 'black' }];

const OnlineUpload: FC<OnlineUploadProps> = ({ taskType, form }) => {
  return (
    <Form.List
      name="files"
      initialValue={
        taskType === 'dichotomy'
          ? localInitValue
          : taskType === 'multiLabel'
          ? [{ label: [] }, { label: [] }]
          : [{ label: '0' }, { label: '1' }]
      }
      rules={[
        {
          validator: async (_, value) => {
            if (!value || value.length < 2) {
              return Promise.reject(new Error('至少添加两个类别文件'));
            }
            return Promise.resolve();
          }
        }
      ]}
    >
      {(fields, operation, { errors }) => {
        return (
          <InputForm
            fields={fields}
            operation={operation}
            isDichotomy={taskType === 'dichotomy'}
            errors={errors}
            form={form}
          />
        );
      }}
    </Form.List>
  );
};

export default OnlineUpload;
