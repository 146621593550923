import { PaperClipOutlined } from '@ant-design/icons';
import styled from '@/theme';

export const Wrapper = styled.div`
  border-right: 1px solid rgb(245 246 249);
  flex: 1;
  padding: 20px 20px 20px 10px;
`;

export const Title = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const ListWrapper = styled.div`
  height: 280px;
  overflow: auto;
`;

export const ListItemWrapper = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  line-height: 34px;
  padding-left: 10px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  &:hover {
    background: #fafafa;
    border-radius: 4px;
  }
`;

export const Icon = styled(PaperClipOutlined)`
  color: ${(props) => props.theme.palette.primary.main};
`;

export const FileName = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 6px;
`;

export const Btn = styled.div`
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
`;

export const FileNameWrapper = styled.div`
  height: 34px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
