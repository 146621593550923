import { useRouteMatch } from 'react-router-dom';
import Switch from '@/components/routers';
import algorithmSettingRoutes from './routes';

const AlgorithmSetting = () => {
  const { path } = useRouteMatch();
  return <Switch routes={algorithmSettingRoutes} basePath={path} />;
};

export default AlgorithmSetting;
