import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';
import request from '@/apps/utils';
import { AlgorithmType } from '@/apps/dashboard/model/modelCreate/types';
import { PageWrapper } from '@/components';
import AlgorithmCard from './algorithmCard';
import { Wrapper } from './styles';

interface ListProps {
  className?: string;
}

const List: FC<ListProps> = () => {
  const [algorithms, setAlgorithms] = useState<AlgorithmType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    request({
      url: '/algorithms',
      config: {
        method: 'GET'
      },
      messageTitle: '算法列表请求'
    })
      .then(({ data }) => {
        setAlgorithms(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <PageWrapper
      headerProps={{ title: '算法列表' }}
      childrenCardProps={{ loading }}
    >
      <Wrapper>
        {isEmpty(algorithms) ? (
          <Empty description="暂无数据" />
        ) : (
          algorithms.map((algorithm) => {
            return (
              <AlgorithmCard
                key={algorithm.algorithmId}
                algorithm={algorithm}
              />
            );
          })
        )}
      </Wrapper>
    </PageWrapper>
  );
};

export default List;
