const breadcrumbNameMap = {
  'manage-dataset': '数据集',
  'manage-project': '项目',
  'manage-train': '训练',
  'manage-model': '模型',
  'manage-account': '用户',
  setting: '设置',
  'manage-algorithm': '算法',
  list: '列表',
  create: '新建',
  edit: '编辑',
  description: '介绍',
  parameter: '参数'
};

export default breadcrumbNameMap;
