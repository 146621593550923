import { CSSProperties, FC } from 'react';
import { EChartsOption, RegisteredSeriesOption } from 'echarts';
import { merge } from 'lodash';
import numeral from 'numeral';
import ReactEcharts from 'echarts-for-react';

interface BasicLineChartOption {
  series: RegisteredSeriesOption['line'][];
}

const defaultSeriesOption: EChartsOption = {
  type: 'line',
  showSymbol: false
};

const defaultOption: EChartsOption = {
  toolbox: {
    show: false
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    type: 'scroll',
    right: 10,
    top: 0,
    itemWidth: 16,
    itemHeight: 8,
    textStyle: {
      fontSize: 14,
      color: '#696E7B'
    }
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '5%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    splitLine: {
      show: false
    },
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#696E7B'
    },
    axisLine: {
      lineStyle: {
        color: '#CCD0D9'
      }
    }
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#CCD0D9'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#696E7B',
      formatter: (value: number) => {
        return numeral(value).format('0.[0]a');
      }
    },
    splitLine: {
      lineStyle: {
        type: 'dotted'
      }
    }
  }
};

interface BasicLineChartProps {
  option: BasicLineChartOption;
  className?: string;
  style?: CSSProperties;
  onLegendSelected?: (name: string) => void;
}
const BasicLineChart: FC<BasicLineChartProps> = ({
  option,
  className,
  style,
  onLegendSelected
}) => {
  const getOption = () => {
    return merge({}, defaultOption, option, {
      series: option.series.map((t) => merge({}, defaultSeriesOption, t))
    });
  };
  const onLegendSelectChanged = (e: {
    name: string;
    selected: { [key in string]: boolean };
  }) => {
    if (e.selected !== undefined) {
      if (onLegendSelected) {
        onLegendSelected(e.name);
      }
    }
  };
  const onEvents = {
    legendselectchanged: onLegendSelectChanged
  };
  return (
    <ReactEcharts
      className={className}
      option={getOption()}
      style={{ minHeight: '350px', ...style }}
      onEvents={onEvents}
    />
  );
};

export default BasicLineChart;
