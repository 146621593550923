import { VerticalAlignTopOutlined } from '@ant-design/icons';
import {
  BackTop,
  Breadcrumb,
  Card,
  CardProps,
  PageHeader as PageHeaderBase,
  PageHeaderProps
} from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import breadcrumbNameMap from './breadcrumbNameMap';
import { BackTopBtn } from './styles';

interface PageWrapperProps {
  children: React.ReactNode;
  headerProps?: PageHeaderProps;
  childrenCardProps?: CardProps;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  headerProps,
  childrenCardProps
}) => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const breadcrumbItems = pathSnippets.map((i, index) => {
    const toPath = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={toPath}>
        <Link to={toPath}>{breadcrumbNameMap[i] || i}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <>
      <Card size="small">
        <PageHeaderBase
          breadcrumb={<Breadcrumb>{breadcrumbItems}</Breadcrumb>}
          {...headerProps}
        />
      </Card>
      <Card {...childrenCardProps}>{children}</Card>
      <BackTop>
        <BackTopBtn>
          <VerticalAlignTopOutlined />
        </BackTopBtn>
      </BackTop>
    </>
  );
};

export default PageWrapper;
