import { ChangeEvent, Dispatch, FC, useEffect, ReactNode } from 'react';
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import cs from 'classnames';
import { find } from 'lodash';
import { RcFile } from 'antd/lib/upload';
import {
  Button,
  Form,
  FormInstance,
  FormListFieldData,
  FormListOperation,
  Input,
  Tooltip,
  Upload
} from 'antd';
import {
  ActionType,
  ChunkType,
  DatasetType,
  FileType,
  FormValueType,
  MAX_FILE_SIZE
} from '../../types';
import { computeHash, createFileChunk } from '../utils';
import UploadItem from './UploadItem';
import { UploadWrapper } from '../styles';
import { actionCreators } from '../store';
import MultiLabelInput from './MultiLabelInput';

interface UploadFormProps {
  form: FormInstance<FormValueType>;
  dispatch: Dispatch<ActionType>;
  fileList: FileType[];
  fields: FormListFieldData[];
  operation: FormListOperation;
  disabled: boolean;
  errors: ReactNode[];
  dataset?: DatasetType;
}
const UploadForm: FC<UploadFormProps> = ({
  form,
  fields,
  dispatch,
  fileList,
  operation: { add, remove },
  dataset,
  disabled,
  errors
}) => {
  const formFilesValue = Form.useWatch('files', form);
  const taskType = Form.useWatch('taskType', form);

  useEffect(() => {
    formFilesValue?.forEach((item) => {
      if (item && item.fileId) {
        dispatch(actionCreators.initialize(item.label, item.fileId));
      }
    });
  }, [formFilesValue]);

  const beforeUpload = async (file: RcFile, fileId: string) => {
    const chunks = createFileChunk(file);
    const fileHash: string = await computeHash(chunks, (value: string) =>
      dispatch(actionCreators.setPercent(value, fileId))
    );
    dispatch(actionCreators.setHashAndOriginFile(fileHash, file, fileId));

    const temp = chunks.map((item, index) => ({
      chunk: item.chunk,
      chunkHash: `${fileHash}_${index}`,
      filename: file.name,
      fileHash,
      progress: 0,
      size: item.chunk.size
    }));
    dispatch(actionCreators.setChunks(temp, fileId));
    return false;
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>, fileId: string) => {
    const label = e.target.value;
    dispatch(actionCreators.setLabel(label, fileId));
  };

  return (
    <UploadWrapper>
      {fields.map(({ key, name }) => {
        const fileId = String(key);
        const currFile = find(fileList, { fileId });
        return (
          <Form.Item key={fileId} wrapperCol={{ offset: 4, span: 12 }}>
            <Form.Item hidden name={[name, 'fileId']} initialValue={fileId}>
              <div>key: {fileId}</div>
            </Form.Item>
            {(taskType === 'polytomous' || taskType === 'dichotomy') && (
              <Form.Item
                className="left"
                initialValue={fileId}
                name={[name, 'label']}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value === undefined || value === '') {
                        return Promise.reject(new Error('请输入标签名'));
                      }
                      let isReject = false;
                      for (let i = fileList.length - 1; i >= 0; i -= 1) {
                        if (
                          fileList[i].label === currFile?.label &&
                          fileList[i].fileId !== currFile?.fileId
                        ) {
                          isReject = true;
                          break;
                        }
                      }
                      return isReject
                        ? Promise.reject(new Error('标签名不能相同'))
                        : Promise.resolve();
                    }
                  })
                ]}
              >
                <Input
                  disabled={disabled}
                  placeholder="标签名"
                  onChange={(e) => onChange(e, fileId)}
                />
              </Form.Item>
            )}
            {taskType === 'multiLabel' && (
              <MultiLabelInput
                name={name}
                fileId={fileId}
                dispatch={dispatch}
                fileList={fileList}
              />
            )}
            <Form.Item
              className={cs('center', {
                multiLabelCenter: taskType === 'multiLabel'
              })}
              name={[name, 'file']}
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: '请添加样本文件'
                },
                () => ({
                  validator() {
                    if (
                      currFile &&
                      currFile.originFile &&
                      currFile.originFile.size >= MAX_FILE_SIZE
                    ) {
                      return Promise.reject(new Error(`文件大小不能超过500M`));
                    }
                    let isReject = false;
                    fileList.forEach((file) => {
                      if (
                        currFile?.fileHash &&
                        file.fileHash === currFile.fileHash &&
                        file.fileId !== currFile?.fileId
                      ) {
                        isReject = true;
                      }
                    });
                    return isReject
                      ? Promise.reject(new Error('样本文件不能相同'))
                      : Promise.resolve();
                  }
                })
              ]}
            >
              <Upload
                disabled={form.getFieldValue([name, 'label'])}
                accept=".zip"
                beforeUpload={async (file: RcFile) => {
                  await beforeUpload(file, String(fileId));
                  return false;
                }}
                maxCount={1}
                listType="picture"
                itemRender={(originNode, file) => {
                  return (
                    <UploadItem
                      originFile={file}
                      fileId={fileId}
                      dataset={dataset}
                      fileList={fileList}
                      setChunks={(value: ChunkType[]) => {
                        dispatch(actionCreators.setChunks(value, fileId));
                      }}
                      setFileStatus={(status: string) => {
                        dispatch(actionCreators.setFileStatus(status, fileId));
                      }}
                    />
                  );
                }}
              >
                <Button style={{ width: '200px' }} icon={<UploadOutlined />}>
                  {currFile &&
                  currFile.hashPercent > 0 &&
                  currFile.hashPercent < 100 ? (
                    `文件处理中...(${currFile.hashPercent}%)`
                  ) : (
                    <Tooltip title="仅支持大小不超过500M的zip文件">
                      添加样本
                    </Tooltip>
                  )}
                </Button>
              </Upload>
            </Form.Item>
            {!disabled && (
              <span className="minusIcon">
                <MinusCircleOutlined
                  onClick={() => {
                    dispatch(actionCreators.removeFile(fileId));
                    remove(name);
                  }}
                />
              </span>
            )}
          </Form.Item>
        );
      })}
      {!disabled && (
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button
            style={{ width: '200px' }}
            onClick={() => {
              add();
            }}
            icon={<PlusOutlined />}
          >
            添加类别文件
          </Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      )}
    </UploadWrapper>
  );
};

export default UploadForm;
