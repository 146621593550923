import { Button as ButtonBase } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from '@/theme';

export const AddIcon = styled(PlusOutlined)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .card {
    margin-bottom: ${(props) => 4 * props.theme.spacing}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .property {
    display: flex;
    align-items: center;
    height: 48px;
    .successIcon {
      font-size: 20px;
      color: ${(props) => props.theme.palette.primary.success};
    }
    .errorIcon {
      font-size: 20px;
      color: ${(props) => props.theme.palette.primary.error};
    }
    .loadingIcon {
      font-size: 20px;
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${(props) => 4 * props.theme.spacing}px;
  float: right;
  margin-left: auto;
  display: flex;
`;

export const Button = styled(ButtonBase).attrs({ type: 'primary' })``;
