import { BrowserRouter as Router } from 'react-router-dom';
import Switch from '@/components/routers';
import indexRoutes from './routes';
import { AppsWrapper } from './styles';
import Sidebar from './sidebar';
import { ProvideAuth } from './authentication/hooks';
import Login from './authentication/login';
import EnsureLogged from './authentication/EnsureLogged';

const Apps = () => {
  return (
    <ProvideAuth>
      <Router>
        <Switch
          routes={[
            {
              key: 'login',
              path: '/login',
              component: Login
            },
            {
              key: 'apps',
              path: '/',
              component: () => (
                <EnsureLogged>
                  <AppsWrapper hasSider>
                    <Sidebar />
                    <Switch routes={indexRoutes} />
                  </AppsWrapper>
                </EnsureLogged>
              )
            }
          ]}
        />
      </Router>
    </ProvideAuth>
  );
};

export default Apps;
