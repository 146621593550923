import { Modal } from 'antd';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { Descriptions } from '@/components';
import request from '@/apps/utils';
import Properties from '@/components/properties';
import { ProjectType } from '../modelCreate/types';

interface ProjectDetailProps {
  project: ProjectType;
  visible: boolean;
  onCancel: (e: MouseEvent<HTMLElement>) => void;
}

const ProjectDetail: FC<ProjectDetailProps> = ({
  project,
  visible,
  onCancel
}) => {
  const [algorithmTitle, setAlgorithmTitle] = useState('');
  const { description, algorithmId, searchSpace } = project;
  useEffect(() => {
    request({
      url: `/algorithms/${algorithmId}`,
      config: { method: 'GET' },
      messageTitle: '训练所使用算法详情请求'
    })
      .then(({ data }) => {
        setAlgorithmTitle(data.title);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const properties = [
    {
      key: 'description',
      title: '描述',
      content: description || '无'
    },
    {
      key: 'algorithmId',
      title: '算法',
      content: `${algorithmTitle}（${algorithmId}）`
    }
  ];
  const searchSpaceProperties = searchSpace.map(
    ({ key, value, description: des }) => ({
      key,
      value: isArray(value) ? value.join(',') : value,
      label: des
    })
  );
  return (
    <Modal
      title="模型创建详情"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={700}
    >
      <Properties properties={properties} propertyWidth="100%" />
      <Descriptions
        title="探索范围"
        properties={searchSpaceProperties}
        bordered
        size="middle"
        column={2}
      />
    </Modal>
  );
};

export default ProjectDetail;
