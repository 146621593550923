import { InputRef, Table } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {
  FilterDropdownProps,
  FilterValue,
  SorterResult,
  TablePaginationConfig
} from 'antd/lib/table/interface';
import { isArray } from 'lodash';
import useATRH from '@minko-fe/use-antd-resizable-header';
import '@minko-fe/use-antd-resizable-header/dist/style.css';
import { Title } from '@/components';
import {
  DEFAULT_PAGE_SIZE,
  RequestParams,
  UploadResultType
} from '../../types';
import FilterDropdown from './FilterDropdown';
import { openNotification } from '../utils';
import { Wrapper, Btn, BtnWrapper } from './styles';

interface PredictedResultProps {
  serveId: string;
  total: number;
  uploadResult: UploadResultType;
  setRequestParams: (requestParams: RequestParams) => void;
  requestParams: RequestParams;
  getResultData(id: string, reqParams: RequestParams): void;
}

interface DataType {
  key: number;
  label: string;
  prob: number;
  data: string;
}

const PredictedResult: FC<PredictedResultProps> = ({
  serveId,
  total,
  uploadResult,
  setRequestParams,
  requestParams,
  getResultData
}) => {
  const searchInputRef = useRef<InputRef>(null);
  const [exportLoading, setExportLoading] = useState(false);

  const rDataSource = (uploadResultData: UploadResultType): DataType[] => {
    const dataSource: DataType[] = [];
    const { label, prob, data } = uploadResultData;
    prob.forEach((item, index) => {
      dataSource.push({
        key: index,
        label: label[index] !== null ? label[index] : 'N/A',
        prob: item,
        data: data[index].replace(/\/serve\//, '')
      });
    });
    return dataSource;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    const { current, pageSize } = pagination;
    if (pageSize && current && filters && !isArray(sorter)) {
      setRequestParams({
        ...requestParams,
        pageSize,
        page: current,
        label: filters.label as string[],
        probSort: sorter.order
      });
    }
  };

  useEffect(() => {
    getResultData(serveId, requestParams);
  }, [requestParams]);
  const rColumns = [
    {
      width: 460,
      title: '数据',
      dataIndex: 'data',
      key: 'data',
      ellipsis: true,
      filteredValue:
        (requestParams.dataFilter && [requestParams.dataFilter]) || null,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterDropdown
          setSearchText={(text) =>
            setRequestParams({ ...requestParams, dataFilter: text })
          }
          searchInputRef={searchInputRef}
          {...props}
        />
      ),
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInputRef.current?.select(), 100);
        }
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#FAAD14' : undefined }} />
      ),
      render: (text: string) => (
        <Highlighter
          title={text}
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[requestParams.dataFilter || '']}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      )
    },
    {
      width: 460,
      title: '预测标签',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      filters: uploadResult.labels?.map((item) => ({
        text: item,
        value: item
      })),
      filteredValue: requestParams.label || null,
      filterResetToDefaultFilteredValue: true,
      defaultFilteredValue: []
    },
    {
      title: '预测概率',
      dataIndex: 'prob',
      key: 'prob',
      sorter: true,
      sortOrder: requestParams.probSort
    }
  ];
  const onExportResult = () => {
    setExportLoading(true);
    fetch(`/api/models/${serveId}/serve-result-download`, {
      method: 'GET'
    })
      .then(async (res) => {
        if (!res.ok) {
          const response = await res.json();
          openNotification('导出失败', String(response.message));
          return;
        }
        const str = res.headers.get('content-disposition');
        const filename = str?.split('filename=')[1] || 'default';
        const blobUrl = window.URL.createObjectURL(await res.blob());
        const tagA = document.createElement('a');
        document.body.appendChild(tagA);
        tagA.href = blobUrl;
        tagA.download = decodeURI(filename);
        tagA.click();
        tagA.remove();
      })
      .catch((err) => {
        openNotification('导出失败', String(err.message));
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  const onReset = () => {
    setRequestParams({
      pageSize: DEFAULT_PAGE_SIZE,
      page: 1,
      probSort: undefined,
      dataFilter: '',
      label: undefined
    });
  };

  const { components, resizableColumns, tableWidth } = useATRH({
    columns: useMemo(() => rColumns, [requestParams]),
    minConstraints: 70
  });

  return (
    <Wrapper>
      <Title divider={false} title="测试结果">
        <BtnWrapper>
          <Btn type="primary" onClick={onExportResult} disabled={exportLoading}>
            导出预测结果
          </Btn>
          <Btn type="primary" onClick={onReset}>
            重置筛选条件
          </Btn>
        </BtnWrapper>
        <Table
          columns={resizableColumns}
          components={components}
          scroll={{ x: tableWidth }}
          pagination={{
            pageSize: requestParams.pageSize,
            total,
            showQuickJumper: true
          }}
          dataSource={rDataSource(uploadResult)}
          onChange={handleTableChange}
          rowKey="key"
        />
      </Title>
    </Wrapper>
  );
};

export default PredictedResult;
