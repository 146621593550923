import { Col, Row } from 'antd';
import {
  useEffect,
  useState,
  useRef,
  FC,
  Dispatch,
  SetStateAction
} from 'react';
import ReactEcharts from 'echarts-for-react';
import { isEmpty } from 'lodash';
import { BasicHorizontalBarChart } from '@/components/charts';
import { formatSeconds, toFixed } from '@/apps/utils/utils';
import request from '@/apps/utils';
import { TrainStatusType, TrialsOrderType, TrialType } from './types';
import TrialTable from './TrialTable';

interface DurationStatisticProps {
  trainId: string;
  trainStatus: TrainStatusType;
  trials: TrialType[];
  setTrials: Dispatch<SetStateAction<TrialType[]>>;
}

const DurationStatistic: FC<DurationStatisticProps> = ({
  trainId,
  trainStatus,
  trials,
  setTrials
}) => {
  const timer = useRef<any>();
  const statusRef = useRef<TrialType[]>();
  const [trialsOrder, setTrialsOrder] = useState<TrialsOrderType>({
    sequenceId: 'ASC'
  });
  statusRef.current = trials;

  const getSort = () => {
    if (isEmpty(trialsOrder)) {
      return '';
    }
    let sort = '?order=';
    Object.keys(trialsOrder).forEach((key, index) => {
      sort += `${key}=${trialsOrder[key]}`;
      if (index !== Object.keys(trialsOrder).length - 1) {
        sort += ',';
      }
    });
    return sort;
  };

  const getTrials = () => {
    if (statusRef.current) {
      request({
        url: `/train/${trainId}/trialJobs${getSort()}`,
        config: { method: 'GET' }
      })
        .then(({ data }) => {
          setTrials(data);
        })
        .catch((err) => {
          console.error(err);
          if (timer.current) {
            clearInterval(timer.current);
          }
        });
    }
  };

  useEffect(() => {
    getTrials();
    timer.current = setInterval(() => {
      getTrials();
    }, 5000);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [trialsOrder]);

  useEffect(() => {
    if (
      trainStatus.status !== 'WAITING' &&
      trainStatus.status !== 'RUNNING' &&
      timer.current
    ) {
      getTrials();
      clearInterval(timer.current);
    }
  }, [trainStatus]);

  const barOption = {
    title: {
      text: '耗时统计'
    },
    tooltip: {
      formatter: (params: any) => {
        return `${params.name}</br>${params.marker} ${formatSeconds(
          params.value
        )}`;
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: '10%',
      containLabel: true
    },
    xAxis: {
      show: false
    },
    yAxis: {
      type: 'category',
      inverse: true,
      data: trials.map((i) => `No.${i.sequenceId}`),
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    series: [
      {
        name: '',
        data: trials.map((i) => i.duration / 1000),
        barMaxWidth: 10,
        itemStyle: {
          color: '#6672E8',
          opacity: 0.8
        }
      }
    ],
    animation: false
  };

  const lineOption = {
    title: {
      text: '评分统计'
    },
    xAxis: {
      type: 'category',
      data: trials.map((i) => `No.${i.sequenceId}`)
    },
    yAxis: {
      type: 'value',
      min: 'dataMin'
    },
    tooltip: {
      trigger: 'axis'
    },
    series: [
      {
        type: 'line',
        showSymbol: true,
        data: trials.map((i) => i.score && toFixed(i.score, 4)),
        symbolSize: 10,
        lineStyle: {
          color: '#6672E8'
        }
      }
    ],
    animation: false
  };
  return (
    <>
      {trials && trials.length > 0 && (
        <>
          <Col span={12}>
            <Row
              gutter={[0, 8]}
              wrap
              style={{
                height: '700px',
                background: '#f5f6f9',
                padding: '8px',
                borderRadius: '4px'
              }}
            >
              <Col span={24}>
                <ReactEcharts style={{ height: '350px' }} option={lineOption} />
              </Col>
              <Col span={24}>
                <div style={{ height: '350px' }}>
                  <BasicHorizontalBarChart
                    style={{ height: '100%' }}
                    option={barOption}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <TrialTable
              trainId={trainId}
              trials={trials}
              setTrials={setTrials}
              setTrialsOrder={setTrialsOrder}
            />
          </Col>
        </>
      )}
    </>
  );
};

export default DurationStatistic;
