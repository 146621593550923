import { Empty, Select, SelectProps, Spin } from 'antd';
import { debounce } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  fetchOptions: (value: any) => Promise<any>;
  onChange?: (value: any, option: any) => void;
  debounceTimeout?: number;
  selectProps?: SelectProps;
  value?: string;
}

const DebounceSelect: FC<Props> = ({
  fetchOptions,
  onChange,
  debounceTimeout = 800,
  selectProps,
  value
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (v: any) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(v).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    debounceFetcher('');
  }, []);

  return (
    <Select
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
      options={options}
      onChange={onChange}
      value={value}
      {...selectProps}
    />
  );
};

export default DebounceSelect;
