import Switch from '@/components/routers';
import { SiteLayout, AppsMainContent } from '../styles';
import dashboardRoutes from './routes';
import Header from '../header';

const Dashboard = () => {
  return (
    <SiteLayout>
      <Header />
      <AppsMainContent>
        <Switch routes={dashboardRoutes} />
      </AppsMainContent>
    </SiteLayout>
  );
};

export default Dashboard;
