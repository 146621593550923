import React, { useState } from 'react';
import {
  LogoutOutlined,
  UserOutlined,
  CarryOutOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Form, Modal } from 'antd';
import DropDown from '@/components/dropdown';
import { Avatar, AvatarWrapper, Spin, Menu } from './styles';
import { classes } from '../styles';
import { useAuth } from '@/apps/authentication/hooks';
import MyAccount from '../myAccount';

const menu = [
  {
    key: 'account',
    icon: <UserOutlined />,
    label: '我的账号'
  },
  {
    key: 'version',
    icon: <CarryOutOutlined />,
    label: `系统版本 ${process.env.VERSION}`
  },
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: '退出登录'
  }
];

const AvatarComponent = () => {
  const auth = useAuth();
  const history = useHistory();
  const { currentUser } = auth;
  const [isMyAccountVisible, setIsMyAccountVisible] = useState(false);

  const [form] = Form.useForm();

  const onMenuClick = (event: { key: React.Key }) => {
    const { key } = event;
    if (key === 'account') {
      setIsMyAccountVisible(true);
    }
    if (key === 'logout') {
      auth.logout(() => {
        history.push('/login');
      });
    }
  };

  const menuHeaderDropdown = <Menu onClick={onMenuClick} items={menu} />;

  const onCancel = () => {
    setIsMyAccountVisible(false);
    form.resetFields();
  };

  return currentUser && currentUser.displayName ? (
    <>
      <DropDown overlay={menuHeaderDropdown}>
        <AvatarWrapper className={classes.action}>
          <Avatar>{[...currentUser.displayName][0].toUpperCase()}</Avatar>
          <span>{currentUser.displayName}</span>
        </AvatarWrapper>
      </DropDown>
      <Modal
        title="我的账号"
        visible={isMyAccountVisible}
        onCancel={onCancel}
        footer={null}
      >
        <MyAccount onCancel={onCancel} form={form} />
      </Modal>
    </>
  ) : (
    <Spin size="small" />
  );
};

export default AvatarComponent;
