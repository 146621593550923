import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FC } from 'react';
import { ModelType } from '../../model/types';
import { toFixed } from '@/apps/utils/utils';

interface DataType {
  modelId: string;
  title: string;
  score: string;
  trainId: string;
  createTime: string;
  updateTime: string;
}

type Props = { models: ModelType[] };

const ModelDetail: FC<Props> = ({ models }) => {
  const columns: ColumnsType<DataType> = [
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, record: DataType) => (
        <Link to={`/manage-model/${record.trainId}/evaluate`}>{text}</Link>
      )
    },
    {
      title: 'id',
      dataIndex: 'modelId',
      key: 'id'
    },
    {
      title: '评分',
      dataIndex: 'score',
      key: 'score'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime'
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime'
    }
  ];

  const data = models.map((model) => {
    const { title, modelId, score, createTime, updateTime, trainId } = model;
    return {
      key: modelId,
      title,
      modelId,
      score: `${toFixed(+score, 4)}`,
      createTime: moment(createTime).format('YYYY-MM-DD[\n]HH:mm:ss'),
      updateTime: moment(updateTime).format('YYYY-MM-DD[\n]HH:mm:ss'),
      trainId
    };
  });
  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default ModelDetail;
