import { FC, ReactNode } from 'react';
import { Loading } from '@/components';
import { Section, Article, Aside } from './styles';

type ContentProps = {
  children: ReactNode;
  aside?: ReactNode;
  loading?: boolean;
};

const Content: FC<ContentProps> = ({ children, aside, loading }) => (
  <Section>
    <Article>{children}</Article>
    {aside && <Aside>{aside}</Aside>}
    {loading && <Loading />}
  </Section>
);

export default Content;
