import {
  Card as CardBase,
  Typography as TypographyBase,
  Progress as ProgressBase
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  StopOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import DescCard from '@/components/descCard';

import styled from '@/theme';

export const Property = styled(CardBase.Grid).attrs({ hoverable: true })`
  width: 100%;
  text-align: center;
  display: flex;
  box-shadow: 0 0;
  padding: ${(props) => 2 * props.theme.spacing}px;
`;

export const Typography = styled(TypographyBase)``;

export const Progress = styled(ProgressBase)`
  .ant-progress-inner {
    background-color: #eaecf2;
  }
`;

export const DoneIcon = styled(CheckCircleOutlined)`
  color: #52c41a;
  margin-right: ${(props) => 2 * props.theme.spacing}px;
`;

export const FailedIcon = styled(CloseCircleOutlined)`
  color: #ff4d4f;
  margin-right: ${(props) => 2 * props.theme.spacing}px;
`;

export const StoppedIcon = styled(StopOutlined)`
  color: #fbad15;
  margin-right: ${(props) => 2 * props.theme.spacing}px;
`;

export const WaitingIcon = styled(HourglassOutlined).attrs({ spin: true })`
  margin-right: ${(props) => 2 * props.theme.spacing}px;
`;

export const RunIcon = styled(LoadingOutlined)`
  margin-right: 8px;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const TrialsWrapper = styled.div`
  width: 50%;
  margin-bottom: 16px;
`;

export const GrayCard = styled(CardBase)`
  background-color: ${(props) => props.theme.palette.background.card};
`;

export const TextBtn = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    text-decoration: underline;
  }
`;

export const DCard = styled(DescCard)`
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
  .cardBody {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ModalBtn = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
  }
`;

export const KillTrialWrapper = styled.div``;
