import { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import request from '@/apps/utils';
import { ListItemType } from './types';
import TextContent from '../textContent';
import FileContent from '../fileContent';
import { DataSourceType } from '../types';

interface TextTableProps {
  datasetId: string;
  subsetId: string;
  dataLabel: string;
  dataType: string;
  columns: ColumnsType<any>;
}
const FilesTable: FC<TextTableProps> = ({
  datasetId,
  subsetId,
  columns,
  dataLabel,
  dataType
}) => {
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fileList, setFileList] = useState<ListItemType[]>([]);

  const [selectedRow, setSelectedRow] = useState<DataSourceType>();
  const [fileContentVisible, setFileContentVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFileList = (id: string, p: number, pSize: number) => {
    setLoading(true);
    request({
      url: `/subset/${id}/browse-list`,
      config: { method: 'GET' },
      params: { page: p, pageSize: pSize },
      messageTitle: '数据集文件列表请求'
    })
      .then(({ data }) => {
        setFileList(data.result);
        setTotal(data.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getFileList(subsetId, 1, pageSize);
  }, []);

  const onPaginationChange = (p: number, pSize: number) => {
    setPageSize(pSize);
    getFileList(subsetId, p, pSize);
  };

  const dataSource = fileList.map((item) => {
    return {
      ...item,
      lastModified: moment(item.lastModified).format('YYYY-MM-DD[\n]HH:mm:ss'),
      key: item.path
    };
  });

  return (
    <>
      <Table
        loading={loading}
        pagination={{
          pageSize,
          total,
          onChange: onPaginationChange,
          showQuickJumper: true
        }}
        columns={columns}
        dataSource={dataSource}
        expandable={
          dataType === 'text'
            ? {
                expandRowByClick: true,
                expandedRowRender: (record) => (
                  <TextContent
                    datasetId={datasetId}
                    label={dataLabel}
                    path={record.path}
                  />
                )
              }
            : undefined
        }
        onRow={
          dataType === 'text'
            ? undefined
            : (record) => ({
                onClick: () => {
                  setSelectedRow(record);
                  setFileContentVisible(true);
                }
              })
        }
      />
      {dataType !== 'text' && selectedRow && (
        <FileContent
          setFileContentVisible={setFileContentVisible}
          fileContentVisible={fileContentVisible}
          selectedRow={selectedRow}
          label={dataLabel}
          datasetId={datasetId}
        />
      )}
    </>
  );
};

export default FilesTable;
