import { Link, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { PageWrapper } from '@/components';
import request from '@/apps/utils';
import Detail from './Detail';
import RelatedModels from '../datasetList/RelatedModels';

const DatasetDetail = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const [status, setStatus] = useState('');
  useEffect(() => {
    request({
      url: `/dataset/${datasetId}`,
      config: { method: 'GET' }
    })
      .then(({ data }) => {
        setStatus(data.status);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <PageWrapper
      headerProps={{
        title: '数据集详情',
        extra: (
          <>
            <RelatedModels datasetId={datasetId} buttonType="primary" />
            {status === 'DONE' && (
              <Button type="primary">
                <Link
                  to={{
                    pathname: '/manage-model/create',
                    state: { datasetId }
                  }}
                >
                  <PlusOutlined />
                  {` `} 创建模型
                </Link>
              </Button>
            )}
          </>
        )
      }}
    >
      <Detail datasetId={datasetId} />
    </PageWrapper>
  );
};

export default DatasetDetail;
