import styled from '@/theme';

export const AsideSection = styled.section`
  margin: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 12px;
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.div<{ width?: number }>`
  width: ${(props) => props.width || 250}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > .top {
    flex: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;

    > ${AsideSection} {
      flex: none;
    }
  }

  > .bottom {
    flex: none;
    box-shadow: ${(props) => props.theme.shadow};
    padding: 12px;
  }
`;
