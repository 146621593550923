import { DeleteOutlined } from '@ant-design/icons';
import { differenceBy } from 'lodash';
import { FC, useState } from 'react';
import { RcFile } from '../types';
import {
  ListItemWrapper,
  Icon,
  FileName,
  Btn,
  FileNameWrapper
} from './styles';

interface Props {
  file: RcFile;
  uploadList: RcFile[];
  setUploadList: (uploadList: RcFile[]) => void;
}

const ListItem: FC<Props> = ({ file, setUploadList, uploadList }) => {
  const [showRemoveIcon, setShowRemoveIcon] = useState(false);

  const handleDelete = (value: RcFile) => {
    setUploadList(differenceBy([...uploadList], [value], 'uid'));
  };
  const onMouseEnter = () => {
    setShowRemoveIcon(true);
  };
  const onMouseLeave = () => {
    setShowRemoveIcon(false);
  };

  return (
    <ListItemWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <FileNameWrapper>
        <Icon />
        <FileName>{file.name}</FileName>
      </FileNameWrapper>
      <Btn
        style={!showRemoveIcon ? { visibility: 'hidden' } : {}}
        onClick={() => handleDelete(file)}
      >
        <DeleteOutlined />
      </Btn>
    </ListItemWrapper>
  );
};

export default ListItem;
