import { CopyOutlined } from '@ant-design/icons';
import styled from '@/theme';

export const CopyIcon = styled(CopyOutlined)`
  width: 16px;
  cursor: pointer;
  color: #faad14;
`;

export const Content = styled.span`
  font-weight: 600;
`;
