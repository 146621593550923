import { notification } from 'antd';
import { isEmpty, merge } from 'lodash';
import { ReactNode } from 'react';
import qs from 'qs';

interface RequestConfigProps {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
  headers?: { [key: string]: any };
  credentials?: 'include' | 'omit' | 'same-origin';
}

interface NotificationProps {
  description: string;
  message?: ReactNode;
}

interface RequestProps {
  url: string;
  config: RequestConfigProps;
  messageTitle?: string;
  params?: { [key: string]: any };
}

const openNotification = (props: NotificationProps) => {
  const { message, description } = props;
  if (message) {
    notification.error({
      message: `${message}失败`,
      duration: 10,
      description,
      style: {
        maxHeight: 300,
        overflow: 'scroll'
      }
    });
  }
};

function queryParams(params: { [key: string]: any }) {
  const rest: string[] = [];
  Object.keys(params).forEach(
    (k) =>
      params[k] &&
      rest.push(`${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
  );
  return rest.join('&');
}

const request = async (props: RequestProps) => {
  const { url, config, messageTitle, params } = props;

  let newUrl = `/api${url}`;
  if (params && !isEmpty(params)) {
    newUrl += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(params);
  }

  const options = merge(
    {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      credentials: 'same-origin'
    },
    config
  );
  const response = await fetch(newUrl, options);

  try {
    const result = await response.json();
    if (result.code < 200 || result.code >= 400) {
      if (result.code === 401 || result.code === 403) {
        let redirectUrl;
        if (['/', '/login'].indexOf(window.location.pathname) < 0) {
          redirectUrl =
            window.location.pathname +
            window.location.search +
            window.location.hash;
        }
        const search = qs.stringify(
          { redirectUrl },
          { addQueryPrefix: true, skipNulls: true }
        );
        window.location.href = `/login${search}`;
      }
      throw result?.message;
    }
    return result;
  } catch (err) {
    openNotification({
      message: messageTitle,
      description: String(err) || response?.statusText
    });
    throw err || new Error(response?.statusText || 'error');
  }
};

export default request;
