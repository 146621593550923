/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseOutlined } from '@ant-design/icons';
import { FC } from 'react';
import styled from 'styled-components';
import { PropertiesType } from './types';
import Properties from './sidebar/Properties';

const Dialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overscroll-behavior: none;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;

  > .modal {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);

    > .modal-header {
      padding: 0 12px;
      height: 60px;
      background-color: #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      > .modal-title {
        flex: auto;
      }

      > .modal-close {
        flex: none;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
      }
    }

    > .modal-body {
      padding: 12px;
      background-color: #fff;
      overflow: auto;
      max-height: calc(80vh - 300px);
    }
  }
`;

type ModelPropertiesDialogProps = {
  data?: PropertiesType | null;
  onClose?: () => unknown;
};

const ModelPropertiesDialog: FC<ModelPropertiesDialogProps> = ({
  data,
  onClose
}) => {
  if (!data) {
    return null;
  }

  return (
    <Dialog>
      <div className="modal">
        <div className="modal-header">
          <span className="modal-title">模型属性</span>
          <div className="modal-close" onClick={() => onClose?.()}>
            <CloseOutlined />
          </div>
        </div>
        <div className="modal-body">
          <Properties {...data} expand />
        </div>
      </div>
    </Dialog>
  );
};

export default ModelPropertiesDialog;
