import { FC, ChangeEvent, DragEvent } from 'react';
import cs from 'classnames';
import { Wrapper, Input, UploadWrapper, Tip, Desc, Icon } from './styles';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFileDrop: (e: DragEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

const Dragger: FC<Props> = ({ onChange, disabled, onFileDrop }) => {
  return (
    <Wrapper>
      <div onDrop={onFileDrop} onDragOver={onFileDrop}>
        <Input disabled={disabled} multiple onChange={onChange} />
        <UploadWrapper className={cs({ disabled })}>
          <Icon className="icon" />
          <Desc>点击或者拖动文件到虚线框内上传</Desc>
          <Tip>最多上传100个文件；文件总大小不超过100M；不能上传zip文件</Tip>
        </UploadWrapper>
      </div>
    </Wrapper>
  );
};

export default Dragger;
