import { Layout, Menu as MenuBase } from 'antd';
import styled from '@/theme';
import riverLogo from '@/assets/images/logos/logo_red.png';

export const Wrapper = styled.div`
  position: sticky;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #fff;
  box-shadow: ${(props) => props.theme.shadow.sidebar};
  z-index: 100;
`;

export const Sider = styled(Layout.Sider).attrs({ theme: 'light' })`
  height: 100vh;
  .collapsed {
    transform: rotate(180deg);
  }
`;

export const Menu = styled(MenuBase).attrs({
  mode: 'inline'
})`
  border-right: 0;
`;

export const MenuItem = styled(MenuBase.Item)``;

export const Logo = styled.div`
  background-image: url(${riverLogo});
  height: 70px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
`;

export const TriggerBtn = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.border.color};
`;
