import { CardProps } from 'antd';
import { CSSProperties, ReactNode } from 'react';
import Properties, { PropertyType } from '../properties/Properties';
import { CardWrapper } from './styles';

interface Props extends CardProps {
  properties: PropertyType[];
  style?: CSSProperties;
  propertyWidth?: string;
  children?: ReactNode;
}

const DescCard = (props: Props) => {
  const { properties, propertyWidth, children, ...rest } = props;
  return (
    <CardWrapper hoverable {...rest}>
      <div className="cardBody">
        <Properties properties={properties} propertyWidth={propertyWidth} />
        {children}
      </div>
    </CardWrapper>
  );
};

export default DescCard;
