import useDebounce from './useDebounce';

const isEmptyValue = (value: unknown): boolean =>
  (Array.isArray(value) && !value.length) ||
  (typeof value === 'string' && value === '');

const useSearchValue = <T>(value: T, delay = 275): T => {
  const debounced = useDebounce(value, delay);
  if (isEmptyValue(value)) {
    return value;
  }
  if (isEmptyValue(debounced)) {
    return value;
  }
  return debounced;
};

export default useSearchValue;
