import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Divider, Skeleton } from 'antd';
import request from '@/apps/utils';

interface FileContentProps {
  label: string;
  path: string;
  datasetId: string;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  content: string[];
  setContent: Dispatch<SetStateAction<string[]>>;
  fileContentVisible: boolean;
  updateHasMore: Dispatch<SetStateAction<boolean>>;
  hasMore: boolean;
}

interface FileDataType {
  page: number;
  pageSize: number;
  lines: string[];
  totalPage: number;
}

const FileContent: FC<FileContentProps> = ({
  label,
  path,
  datasetId,
  page,
  setPage,
  content,
  setContent,
  fileContentVisible,
  updateHasMore,
  hasMore
}) => {
  const [fileData, setFileData] = useState<FileDataType>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const handleRequest = () => {
    if (fileContentVisible) {
      request({
        url: `/dataset/${datasetId}/browse-file`,
        config: {
          method: 'GET'
        },
        params: {
          label,
          path,
          pageSize: 100,
          page
        }
      })
        .then(({ data }) => {
          setFileData(data);
          setTotalPage(data.totalPage);
          setContent((pre) => [...pre, ...data.lines]);
          setPage((pre) => pre + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const loadMore = () => {
    if (!fileData) {
      return;
    }
    if (page > fileData.totalPage) {
      updateHasMore(false);
      return;
    }
    handleRequest();
  };

  useEffect(() => {
    if (page > totalPage) {
      updateHasMore(false);
    } else {
      updateHasMore(true);
    }
  }, [page]);

  useEffect(() => {
    if (fileContentVisible) {
      handleRequest();
    }
  }, [path, fileContentVisible]);

  return (
    <div id="scrollableContent" style={{ height: '100%', overflow: 'scroll' }}>
      <InfiniteScroll
        dataLength={content.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<Skeleton active />}
        endMessage={<Divider plain>已经全部加载完毕</Divider>}
        scrollableTarget="scrollableContent"
      >
        {content.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>
              {item}
              <br />
            </span>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default FileContent;
