import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import { Loading } from '@/components';
import request from '../utils';
import { useAuth } from './hooks';

interface EnsureLoggedProps {
  children: React.ReactNode;
}

const EnsureLogged: React.FC<EnsureLoggedProps> = ({ children }) => {
  const { currentUser, setUser } = useAuth();
  const [logged, updateLogged] = useState<number>(0);

  useEffect(() => {
    if (!currentUser) {
      request({ url: '/auth/userInfo', config: { method: 'GET' } })
        .then(async ({ data }) => {
          setUser(data);
          updateLogged(1);
        })
        .catch((err) => {
          updateLogged(-1);
          console.error(err);
        });
    } else {
      updateLogged(1);
    }
  }, []);

  if (logged === 0) {
    return <Loading />;
  }
  if (logged === -1) {
    let redirectUrl;
    if (['/', '/login'].indexOf(window.location.pathname) < 0) {
      redirectUrl =
        window.location.pathname +
        window.location.search +
        window.location.hash;
    }
    const search = qs.stringify(
      { redirectUrl },
      { addQueryPrefix: true, skipNulls: true }
    );
    return <Redirect to={`/login${search}`} />;
  }
  return <>{children}</>;
};

export default EnsureLogged;
