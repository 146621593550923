import { FC } from 'react';
import styled from '@/theme';

const Wrapper = styled.div`
  & + & {
    margin-top: 12px;
  }
`;

const Label = styled.div`
  margin-bottom: 12px;
`;

type FieldProps = {
  label?: string;
  className?: string;
};

const Field: FC<FieldProps> = ({ label, children, className }) => (
  <Wrapper className={className}>
    {label && <Label>{label}</Label>}
    {children}
  </Wrapper>
);

export default Field;
