import { useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PageWrapper } from '@/components';
import Switch from '@/components/routers';
import request from '@/apps/utils';
import { TrainType } from '../types';
import Menu from './Menu';
import routes from './routes';
import Desc from './Desc';
import { Wrapper } from './styles';

const Detail = () => {
  const { params, path } = useRouteMatch<{ trainId: string }>();
  const [train, setTrain] = useState<TrainType>();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    request({
      url: `/train/${params.trainId}`,
      config: { method: 'GET' },
      messageTitle: '模型ID请求',
      params: { verbose: true }
    })
      .then(({ data }) => {
        setTrain(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Wrapper>
      <PageWrapper
        childrenCardProps={{ loading }}
        headerProps={{
          title: train?.title,
          footer: Menu(train?.modelId),
          children: Desc(train)
        }}
      >
        <Switch
          routes={routes}
          basePath={path}
          routeProp={{ modelId: train?.modelId, datasetId: train?.datasetId }}
        />
      </PageWrapper>
    </Wrapper>
  );
};

export default Detail;
