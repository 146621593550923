import { Modal } from 'antd';
import { FC, useState } from 'react';
import request from '@/apps/utils';
import { Btn } from './styles';
import { Copy } from '@/components';
import { UserType } from '@/apps/authentication/hooks/types';
import { useAuth } from '@/apps/authentication/hooks';

interface ResetPasswordProps {
  account: UserType;
}

const ResetPassword: FC<ResetPasswordProps> = ({
  account: { accountId, email, displayName }
}) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const { currentUser } = useAuth();

  const onClick = () => {
    setConfirmVisible(true);
  };

  const handleOk = () => {
    setConfirmVisible(false);
    request({
      url: `/account/${accountId}/reset-password`,
      config: { method: 'PUT' },
      messageTitle: '重置密码'
    })
      .then(({ data }) => {
        Modal.success({
          title: '重置密码成功',
          content: (
            <>
              账号{data.email}（{data.displayName}）的密码为
              <Copy content={data.initialPassword} />
              ，请妥善保存，通知该用户及时登录并修改密码。
            </>
          )
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancel = () => {
    setConfirmVisible(false);
  };

  return (
    <>
      <Btn
        type="text"
        onClick={onClick}
        disabled={accountId === currentUser?.accountId}
      >
        重置密码
      </Btn>
      <Modal
        title="重置密码"
        visible={confirmVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
      >
        <p>{`将重置账号${email}（${displayName}）的密码，是否继续？`}</p>
      </Modal>
    </>
  );
};

export default ResetPassword;
