import { SearchOutlined } from '@ant-design/icons';
import styled from '@/theme';

export const Documentation = styled.div`
  overflow: hidden;
  word-break: break-word;

  h1 {
    font-size: 16px;
    margin: 12px 0;
  }

  h2 {
    font-size: 14px;
    margin: 12px 0;
  }

  h3 {
    font-size: 14px;
    margin: 12px 0;
  }

  p {
    line-height: 1.5;
    margin: 12px 0;
  }

  dl {
    line-height: 1.5;
    margin: 12px 0;

    > dt {
      font-weight: 700;
    }

    > dd {
      margin-left: 12px;
    }
  }

  pre {
    background-color: rgba(216, 216, 216, 0.5);
    color: #666;
    padding: 12px;
    border-radius: 4px;
    overflow: auto;

    code {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
    }
  }

  code {
    background-color: rgba(216, 216, 216, 0.5);
    color: #666;
    padding: 3px 6px;
    border-radius: 4px;
  }
`;

export const Sidebar = styled.div`
  height: 100%;
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 12px;
  height: calc(100% - 20px);
  overflow: auto;
`;

export const Title = styled.div`
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin: 0 12px;

  > .close {
    flex: none;
    color: #666;
    cursor: pointer;
  }
`;

export const SearchIcon = styled(SearchOutlined)`
  color: #d9d9d9;
`;
