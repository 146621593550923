import { Layout } from 'antd';
import styled from '@/theme';

export const classes = {
  action: 'header-action'
};

export const HeaderWrapper = styled(Layout.Header)`
  box-shadow: ${(props) => props.theme.shadow.header};
  padding: 0 ${(props) => 4 * props.theme.spacing}px;
  background-color: ${(props) => props.theme.palette.header.backgroundColor};
`;
export const RightContent = styled.div`
  display: flex;
  float: right;
  margin-left: auto;
  height: 100%;
  cursor: pointer;
  overflow: hidden;

  .${classes.action} {
    display: flex;
    transition: all 0.3s;
    cursor: pointer;
    align-items: center;
    padding: 0 ${(props) => 4 * props.theme.spacing}px;
    &:hover {
      border-radius: ${(props) => props.theme.palette.border.radius};
      background: ${(props) => props.theme.palette.hoverBg};
    }
  }
`;
