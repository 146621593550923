import { useState } from 'react';
import request from '@/apps/utils';
import { UserType } from './types';

function useProvideAuth() {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const login = (
    data: { username: string; password: string },
    cb: () => void
  ) => {
    request({
      url: '/auth/login',
      config: {
        method: 'POST',
        body: JSON.stringify(data)
      }
    })
      .then(async (res) => {
        setCurrentUser(res.data);
        cb();
      })
      .catch((err) => {
        setError(String(err));
      });
  };

  const logout = (cb: () => void) => {
    request({ url: '/auth/logout', config: { method: 'GET' } })
      .then(async () => {
        setCurrentUser(null);
        cb();
      })
      .catch((err) => {
        setError(String(err));
      });
  };

  const setUser = (user: UserType) => {
    setCurrentUser(user);
  };

  return {
    currentUser,
    login,
    logout,
    error,
    setUser
  };
}

export default useProvideAuth;
