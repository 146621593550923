import { RouteType } from '@/components/routers/types';
import DatasetDetail from './datasetDetail';
import DatasetList from './datasetList';
import DatasetSetting from './datasetSetting';

const trainRoutes: RouteType[] = [
  {
    key: 'list',
    path: '/list',
    component: DatasetList
  },
  {
    key: 'create',
    path: '/create',
    component: DatasetSetting
  },
  {
    key: 'detail',
    path: '/:datasetId',
    component: DatasetDetail
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/list'
  }
];

export default trainRoutes;
