import { FC, useState } from 'react';
import {
  DEFAULT_PAGE_SIZE,
  RequestParams,
  UploadResultType
} from '../../types';
import PredictedResult from './PredictedResult';
import request from '@/apps/utils';
import FileUpload from './upload';

interface Props {
  modelId: string;
}

const Predict: FC<Props> = ({ modelId }) => {
  const [serveId, setServeId] = useState('');
  const [total, setTotal] = useState(0);
  const [uploadResult, setUploadResult] = useState<UploadResultType>();
  const [requestParams, setRequestParams] = useState<RequestParams>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1
  });

  const getResultData = (id: string, reqParams: RequestParams) => {
    const rParams = { ...reqParams } as any;
    let label = '';
    if (reqParams.label) {
      reqParams.label.forEach((item, index) => {
        label = `${label}${index === 0 ? '' : ','}"${item}"`;
      });
    }
    if (label) {
      rParams.label = encodeURIComponent(`[${label}]`);
    }

    request({
      url: `/models/${id}/serve-result`,
      config: { method: 'GET' },
      messageTitle: '获取预测结果',
      params: rParams
    })
      .then(({ data }) => {
        setUploadResult(data);
        setTotal(data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <FileUpload
        modelId={modelId}
        requestParams={requestParams}
        setServeId={setServeId}
        getResultData={getResultData}
      />
      {uploadResult && (
        <PredictedResult
          serveId={serveId}
          total={total}
          uploadResult={uploadResult}
          setRequestParams={setRequestParams}
          requestParams={requestParams}
          getResultData={getResultData}
        />
      )}
    </>
  );
};
export default Predict;
