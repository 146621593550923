import { FC } from 'react';
import { graphic } from 'echarts';
import { BasicLineChart } from '@/components/charts';
import { PrcCurveType, RocCurveType } from '../types';
import { toFixed } from '@/apps/utils/utils';

interface ROCandPRCProps {
  prcCurve: PrcCurveType[];
  rocCurve: RocCurveType[];
}

const ROCandPRC: FC<ROCandPRCProps> = ({ prcCurve = [], rocCurve = [] }) => {
  const prcCurveSeries = prcCurve.map((item) => {
    return {
      name: item.label,
      data: item.recall.map((i, index) => [
        toFixed(i, 4),
        toFixed(item.precision[index], 4)
      ]),
      symbol: 'rect',
      areaStyle:
        prcCurve.length === 1
          ? {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(77, 101, 211, 0.8)'
                },
                {
                  offset: 1,
                  color: 'rgba(77, 101, 211, 0.1)'
                }
              ])
            }
          : undefined
    };
  });
  const rocCurveSeries = rocCurve.map((item) => {
    return {
      name: item.label,
      data: item.fpr.map((i, index) => [
        toFixed(i, 4),
        toFixed(item.tpr[index], 4)
      ]),
      symbol: 'rect',
      areaStyle:
        rocCurve.length === 1
          ? {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(77, 101, 211, 0.8)'
                },
                {
                  offset: 1,
                  color: 'rgba(77, 101, 211, 0.1)'
                }
              ])
            }
          : undefined
    };
  });
  const prcCurveOption = {
    title: {
      text: 'PRC',
      textStyle: {
        color: '#38445E',
        fontSize: '14px'
      },
      top: 0
    },
    tooltip: {
      trigger: 'axis',
      formatter: (
        params: { marker: any; value: any[]; seriesName: string }[]
      ) => {
        let tooltip = `Recall: ${params[0].value[0]}</br>`;
        params.forEach((item) => {
          tooltip += `${item.marker} 标签${item.seriesName} Precision: ${item.value[1]}</br>`;
        });
        return tooltip;
      }
    },
    xAxis: {
      type: 'value',
      name: 'Recall',
      nameLocation: 'center',
      nameTextStyle: {
        color: 'rgb(125 130 141)'
      },
      nameGap: 30
    },
    yAxis: {
      type: 'value',
      name: 'Precision',
      nameLocation: 'center',
      nameTextStyle: {
        color: 'rgb(125 130 141)'
      },
      nameGap: 30
    },
    series: prcCurveSeries
  };
  const rocCurveOption = {
    title: {
      text: 'ROC',
      textStyle: {
        color: '#38445E',
        fontSize: '14px'
      },
      top: 0
    },
    tooltip: {
      trigger: 'axis',
      formatter: (
        params: { marker: any; value: any[]; seriesName: string }[]
      ) => {
        let tooltip = `False Positive Rate: ${params[0].value[0]}</br>`;
        params.forEach((item) => {
          tooltip += `${item.marker} 标签${item.seriesName} True Positive Rate: ${item.value[1]}</br>`;
        });
        return tooltip;
      }
    },
    xAxis: {
      type: 'value',
      name: 'False Positive Rate',
      nameLocation: 'center',
      nameTextStyle: {
        color: 'rgb(125 130 141)'
      },
      nameGap: 30
    },
    yAxis: {
      type: 'value',
      name: 'True Positive Rate',
      nameLocation: 'center',
      nameTextStyle: {
        color: 'rgb(125 130 141)'
      },
      nameGap: 30
    },
    series: rocCurveSeries
  };
  return (
    <div style={{ display: 'flex', height: '410px' }}>
      <BasicLineChart
        option={prcCurveOption}
        style={{ width: '50%', height: '100%' }}
      />
      <BasicLineChart
        option={rocCurveOption}
        style={{ width: '50%', height: '100%' }}
      />
    </div>
  );
};

export default ROCandPRC;
