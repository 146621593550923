import { Dropdown as DropdownBase } from 'antd';
import { DropDownProps } from 'antd/es/dropdown';
import classNames from 'classnames';
import React from 'react';

type OverlayFunc = () => React.ReactNode;

export interface DropdownProps extends Omit<DropDownProps, 'overlay'> {
  overlayClassName?: string;
  overlay: React.ReactNode | OverlayFunc | any;
  placement?:
    | 'bottomLeft'
    | 'bottomRight'
    | 'bottomCenter'
    | 'topLeft'
    | 'topCenter'
    | 'topRight';
}

const DropDown: React.FC<DropdownProps> = ({
  overlayClassName: cls,
  ...restProps
}) => <DropdownBase overlayClassName={classNames(cls)} {...restProps} />;

export default DropDown;
