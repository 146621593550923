import { Menu as MenuBase, Avatar as AvatarBase, Spin as SpinBase } from 'antd';
import styled from '@/theme';

export const AvatarWrapper = styled.div``;

export const Avatar = styled(AvatarBase)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: ${(props) => 2 * props.theme.spacing}px;
`;

export const Menu = styled(MenuBase)``;

export const MenuItem = styled(MenuBase.Item)`
  min-width: 100px;
  .menu-title {
    margin-left: ${(props) => 2 * props.theme.spacing}px;
  }
`;

export const Spin = styled(SpinBase)`
  margin: 0 ${(props) => 2 * props.theme.spacing}px;
`;
