/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import { FC, useCallback, Fragment } from 'react';
import { DocumentationType } from '@/apps/dashboard/model/graph/types';
import GraphSidebar from './GraphSidebar';
import { Documentation } from './styles';

type NodeDocumentationSidebarProps = {
  data?: DocumentationType | null;
  onClose?: () => unknown;
};

const NodeDocumentationSidebar: FC<NodeDocumentationSidebarProps> = ({
  data,
  onClose
}) => {
  const list = useCallback(
    (
      items: { name: string; type?: string | string[]; description: string }[]
    ) => {
      items.map((item, index) => {
        return (
          <dl key={index}>
            <dt>
              {item.name}
              {item.type && (
                <>
                  :{' '}
                  {typeof item.type === 'string' ? (
                    <code>{item.type}</code>
                  ) : (
                    item.type.map((i, j) => (
                      <Fragment key={j}>
                        {j ? ',' : null}
                        <code>{i}</code>
                      </Fragment>
                    ))
                  )}
                </>
              )}
            </dt>
            <dd dangerouslySetInnerHTML={{ __html: item.description }} />
          </dl>
        );
      });
    },
    []
  );

  return (
    <GraphSidebar title="documentation" onClose={onClose}>
      <Documentation>
        <h1 className="h1">{data?.name}</h1>
        {data?.summary && (
          <p dangerouslySetInnerHTML={{ __html: data.summary }} />
        )}
        {data?.description && (
          <p dangerouslySetInnerHTML={{ __html: data.description }} />
        )}
        {data?.attributes && (
          <>
            <h2>attributes</h2>
            {list(data.attributes)}
          </>
        )}
        {data?.inputs && (
          <>
            <h2>
              inputs
              {data?.inputs_range &&
                ` (${data.inputs_range.replace(/&#8734;/g, '∞')})`}
            </h2>
            {list(data.inputs)}
          </>
        )}
        {data?.outputs && (
          <>
            <h2>
              outputs
              {data?.outputs_range &&
                ` (${data.outputs_range.replace(/&#8734;/g, '∞')})`}
            </h2>
            {list(data.outputs)}
          </>
        )}
        {data?.type_constraints && (
          <>
            <h2>type-constraints</h2>
            {list(
              data.type_constraints.map(
                ({ type_param_str, allowed_type_strs, description }) => ({
                  name: type_param_str,
                  type: allowed_type_strs,
                  description
                })
              )
            )}
          </>
        )}
        {data?.examples && (
          <>
            <h2>examples</h2>
            {data.examples.map((example, index) => (
              <Fragment key={index}>
                <h3>{example.summary}</h3>
                <pre>{example.code}</pre>
              </Fragment>
            ))}
          </>
        )}
        {data?.references && (
          <>
            <h2>references</h2>
            <ul>
              {data.references.map((reference, index) => (
                <li
                  key={index}
                  dangerouslySetInnerHTML={{ __html: reference.description }}
                />
              ))}
            </ul>
          </>
        )}
        {data && data.domain && data.since_version && data.support_level && (
          <>
            <h2>support</h2>
            <dl>
              In domain <code>{{ domain: data.domain }}</code> since version{' '}
              <code>{{ since_version: data.since_version }}</code> at support
              level <code>{{ support_level: data.support_level }}</code>.
            </dl>
          </>
        )}
      </Documentation>
    </GraphSidebar>
  );
};

export default NodeDocumentationSidebar;
