import { isEmpty } from 'lodash';
import { Rule } from 'antd/lib/form';
import { ModelParamItemRule } from '@/apps/dashboard/model/modelCreate/types';

export const getRules = (rules?: ModelParamItemRule | Rule[]) => {
  if (!rules || isEmpty(rules)) {
    return undefined;
  }
  if (Array.isArray(rules)) {
    return rules;
  }
  const temp: Rule = {};
  const message = rules.message || 'error';
  temp.required = rules.required;

  temp.validator = (_: any, value: string) => {
    if (rules.required && !value) {
      return Promise.reject(message);
    }
    if (rules.max && !value?.split(',').every((i) => Number(i) <= rules.max!)) {
      return Promise.reject(message);
    }
    if (rules.min && !value?.split(',').every((i) => Number(i) >= rules.min!)) {
      return Promise.reject(message);
    }
    if (rules.pattern) {
      const pattern = new RegExp(rules.pattern);
      if (!pattern.test(value)) {
        return Promise.reject(message);
      }
    }
    return Promise.resolve();
  };
  return [temp];
};
