import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import mainRoutes from 'apps/dashboard/routes';
import { Spin } from 'antd';
import { Wrapper, Sider, Menu, Logo, TriggerBtn } from './styles';
import { useAuth } from '../authentication/hooks';
import { RouteType } from '@/components/routers/types';

const Sidebar = () => {
  const { currentUser } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const onCollapse = () => {
    setCollapsed((pre) => !pre);
  };
  const pathnameArr = history.location.pathname?.split('/').filter((i) => i);
  const temp = pathnameArr.filter((i) => i.startsWith('manage'));
  const currentPath = (temp && temp[0]) || mainRoutes[0].path;

  if (!currentUser) {
    return <Spin size="small" />;
  }

  const checkPermission = (userPermissions: string[], key: string) => {
    return userPermissions.includes(key);
  };

  const getMenuItems = (routes: RouteType[], prevPath: string = '') => {
    return routes.map((route) => {
      if (
        'redirect' in route ||
        route.hidden ||
        !checkPermission(currentUser.permissions, route.key)
      ) {
        return null;
      }
      const item: any = {
        key: route.path,
        icon: route.icon,
        label: route.children ? (
          route.sidebarName
        ) : (
          <Link to={prevPath + route.path}>{route.sidebarName}</Link>
        )
      };
      if (route.children) {
        prevPath += route.path;
        item.children = getMenuItems(route.children, prevPath);
      }
      return item;
    });
  };
  const items = getMenuItems(mainRoutes);

  return (
    <Wrapper>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        trigger={
          <TriggerBtn>
            <LeftOutlined className={classnames({ collapsed })} />
          </TriggerBtn>
        }
      >
        <Logo />
        <Menu
          defaultOpenKeys={[`/${pathnameArr[0]}`]}
          selectedKeys={[`/${currentPath}`]}
          items={items}
        />
      </Sider>
    </Wrapper>
  );
};

export default Sidebar;
