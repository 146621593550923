import { useEffect, useState } from 'react';
import request from '@/apps/utils';

const TrialLogs = (props: {
  trialId: string;
  trainId: string;
  logVisible: boolean;
}) => {
  const { trialId, trainId, logVisible } = props;
  const [logs, setLogs] = useState<string[]>([]);

  useEffect(() => {
    if (logVisible) {
      request({
        url: `/train/${trainId}/log?trialId=${trialId}`,
        config: { method: 'GET' }
      })
        .then(({ data }) => {
          setLogs(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [trialId, logVisible]);
  let str = '';
  logs.forEach((item) => {
    str += item;
  });
  return (
    <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{str}</div>
  );
};

export default TrialLogs;
