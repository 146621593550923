import { Empty, FormInstance } from 'antd';
import { FC } from 'react';
import { useAuth } from '@/apps/authentication/hooks';
import { Descriptions } from '@/components';
import { Wrapper } from './styles';
import ChangePassword from './ChangePassword';
import { RoleMap } from '@/apps/authentication/hooks/types';

interface MyAccountProps {
  onCancel: () => void;
  form: FormInstance<any>;
}

const MyAccount: FC<MyAccountProps> = ({ onCancel, form }) => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Empty description="暂无数据" />;
  }
  const properties = [
    { key: 'id', label: 'id', value: currentUser.accountId },
    { key: 'email', label: '邮箱', value: currentUser.email },
    { key: 'displayName', label: '姓名', value: currentUser.displayName },
    { key: 'role', label: '角色', value: RoleMap[currentUser.role][0] },
    { key: 'permissions', label: '权限', value: RoleMap[currentUser.role][1] }
  ];

  return (
    <Wrapper>
      <Descriptions column={1} properties={properties} />
      <ChangePassword form={form} onCancel={onCancel} />
    </Wrapper>
  );
};

export default MyAccount;
