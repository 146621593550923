/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PropertiesType } from '@/apps/dashboard/model/graph/types';
import Property from './Property';

const Header = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 12px 0;
`;

type PropertiesProps = PropertiesType & {
  expand?: boolean;
  showNodeDocumentation?: () => unknown;
};

const Properties: FunctionComponent<PropertiesProps> = ({
  properties,
  groups,
  expand,
  showNodeDocumentation
}) => {
  return (
    <>
      {properties?.map((property, index) => (
        <Property
          name={property.name}
          values={property.values}
          key={index}
          showNodeDocumentation={showNodeDocumentation}
        />
      ))}
      {groups?.map((group, index) => (
        <React.Fragment key={index}>
          <Header>{group.name}</Header>
          {group.properties?.map((property, anotherIndex) => (
            <Property
              {...property}
              key={anotherIndex}
              expand={expand}
              showNodeDocumentation={showNodeDocumentation}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default Properties;
