import { Card, Progress as ProgressBase } from 'antd';
import styled from '@/theme';

export const FormWrapper = styled(Card)`
  background-color: #f5f6f9;
`;

export const UploadItemWrapper = styled.div`
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-top: 8px;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
  .icon {
    font-size: 26px;
    color: #1890ff;
  }
`;

export const UploadSpan = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemName = styled.span`
  flex: auto;
  padding: 0 8px;
  margin: 0 0 0 8px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
  width: 20%;
`;

export const Progress = styled(ProgressBase)`
  .ant-progress-inner {
    background-color: #eaecf2;
  }
  .ant-progress-outer {
    margin-right: calc(-3em - 8px);
    padding-right: calc(3em + 8px);
  }
`;

export const Retry = styled.div`
  .icon {
    font-size: 26px;
    color: #ff4d4f;
  }
`;

export const LinkBtn = styled.span`
  color: #faad14;
  cursor: pointer;
`;

export const UploadWrapper = styled.div`
  .ant-form-item-control-input-content {
    .left {
      display: inline-block;
      margin-bottom: 0;
      width: 200px;
    }
    .multiLabelLeft {
      width: 350px;
    }
    .center {
      display: inline-block;
      margin: 0 2% 0 2%;
      width: calc(96% - 200px - 24px);
    }
    .multiLabelCenter {
      width: calc(96% - 350px - 24px);
    }
    .minusIcon {
      display: inline-block;
      width: 24px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      right: 0;
      &:hover {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
`;
