import { useState, ChangeEvent, FC, useEffect, DragEvent } from 'react';
import { message } from 'antd';
import Dragger from './dragger';
import { RcFile } from './types';
import getUid from './uid';
import FileList from './fileList';
import { MAX_FILE_SIZE, RequestParams } from '../../../types';
import { generateZipFile } from '@/apps/utils/utils';
import { openNotification } from '../../utils';
import { Wrapper, UploadWrapper, Btn, SubmitWrapper } from './styles';

interface UploadProps {
  modelId: string;
  requestParams: RequestParams;
  setServeId: (id: string) => void;
  getResultData: (id: string, reqParams: RequestParams) => void;
}

const Upload: FC<UploadProps> = ({
  modelId,
  setServeId,
  getResultData,
  requestParams
}) => {
  const [uploadList, setUploadList] = useState<RcFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setError('');
  }, [uploadList.length]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const temp: RcFile[] = [];
      for (let i = 0; i < 100 - uploadList.length && i < files.length; i += 1) {
        const file = files[i] as RcFile;
        file.uid = getUid();
        temp.push(file);
      }
      setUploadList([...uploadList, ...temp]);
    }
  };

  const onFileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.type === 'dragover') {
      return;
    }
    const { files } = e.dataTransfer;
    const temp: RcFile[] = [];
    for (let i = 0; i < 100 - uploadList.length && i < files.length; i += 1) {
      const file = files[i] as RcFile;
      file.uid = getUid();
      temp.push(file);
    }
    setUploadList([...uploadList, ...temp]);
  };

  const onSubmit = async () => {
    setUploading(true);
    const size = uploadList.reduce((pre, curr) => pre + curr.size, 0);
    if (size >= MAX_FILE_SIZE) {
      setUploading(false);
      setError('文件总大小不能超过100M');
      return;
    }
    for (let i = 0; i < uploadList.length; i += 1) {
      if (uploadList[i].type === 'application/zip') {
        setUploading(false);
        setError('不能上传zip文件');
        return;
      }
    }

    if (uploadList.length > 0) {
      const zipFile = await generateZipFile(uploadList, 'serve');
      const formData = new FormData();
      formData.append('file', zipFile);
      fetch(`/api/models/${modelId}/serve`, {
        method: 'POST',
        body: formData
      })
        .then(async (res) => {
          const result = await res.json();
          if (res.ok) {
            setServeId(result.data.serveid);
            getResultData(result.data.serveid, {
              pageSize: requestParams.pageSize,
              page: 1
            });

            message.success('上传成功');
          } else {
            openNotification('上传失败', result?.message);
          }
        })
        .catch((err) => {
          openNotification('上传失败', String(err));
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  return (
    <Wrapper>
      <UploadWrapper>
        {uploadList && uploadList.length > 0 && (
          <FileList uploadList={uploadList} setUploadList={setUploadList} />
        )}
        <Dragger
          disabled={uploading || uploadList.length >= 100}
          onChange={handleChange}
          onFileDrop={onFileDrop}
        />
      </UploadWrapper>
      <SubmitWrapper style={{ display: 'flex' }}>
        <Btn
          type="primary"
          onClick={onSubmit}
          disabled={uploading || !uploadList || uploadList.length <= 0}
        >
          {uploading ? '上传中... ' : '上传测试集'}
        </Btn>
        {error && <div className="error">{error}</div>}
      </SubmitWrapper>
    </Wrapper>
  );
};
export default Upload;
