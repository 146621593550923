import { Form, FormInstance, Input, Typography, InputNumber } from 'antd';
import { FC, useEffect, useState } from 'react';
import request from '@/apps/utils';
import { AlgorithmType } from './types';
import { Title } from '@/components';
import { UniformInput } from './styles';
import { handleSearchSpace } from './utils';

interface SearchSpaceProps {
  form: FormInstance;
}

const SearchSpace: FC<SearchSpaceProps> = ({ form }) => {
  const [algorithm, setAlgorithm] = useState<AlgorithmType>();
  useEffect(() => {
    if (!form.getFieldValue('algorithmId')) {
      return;
    }
    request({
      url: `/algorithms/${form.getFieldValue('algorithmId')}`,
      config: {
        method: 'GET'
      }
    })
      .then(({ data }) => {
        setAlgorithm(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [form.getFieldValue('algorithmId')]);

  useEffect(() => {
    const searchSpace = handleSearchSpace(algorithm?.searchSpace);

    form.setFieldsValue({ searchSpace });
  }, [algorithm]);

  if (!algorithm || !form.getFieldValue('algorithmId')) {
    return <></>;
  }

  return (
    <Title title="探索范围">
      {algorithm.searchSpace?.map((item) => {
        return item.type === 'choice' ? (
          <Form.Item
            {...item}
            key={item.key}
            name={['searchSpace', item.key]}
            label={
              <Typography.Text type="secondary">
                {item.description}
              </Typography.Text>
            }
          >
            <Input placeholder={item.placeholder || '输入枚举值，以“,”分隔'} />
          </Form.Item>
        ) : (
          <Form.Item
            {...item}
            key={item.key}
            label={
              <Typography.Text type="secondary">
                {item.description}
              </Typography.Text>
            }
          >
            <UniformInput>
              <Form.Item
                noStyle
                {...item}
                key="min"
                name={['searchSpace', item.key, 'min']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  step={0.1}
                  placeholder={item.placeholder || '输入最小值'}
                />
              </Form.Item>
              &nbsp;&nbsp;~&nbsp;&nbsp;
              <Form.Item
                noStyle
                {...item}
                key="max"
                name={['searchSpace', item.key, 'max']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  step={0.1}
                  placeholder={item.placeholder || '输入最大值'}
                />
              </Form.Item>
            </UniformInput>
          </Form.Item>
        );
      })}
    </Title>
  );
};

export default SearchSpace;
