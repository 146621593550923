import { Descriptions } from 'antd';
import { Dictionary } from '../../dataset/types';
import { TrainType } from '../types';

const Desc = (train?: TrainType) => {
  if (!train) {
    return <></>;
  }
  const { algorithm, dataset } = train;
  return (
    <Descriptions size="small" column={3}>
      {algorithm && (
        <Descriptions.Item label="算法">
          {`${algorithm.title}（${algorithm.algorithmId}）`}
        </Descriptions.Item>
      )}
      {dataset && (
        <>
          <Descriptions.Item label="任务类型">
            {Dictionary[dataset.taskType]}
          </Descriptions.Item>
          <Descriptions.Item label="数据类型">
            {Dictionary[dataset.dataType]}
          </Descriptions.Item>
        </>
      )}
    </Descriptions>
  );
};

export default Desc;
