import { CopyIcon, Content } from './styles';

function clip(ele: any) {
  if (!ele) return;
  const selection = window.getSelection();

  if (ele.nodeName === 'INPUT' || ele.nodeName === 'TEXTAREA') {
    ele.select();
    ele.setSelectionRange(0, ele.value.length);
  } else {
    selection?.removeAllRanges();
    const range = document.createRange();
    range.selectNodeContents(ele);
    selection?.addRange(range);
  }

  document.execCommand('copy');
  selection?.removeAllRanges();
}

const Copy = (props: { content: string }) => {
  const { content } = props;
  return (
    <>
      <Content id="copy_content">{content}</Content>
      <CopyIcon
        onClick={() => {
          const ele = document.getElementById('copy_content');
          clip(ele);
        }}
      />
    </>
  );
};

export default Copy;
