import {
  Form,
  Input as InputBase,
  Button as ButtonBase,
  Alert as AlertBase
} from 'antd';
import styled from '@/theme';
import bg from '@/assets/images/bg.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100vh;
  overflow: auto;
  background-color: #f0f2f5;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
`;

export const Container = styled.div`
  margin: 128px auto 0;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.div`
  .logo {
    width: 250px;
    height: 60px;
    margin-left: -12px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.palette.typography.h1.color};
  font-size: ${(props) => props.theme.palette.typography.h1.fontSize}px;
  margin: 24px auto 0;
`;

// login form
export const FormWrapper = styled.div`
  width: 328px;
  margin-top: 44px;
`;

export const InputForm = styled(Form)``;

export const InputFormItem = styled(Form.Item)``;

export const Input = styled(InputBase)``;

export const Button = styled(ButtonBase)`
  width: 100%;
`;

export const Alert = styled(AlertBase)`
  margin-bottom: 24px;
`;

export const Version = styled.div`
  font-size: 16px;
`;
