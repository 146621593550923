import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';
import moment from 'moment';
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  StopOutlined
} from '@ant-design/icons';
import { Link, PageWrapper } from '@/components';
import { PropertyType } from '@/components/properties/Properties';
import request from '@/apps/utils';
import { DatasetType, Dictionary } from '../types';
import DescCard from '@/components/descCard/DescCard';
import DeleteDataset from './DeleteDataset';
import {
  AddIcon,
  Button,
  ButtonWrapper,
  ExtraWrapper,
  Wrapper
} from './styles';
import RelatedModels from './RelatedModels';

const DatasetList = () => {
  const [datasetList, setDatasetList] = useState<DatasetType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request({
      url: '/dataset',
      config: { method: 'GET' },
      messageTitle: '数据集列表请求'
    })
      .then(({ data }) => {
        setDatasetList(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <PageWrapper
      headerProps={{
        title: '数据集列表'
      }}
      childrenCardProps={{ loading }}
    >
      <Wrapper>
        <ButtonWrapper>
          <Link to="create">
            <Button>
              <AddIcon />
              新建
            </Button>
          </Link>
        </ButtonWrapper>
        {isEmpty(datasetList) ? (
          <Empty description="暂无数据" />
        ) : (
          datasetList.map((dataset) => {
            const {
              datasetId,
              title,
              createTime,
              description,
              updateTime,
              taskType,
              status,
              dataType
            } = dataset;
            const properties: PropertyType[] = [
              {
                key: 'id',
                title: 'id',
                content: datasetId
              },
              {
                key: 'isValid',
                title: '有效',
                content:
                  status === 'DONE' ? (
                    <CheckCircleOutlined className="successIcon" />
                  ) : status === 'WAITING' ? (
                    <LoadingOutlined className="loadingIcon" />
                  ) : status === 'STOPPED' ? (
                    <StopOutlined className="errorIcon" />
                  ) : (
                    <CloseCircleOutlined className="errorIcon" />
                  )
              },
              {
                key: 'description',
                title: '描述',
                content: description || '无'
              },
              {
                key: 'dataType',
                title: '数据类型',
                content: Dictionary[dataType]
              },
              {
                key: 'taskType',
                title: '任务类型',
                content: Dictionary[taskType]
              },
              {
                key: 'createTime',
                title: '创建时间',
                content: moment(createTime).format('YYYY-MM-DD[\n]HH:mm:ss')
              },
              {
                key: 'updateTime',
                title: '更新时间',
                content: moment(updateTime).format('YYYY-MM-DD[\n]HH:mm:ss')
              },
              {
                key: 'models',
                content: (
                  <RelatedModels buttonType="primary" datasetId={datasetId} />
                )
              }
            ];
            return (
              <DescCard
                className="card"
                title={title}
                key={datasetId}
                properties={properties}
                extra={
                  <ExtraWrapper>
                    <Link to={datasetId}>查看详情</Link>/
                    <DeleteDataset
                      title={title}
                      datasetId={datasetId}
                      setDatasetList={setDatasetList}
                    />
                  </ExtraWrapper>
                }
              />
            );
          })
        )}
      </Wrapper>
    </PageWrapper>
  );
};

export default DatasetList;
