import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';
import { Link, PageWrapper } from '@/components';
import { AddIcon, Button, ButtonWrapper, Wrapper } from './styles';
import request from '@/apps/utils';
import { UserType } from '@/apps/authentication/hooks/types';
import AccountCard from '../accountCard';

const AccountList = () => {
  const [accountList, setAccountList] = useState<UserType[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    request({
      url: '/account',
      config: { method: 'GET' },
      messageTitle: '用户列表请求'
    })
      .then(({ data }) => {
        setAccountList(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <PageWrapper
      headerProps={{
        title: '用户列表'
      }}
      childrenCardProps={{ loading }}
    >
      <Wrapper>
        <ButtonWrapper>
          <Link to="create">
            <Button>
              <AddIcon />
              新建
            </Button>
          </Link>
        </ButtonWrapper>
        {isEmpty(accountList) ? (
          <Empty description="暂无数据" />
        ) : (
          accountList.map((account) => {
            return (
              <AccountCard
                key={account.accountId}
                account={account}
                accountList={accountList}
                setAccountList={setAccountList}
              />
            );
          })
        )}
      </Wrapper>
    </PageWrapper>
  );
};

export default AccountList;
