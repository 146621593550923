import { Empty, Modal, Skeleton } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { ButtonType } from 'antd/lib/button';
import request from '@/apps/utils';
import { ModelType } from '../../model/types';
import { ModelBtn } from './styles';
import ModelDetail from './ModelDetail';

type RelatedModelsProps = {
  datasetId: string;
  buttonType?: ButtonType;
};

const RelatedModels: FC<RelatedModelsProps> = ({ datasetId, buttonType }) => {
  const [relatedModelVisible, setRelatedModelVisible] = useState(false);
  const [models, setModels] = useState<ModelType[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (relatedModelVisible) {
      setLoading(true);
      request({
        url: `/dataset/${datasetId}/models`,
        config: { method: 'GET' },
        messageTitle: '数据集关联模型列表请求'
      })
        .then(({ data }) => {
          setModels(data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [relatedModelVisible, datasetId]);

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton />;
    }
    if (models && models.length > 0) {
      return <ModelDetail models={models} />;
    }
    return <Empty description="暂无数据" />;
  }, [loading, models]);

  return (
    <>
      <ModelBtn
        type={buttonType || 'link'}
        onClick={() => setRelatedModelVisible(true)}
      >
        关联模型列表
      </ModelBtn>
      <Modal
        width={800}
        title="数据集关联的模型"
        visible={relatedModelVisible}
        onCancel={() => setRelatedModelVisible(false)}
        footer={null}
      >
        {content}
      </Modal>
    </>
  );
};

export default RelatedModels;
