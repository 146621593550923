import { FC, useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import { useLocation } from 'react-router-dom';
import { uniqBy } from 'lodash';
import request from '@/apps/utils';
import { OverviewType } from '../types';

interface OverviewProps {
  datasetId: string;
}

interface ColumnType {
  title: string;
  dataIndex: string;
  key: string;
  ellipsis: boolean;
}

const titleMap = {
  label: '标签',
  path: '路径',
  count: '样本数',
  notSuffixCount: '不满足后缀的样本数',
  contentNoneCount: '内容为空的样本数',
  compileFailedCount: '编译未通过的样本数'
};

const Overview: FC<OverviewProps> = ({ datasetId }) => {
  const [overviewData, setOverviewData] = useState<OverviewType[]>([]);
  useEffect(() => {
    request({
      url: `/dataset/${datasetId}/browse-stat`,
      config: {
        method: 'GET'
      }
    })
      .then(({ data }) => {
        setOverviewData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const defaultColumns = [
    {
      title: '文件路径',
      dataIndex: 'path',
      key: 'path',
      ellipsis: true
    },
    {
      title: '标签',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      render: (text: string) => (
        <a href={`#${text}`}>
          <Tag color="#1990ff">{text}</Tag>
        </a>
      )
    }
  ];
  const location = useLocation();

  const scrollIntoView = (delay?: number) => {
    const { hash } = location;
    let timeoutId: any;
    if (hash) {
      const element = document.getElementById(hash.replace(',', '').slice(1));
      if (element) {
        const options: any = {
          block: 'center',
          inline: 'nearest'
        };
        if (delay) {
          timeoutId = setTimeout(() => {
            element.scrollIntoView(options);
          }, delay);
        } else {
          element.scrollIntoView(options);
        }
      }
    }
    return timeoutId;
  };

  useEffect(() => {
    scrollIntoView();
  }, [location]);

  useEffect(() => {
    const timeoutId = scrollIntoView(1200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const dataSource = overviewData.map((item, index) => {
    if (!item.metrics) {
      return {
        key: index,
        path: item.path,
        label: item.label
      };
    }
    const {
      total_count,
      not_suffix_count,
      content_none_count,
      compile_failed_count
    } = item.metrics;
    return {
      key: index,
      path: item.path,
      label: item.label,
      count: total_count,
      notSuffixCount: not_suffix_count,
      contentNoneCount: content_none_count,
      compileFailedCount: compile_failed_count
    };
  });

  const columns: ColumnType[] = defaultColumns;
  dataSource.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (titleMap[key] && item[key] !== undefined) {
        columns.push({
          title: titleMap[key],
          dataIndex: key,
          key,
          ellipsis: true
        });
      }
    });
  });
  return (
    <Table
      pagination={false}
      dataSource={dataSource}
      columns={uniqBy(columns, 'key')}
    />
  );
};

export default Overview;
