/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react';
import { Input, Button, Space } from 'antd';
import type {
  FilterConfirmProps,
  FilterDropdownProps
} from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';

interface filterDropdownProps extends FilterDropdownProps {
  setSearchText: (text: string) => void;
  searchInputRef: any;
}

const filterDropdown: FC<filterDropdownProps> = ({
  setSearchText,
  searchInputRef,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters
}) => {
  const handleSearch = (
    keys: string[],
    confirmFn: (param?: FilterConfirmProps) => void
  ) => {
    confirmFn();
    setSearchText(keys[0]);
  };
  const handleReset = (clearFiltersFn: () => void) => {
    clearFiltersFn();
    setSearchText('');
  };
  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInputRef}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as string[], confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          搜索
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          重置
        </Button>
      </Space>
    </div>
  );
};
export default filterDropdown;
