import { FC, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { Button, Checkbox } from 'antd';
import Content from './Content';
import {
  DocumentationType,
  NetronRef,
  PropertiesType,
  SearchItem,
  SearchResult
} from '@/apps/dashboard/model/graph/types';
import { SearchSection, FullWidthButton, ExportButtonWrapper } from './styles';
import NodeDocumentationSidebar from './sidebar/NodedocumentationSidebar';
import NodePropertiesSidebar from './sidebar/NodePropertiesSidebar';
import Search from './sidebar/Search';
import { AsideSection } from '@/apps/dashboard/model/graph/aside/styles';
import Field from './Field';
import ModelPropertiesDialog from './ModelPropertiesDialog';
import Netron from './netron';
import Aside from './aside';
import { Loading } from '@/components';

interface Props {
  modelId: string;
}

const Graph: FC<Props> = ({ modelId }) => {
  const netronRef = useRef<NetronRef>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/models/${modelId}/netronData`, {
      method: 'GET'
    })
      .then(async (res) => {
        if (!res.ok) {
          const response = await res.json();
          console.log(response.message);
          return;
        }
        const fileName = res.headers.get('file-name');
        const data = await res.blob();
        const file = new File([data], fileName || 'model');
        setFiles([file]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResult>({
    text: '',
    result: []
  });
  const onSearch = useCallback((value: string) => {
    setSearch(value);
    netronRef.current?.search(value);
  }, []);

  const onSelect = useCallback((item: SearchItem) => {
    setSearch(item.name);
    netronRef.current?.select(item);
  }, []);

  const [showAttributes, setShowAttributes] = useState(false);
  const [showInitializers, setShowInitializers] = useState(true);
  const [showNames, setShowNames] = useState(false);

  const [modelData, setModelData] = useState<PropertiesType | null>(null);
  const [nodeData, setNodeData] = useState<PropertiesType | null>(null);
  const [nodeDocumentation, setNodeDocumentation] =
    useState<DocumentationType | null>(null);
  const [rendered, setRendered] = useState(false);

  useEffect(() => setSearch(''), [showAttributes, showInitializers, showNames]);

  const aside = useMemo(() => {
    if (!rendered) {
      return null;
    }
    if (nodeDocumentation) {
      return (
        <Aside width={360}>
          <NodeDocumentationSidebar
            data={nodeDocumentation}
            onClose={() => setNodeDocumentation(null)}
          />
        </Aside>
      );
    }
    if (nodeData) {
      return (
        <Aside width={360}>
          <NodePropertiesSidebar
            data={nodeData}
            onClose={() => setNodeData(null)}
            showNodeDocumentation={() =>
              netronRef.current?.showNodeDocumentation(nodeData)
            }
          />
        </Aside>
      );
    }
    return (
      <Aside>
        <SearchSection>
          <Search
            text={search}
            data={searchResult}
            onChange={onSearch}
            onSelect={onSelect}
            onActive={() => setSearching(true)}
            onDeactive={() => setSearching(false)}
          />
        </SearchSection>
        {!searching && (
          <>
            <AsideSection>
              <FullWidthButton
                onClick={() => {
                  netronRef.current?.showModelProperties();
                }}
              >
                模型属性
              </FullWidthButton>
            </AsideSection>
            <AsideSection>
              <Field label="选择展示数据">
                <div>
                  <Checkbox
                    checked={showAttributes}
                    onChange={(e) => setShowAttributes(e.target.checked)}
                  >
                    显示参数
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={showInitializers}
                    onChange={(e) => setShowInitializers(e.target.checked)}
                  >
                    显示初始化参数
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={showNames}
                    onChange={(e) => setShowNames(e.target.checked)}
                  >
                    显示节点名称
                  </Checkbox>
                </div>
              </Field>
            </AsideSection>
            <AsideSection>
              <Field label="导出文件">
                <ExportButtonWrapper>
                  <Button onClick={() => netronRef.current?.export('png')}>
                    PNG
                  </Button>
                  <Button onClick={() => netronRef.current?.export('svg')}>
                    SVG
                  </Button>
                </ExportButtonWrapper>
              </Field>
            </AsideSection>
          </>
        )}
      </Aside>
    );
  }, [
    search,
    searching,
    searchResult,
    onSearch,
    onSelect,
    showAttributes,
    showInitializers,
    showNames,
    rendered,
    nodeData,
    nodeDocumentation
  ]);

  return (
    <>
      <ModelPropertiesDialog
        data={modelData}
        onClose={() => setModelData(null)}
      />
      <Content aside={aside}>
        {loading ? (
          <Loading />
        ) : (
          <Netron
            ref={netronRef}
            files={files}
            showAttributes={showAttributes}
            showInitializers={showInitializers}
            showNames={showNames}
            onRendered={() => setRendered(true)}
            onSearch={(data) => setSearchResult(data)}
            onShowModelProperties={(data) => setModelData(data)}
            onShowNodeDocumentation={(data) => setNodeDocumentation(data)}
            onShowNodeProperties={(data) => {
              setNodeData(data);
              setNodeDocumentation(null);
            }}
          />
        )}
      </Content>
    </>
  );
};

export default Graph;
