import {
  Input as InputBase,
  Button as ButtonBase,
  Card,
  Select as SelectBase
} from 'antd';
import styled from '@/theme';

export const FormWrapper = styled(Card)`
  background-color: #f5f6f9;
`;

export const Input = styled(InputBase)``;

export const Select = styled(SelectBase)``;

export const Button = styled(ButtonBase)`
  float: right;
`;

// 连续：随机取区间内一个数值
export const UniformInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
`;

export const LinkBtn = styled.span`
  color: #faad14;
  cursor: pointer;
`;
