const TitleRule = [
  {
    required: true,
    message: '请输入名称'
  },
  {
    max: 50,
    message: '不能超过50个字符'
  },
  {
    pattern: /^([\u4e00-\u9fa5\w.@-]+\s)*[\u4e00-\u9fa5\w.@-]+$/,
    message:
      '长度为1~50个字符并且首尾不能为空格。由中文、大小写字母、数字、特殊符号（-_.@和空格，但不包含连续空格）四类字符中某一类或多个类别组成。'
  }
];

const DescRule = [
  {
    pattern: /^([\u4e00-\u9fa5\w.,@-]+\s)*[\u4e00-\u9fa5\w.,@-]+$/,
    message:
      '长度最多为100个字符并且首尾不能为空格。由中文、大小写字母、数字、特殊符号（-_.,@和空格，但不包含连续空格）四类字符中某一类或多个类别组成。'
  },
  {
    max: 100,
    message: '不能超过100个字符'
  }
];

export { TitleRule, DescRule };
