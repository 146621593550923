import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { RoleMap, UserType } from '@/apps/authentication/hooks/types';
import DescCard from '@/components/descCard/DescCard';
import { PropertyType } from '@/components/properties/Properties';
import ChangeRole from '../updateAccount/changeRole';
import request from '@/apps/utils';
import StatusSwitch from '../updateAccount/statusSwitch';
import ResetPassword from '../updateAccount/resetPassword';
import { Wrapper } from './styles';

interface AccountCardProps {
  account: UserType;
  accountList: UserType[];
  setAccountList: Dispatch<SetStateAction<UserType[]>>;
}

const AccountCard: FC<AccountCardProps> = ({
  account,
  accountList,
  setAccountList
}) => {
  const {
    accountId,
    displayName,
    email,
    role,
    createTime,
    updateTime,
    isValid
  } = account;
  const [accountRole, setAccountRole] = useState<string>(role);
  const [accountIsValid, setAccountIsValid] = useState<boolean>(isValid);
  const [isUpdateUser, setIsUpdateUser] = useState(false);

  useEffect(() => {
    setAccountRole(role);
    setAccountIsValid(isValid);
  }, [account]);

  useEffect(() => {
    if (isUpdateUser === true) {
      setIsUpdateUser(false);
      const body = JSON.stringify({
        role: accountRole,
        isValid: accountIsValid
      });
      request({
        url: `/account/${accountId}`,
        config: { method: 'PUT', body },
        messageTitle: '修改用户'
      })
        .then(({ data }) => {
          setAccountList(uniqBy([data, ...accountList], 'accountId'));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [accountRole, accountIsValid]);

  const properties: PropertyType[] = [
    {
      key: 'id',
      title: 'id',
      content: accountId
    },
    {
      key: 'email',
      title: '邮箱',
      content: email
    },
    {
      key: 'role',
      title: '角色',
      content: (
        <ChangeRole
          account={account}
          setAccountRole={setAccountRole}
          setIsUpdateUser={setIsUpdateUser}
        />
      )
    },
    {
      key: 'permissions',
      title: '权限',
      content: RoleMap[accountRole][1]
    },
    {
      key: 'createTime',
      title: '创建时间',
      content: moment(createTime).format('YYYY-MM-DD[\n]HH:mm:ss')
    },
    {
      key: 'updateTime',
      title: '更新时间',
      content: moment(updateTime).format('YYYY-MM-DD[\n]HH:mm:ss')
    }
  ];
  return (
    <DescCard
      className="card"
      title={displayName}
      key={accountId}
      properties={properties}
    >
      <Wrapper>
        <StatusSwitch
          account={account}
          setAccountIsValid={setAccountIsValid}
          setIsUpdateUser={setIsUpdateUser}
          accountIsValid={accountIsValid}
        />
        <ResetPassword account={account} />
      </Wrapper>
    </DescCard>
  );
};

export default AccountCard;
