import { Card as CardBase, Typography as TypographyBase } from 'antd';
import styled from '@/theme';

export const CardWrapper = styled(CardBase)`
  background-color: ${(props) => props.theme.palette.background.card};
  &:hover {
    cursor: default;
  }
`;

export const Property = styled(CardBase.Grid).attrs({ hoverable: false })`
  text-align: center;
  display: flex;
  box-shadow: 0 0;
  padding: ${(props) => 2 * props.theme.spacing}px;
`;

export const Typography = styled(TypographyBase)``;

export const PropertiesWrapper = styled.div`
  overflow: hidden;
`;

export const Title = styled(TypographyBase.Text).attrs({
  type: 'secondary'
})`
  white-space: nowrap;
`;
