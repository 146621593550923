import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FC, ReactNode, useEffect, useState } from 'react';
import cs from 'classnames';
import {
  Button,
  Form,
  FormInstance,
  FormListFieldData,
  FormListOperation,
  Input,
  Select
} from 'antd';
import { union } from 'lodash';
import request from '@/apps/utils';
import { FormValueType } from '../../types';
import FileAddressSelect from './FileAddressSelect';
import { UploadWrapper } from '../styles';

interface InputFormProps {
  fields: FormListFieldData[];
  operation: FormListOperation;
  isDichotomy: boolean;
  errors: ReactNode[];
  form: FormInstance<FormValueType>;
}
const InputForm: FC<InputFormProps> = ({
  fields,
  operation: { add, remove },
  isDichotomy,
  errors,
  form
}) => {
  const taskType = Form.useWatch('taskType', form);
  const [selectOptions, setSelectOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    request({
      url: '/labels',
      params: { taskType: 'multiLabel' },
      messageTitle: '获取标签名',
      config: { method: 'GET' }
    }).then(({ data }) => {
      const temp =
        data?.map((item: { title: string; name: string }) => ({
          label: item.title,
          value: item.name
        })) || [];
      setSelectOptions(temp);
    });
  }, []);

  return (
    <UploadWrapper>
      {fields.map(({ key, name }) => {
        const fileId = String(key);
        return (
          <Form.Item key={key} wrapperCol={{ offset: 4, span: 12 }}>
            <Form.Item hidden name={[name, 'fileId']} initialValue={fileId}>
              <div>key: {fileId}</div>
            </Form.Item>
            {(taskType === 'polytomous' || taskType === 'dichotomy') && (
              <Form.Item
                className="left"
                initialValue={fileId}
                name={[name, 'label']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '请输入标签名'
                  }
                ]}
              >
                <Input placeholder="标签名" disabled={isDichotomy} />
              </Form.Item>
            )}
            {taskType === 'multiLabel' && (
              <Form.Item
                className={cs('left', 'multiLabelLeft')}
                name={[name, 'label']}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        value === undefined ||
                        (Array.isArray(value) && value.length < 1)
                      ) {
                        return Promise.reject(new Error('请选择标签'));
                      }
                      const files = form.getFieldValue('files');
                      for (let i = 0; i < files.length; i += 1) {
                        const { label } = files[i];
                        if (
                          label &&
                          union(label, value).length ===
                            (value.length + label.length) / 2 &&
                          fileId !== files[i].fileId
                        ) {
                          return Promise.reject(
                            new Error('样本标签不能完全一样')
                          );
                        }
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showArrow
                  placeholder="请选择标签"
                  options={selectOptions}
                />
              </Form.Item>
            )}
            <FileAddressSelect name={name} fileId={fileId} />
            {!isDichotomy && (
              <span className="minusIcon">
                <MinusCircleOutlined
                  onClick={() => {
                    remove(name);
                  }}
                />
              </span>
            )}
          </Form.Item>
        );
      })}
      {!isDichotomy && (
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button
            onClick={() => {
              add();
            }}
            icon={<PlusOutlined />}
          >
            添加类别文件
          </Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      )}
    </UploadWrapper>
  );
};

export default InputForm;
