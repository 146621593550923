import { Modal, Switch } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { UserType } from '@/apps/authentication/hooks/types';
import { useAuth } from '@/apps/authentication/hooks';

interface StatusSwitchProps {
  account: UserType;
  accountIsValid: boolean;
  setAccountIsValid: Dispatch<SetStateAction<boolean>>;
  setIsUpdateUser: Dispatch<SetStateAction<boolean>>;
}

const StatusSwitch: FC<StatusSwitchProps> = ({
  account: { isValid, displayName, email, accountId },
  accountIsValid,
  setAccountIsValid,
  setIsUpdateUser
}) => {
  const [checked, setChecked] = useState(isValid);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const { currentUser } = useAuth();

  const onChange = (value: boolean) => {
    setChecked(value);
    setConfirmVisible(true);
  };
  const handleOk = () => {
    setConfirmVisible(false);
    setAccountIsValid(checked);
    setIsUpdateUser(true);
  };
  const handleCancel = () => {
    setConfirmVisible(false);
  };
  return (
    <>
      <Switch
        checkedChildren="启用"
        unCheckedChildren="停用"
        checked={accountIsValid}
        onChange={onChange}
        disabled={accountId === currentUser?.accountId}
      />
      <Modal
        title="修改状态"
        visible={confirmVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
      >
        <p>
          {checked
            ? `激活账号${email}（${displayName}），该账号将可以登录系统并恢复原有权限，是否继续？`
            : `停用账号${email}（${displayName}），该账号将不能登录系统，是否继续？`}
        </p>
      </Modal>
    </>
  );
};

export default StatusSwitch;
