import { Form, FormInstance, Radio, Select, Typography } from 'antd';
import { FC } from 'react';
import textImg from '@/assets/images/text.png';
import fileImg from '@/assets/images/file.png';
import { FormValueType } from '../../types';
import { Card, Wrapper } from './styles';

const taskMap = {
  text: [
    {
      title: '二分类',
      image: textImg,
      name: 'dichotomy',
      description: (
        <div>
          数据样本：单行数据为一个样本。
          <br />
          适用场景：web攻击中单一攻击类型分类，文本二分类。
        </div>
      )
    },
    {
      title: '多分类',
      name: 'polytomous',
      image: textImg,
      description: (
        <div>
          数据样本：单行数据为一个样本。
          <br />
          适用场景：web攻击中多种攻击类型分类，文本多分类。
        </div>
      )
    }
  ],
  file: [
    {
      title: '二分类',
      name: 'dichotomy',
      image: fileImg,
      description: (
        <div>
          数据样本：单个文件为一个样本。
          <br />
          适用场景：webshell检测，文件二分类。
        </div>
      )
    },
    {
      title: '多分类',
      name: 'polytomous',
      image: fileImg,
      description: (
        <div>
          数据样本：单个文件为一个样本。
          <br />
          适用场景：脚本语言检测，文件多分类。
        </div>
      )
    },
    {
      title: '多标签',
      name: 'multiLabel',
      image: fileImg,
      description: (
        <div>
          数据样本：单个文件为一个样本。
          <br />
          适用场景：webshell检测和脚本语言类型检测，文件多标签分类。
        </div>
      )
    }
  ]
};

interface TaskTypeProps {
  form: FormInstance<FormValueType>;
}

const TaskType: FC<TaskTypeProps> = ({ form }) => {
  const taskType = Form.useWatch('taskType', form);
  const dataType = Form.useWatch('dataType', form);
  const onTaskTypeChange = () => {
    form.setFieldsValue({ uploadType: undefined, files: undefined });
  };
  return (
    <>
      <Form.Item
        name="taskType"
        label={<Typography.Text type="secondary">任务类型</Typography.Text>}
        rules={[
          {
            required: true,
            message: '请选择任务类型'
          }
        ]}
      >
        <Radio.Group onChange={onTaskTypeChange}>
          <Wrapper>
            {taskMap[dataType || 'text'].map(
              (item: {
                title: string;
                description: string;
                image: string;
                name: string;
              }) => {
                return (
                  <Card
                    key={item.title}
                    hoverable
                    cover={<img className="img" alt="text" src={item.image} />}
                  >
                    <Card.Meta
                      title={<Radio value={item.name}>{item.title}</Radio>}
                      description={item.description}
                    />
                  </Card>
                );
              }
            )}
          </Wrapper>
        </Radio.Group>
      </Form.Item>
      {dataType &&
        taskType === 'dichotomy' &&
        form.getFieldValue('dataType') === 'file' && (
          <Form.Item
            name="tags"
            label={
              <Typography.Text type="secondary">数据集类型</Typography.Text>
            }
            rules={[
              {
                required: true,
                message: '请选择数据集类型'
              }
            ]}
          >
            <Select
              options={[
                { label: 'PHP', value: 'PHP' },
                { label: 'JSP', value: 'JSP' },
                { label: 'ASP', value: 'ASP' }
              ]}
            />
          </Form.Item>
        )}
      {taskType && (
        <Form.Item
          name="uploadType"
          label={<Typography.Text type="secondary">上传方式</Typography.Text>}
          rules={[
            {
              required: true,
              message: '请选择上传方式'
            }
          ]}
        >
          <Select
            options={[
              { label: '本地', value: 'local' },
              { label: '线上', value: 'online' }
            ]}
          />
        </Form.Item>
      )}
    </>
  );
};
export default TaskType;
