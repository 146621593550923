import { useRouteMatch } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { PageWrapper } from '@/components';
import routes from './routes';
import { Wrapper } from './styles';
import Menu from './Menu';
import Switch from '@/components/routers';
import { AlgorithmType } from '@/apps/dashboard/model/modelCreate/types';
import request from '@/apps/utils';

const Detail = () => {
  const { params, path } = useRouteMatch<{ algorithmId: string }>();
  const [algorithm, setAlgorithm] = useState<AlgorithmType>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request({
      url: `/algorithms/${params.algorithmId}`,
      config: {
        method: 'GET'
      },
      params: { defaultModelParam: true }
    })
      .then(({ data }) => {
        setAlgorithm(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Wrapper>
      <PageWrapper
        childrenCardProps={{ loading }}
        headerProps={{
          title: `${algorithm?.title}`,
          footer: <Menu />
        }}
      >
        <Switch
          routes={routes}
          basePath={path}
          routeProp={{ algorithm, setAlgorithm }}
        />
      </PageWrapper>
    </Wrapper>
  );
};

export default Detail;
