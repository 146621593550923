import { ReadOutlined, ContainerOutlined } from '@ant-design/icons';
import { RouteType } from '@/components/routers/types';
import Desc from './desc';
import ParameterSetting from './parameterSetting';

const algorithmRoutes: RouteType[] = [
  {
    key: 'desc',
    path: '/description',
    component: Desc,
    sidebarName: '算法介绍',
    icon: <ReadOutlined />
  },
  {
    key: 'parameter',
    path: '/parameter',
    component: ParameterSetting,
    sidebarName: '参数设置',
    icon: <ContainerOutlined />
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/description'
  }
];

export default algorithmRoutes;
