export const SIZE =
  (process.env.CHUNK_SIZE && +process.env.CHUNK_SIZE) || 1024 * 1024; // 切片大小

export const MAX_FILE_SIZE = 1024 * 1024 * 500;
export interface ChunkType {
  chunk: Blob;
  chunkHash: string;
  size: number;
  filename: string;
  fileHash: string;
  progress: number;
}

export const StatusMap = {
  wait: 'wait',
  active: 'active',
  exception: 'exception',
  success: 'success'
};

interface subsetType {
  subsetId: string;
  title: string;
  dataLabel: string;
  status: 'WAITING' | 'DONE' | 'ERROR' | 'STOPPED';
  errorMsg?: string;
}

export type TaskType = 'dichotomy' | 'polytomous' | 'multiLabel';

export interface DatasetType {
  datasetId: string;
  title: string;
  dataPaths: string[];
  isValid: boolean;
  isDeleted: boolean;
  hasLabel: boolean;
  taskType: TaskType;
  uploadType: 'local' | 'online';
  dataType: 'file' | 'text';
  createTime: string;
  updateTime: string;
  description: string;
  subsets: subsetType[];
  tags: string[];
  dataLabels: string[];
  status: 'WAITING' | 'DONE' | 'ERROR' | 'STOPPED';
}

export const Dictionary = {
  dichotomy: '二分类',
  polytomous: '多分类',
  multiLabel: '多标签',
  file: '文件 ',
  text: '文本'
};

export interface OverviewType {
  label: string;
  path: string;
  metrics?: {
    total_count: number;
    not_suffix_count?: number; // 不满足后缀的样本数
    content_none_count?: number; // 内容为空的样本数
    compile_failed_count?: number; // 编译未通过的样本数
  };
}

export interface FormValueType {
  title: string;
  description: string;
  taskType: 'dichotomy' | 'polytomous' | 'multiLabel';
  tags: string;
  uploadType: 'local' | 'online';
  files: {
    fileId: string;
    label: string;
    fileAddress: string;
    file: File;
  }[];
  dataType?: string;
}

export interface FileType {
  fileId: string;
  label: string;
  status: string;
  hashPercent: number;
  originFile?: File;
  chunks?: ChunkType[];
  fileHash?: string;
}

export interface ActionType {
  type: string;
  value?: any;
  fileId: string;
}
