import { useRouteMatch } from 'react-router-dom';
import Switch from '@/components/routers';
import modelRoutes from './routes';

const Model = () => {
  const { path } = useRouteMatch();
  return <Switch routes={modelRoutes} basePath={path} />;
};

export default Model;
