/* eslint-disable consistent-return */
/* eslint-disable no-loop-func */
import axios from 'axios';
import { notification } from 'antd';
import request from '@/apps/utils';
import { ChunkType, SIZE, StatusMap } from '../types';

export const openNotification = (description: string) => {
  notification.error({
    message: '文件上传失败',
    description,
    duration: 10,
    style: {
      maxHeight: 300,
      overflow: 'scroll'
    }
  });
};

// 生成文件切片
const createFileChunk = (file: Blob, size = SIZE) => {
  const fileChunkList = [];
  let cur = 0;
  while (cur < file.size) {
    fileChunkList.push({ chunk: file.slice(cur, cur + size) });
    cur += size;
  }
  return fileChunkList;
};

const computeHash = (
  fileChunks: { chunk: Blob }[],
  setFileHashPercent: (value: string) => void
): Promise<string> => {
  return new Promise((resolve) => {
    const hashWorker = new Worker('/hash.js');
    hashWorker.postMessage({ fileChunks });
    hashWorker.onmessage = (e) => {
      const { percentage, hash } = e.data;
      setFileHashPercent(percentage.toFixed());
      if (hash) {
        resolve(hash);
      }
    };
  });
};

const mergeRequest = async (
  fileHash: string,
  fileName: string,
  chunkSize: number,
  subsetId: string
) => {
  await request({
    url: `/subset/${subsetId}/upload-merge`,
    config: {
      method: 'POST',
      body: JSON.stringify({ fileHash, fileName, chunkSize })
    }
  });
};

const shouldUpload = async (
  fileHash: string,
  fileName: string,
  subsetId: string
) => {
  try {
    const { data } = await request({
      url: `/subset/${subsetId}/upload-verify`,
      config: {
        method: 'POST',
        body: JSON.stringify({ fileName, fileHash })
      }
    });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const handleChunkProgress = (
  chunks: ChunkType[],
  chunkHash: string,
  setChunks: (value: ChunkType[]) => void
) => {
  return (e: any) => {
    setChunks(
      chunks.map((i) => {
        if (i.chunkHash === chunkHash) {
          i.progress += (e.loaded / e.total) * (100 - i.progress);
        }
        return i;
      })
    );
  };
};

const sendRequest = (
  list: {
    formData: FormData;
    status: string;
    index: number;
    chunkHash: string;
  }[],
  max: number = 4,
  setChunks: (value: ChunkType[]) => void,
  subsetId: string,
  chunks: ChunkType[]
) => {
  return new Promise<void>((resolve, reject) => {
    const len = list.length;
    let counter = 0;
    const retryArr: number[] = [];
    const start = async () => {
      while (counter < len && max > 0) {
        max -= 1;
        const i = list.findIndex(
          (v) => v.status === StatusMap.wait || v.status === StatusMap.exception
        );
        if (i > -1) {
          list[i].status = StatusMap.active;
          const { formData, index, chunkHash } = list[i];
          axios({
            url: `/api/subset/${subsetId}/upload`,
            method: 'POST',
            data: formData,
            onUploadProgress: handleChunkProgress(chunks, chunkHash, setChunks),
            timeout: 60000
          })
            .then(() => {
              list[i].status = StatusMap.success;
              max += 1;
              counter += 1;
              if (counter === len) {
                resolve();
              } else {
                start();
              }
            })
            .catch((err) => {
              list[i].status = StatusMap.exception;
              if (typeof retryArr[index] !== 'number') {
                retryArr[index] = 0;
              }
              retryArr[index] += 1;
              if (retryArr[index] >= 2) {
                return reject(String(err));
              }
              max += 1; // 释放当前占用的通道，但是counter不累加
              start();
            });
        }
      }
    };
    start();
  });
};

const uploadChunks = async (
  chunks: ChunkType[],
  uploadedChunks: string[] = [],
  setChunks: (value: ChunkType[]) => void,
  setFileStatus: (value: string) => void,
  subsetId: string
) => {
  if (chunks.length < 1) return;
  const reqList = chunks
    .filter((item) => !uploadedChunks.includes(item.chunkHash))
    .map(({ chunk, chunkHash, fileHash }, index) => {
      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('hash', chunkHash);
      formData.append('fileHash', fileHash);
      return { formData, status: StatusMap.wait, index, chunkHash };
    });

  await sendRequest(reqList, 4, setChunks, subsetId, chunks);
  if (reqList.length + uploadedChunks.length === chunks.length) {
    await mergeRequest(chunks[0].fileHash, chunks[0].filename, SIZE, subsetId);
    setFileStatus(StatusMap.success);
  }
};

interface handleFileUploadProps {
  chunks: ChunkType[];
  setChunks: (value: ChunkType[]) => void;
  setFileStatus: (value: string) => void;
  subsetId: string;
  fileHash?: string;
  fileName?: string;
}

const handleFileUpload = async ({
  fileHash,
  fileName,
  chunks,
  setChunks,
  setFileStatus,
  subsetId
}: handleFileUploadProps) => {
  if (!fileHash || !fileName) {
    return;
  }
  if (chunks.length) {
    setChunks(chunks.map((i) => ({ ...i, progress: 0 })));
  }
  try {
    const { shouldUploadFile, uploadedChunks = [] } = await shouldUpload(
      fileHash,
      fileName,
      subsetId
    );

    if (!shouldUploadFile) {
      setChunks(chunks.map((i) => ({ ...i, progress: 100 })));
      setFileStatus(StatusMap.success);
      return;
    }
    if (uploadedChunks.length) {
      setChunks(
        chunks.map((i) => {
          if (uploadedChunks.includes(i.chunkHash)) {
            i.progress = 100;
          }
          return i;
        })
      );
    }
    if (chunks.length === uploadedChunks.length) {
      mergeRequest(fileHash, fileName, SIZE, subsetId);
      setFileStatus(StatusMap.success);
    } else {
      setFileStatus(StatusMap.active);
      await uploadChunks(
        chunks,
        uploadedChunks,
        setChunks,
        setFileStatus,
        subsetId
      );
    }
  } catch (err) {
    setFileStatus(StatusMap.exception);
    throw err;
  }
};

export {
  createFileChunk,
  computeHash,
  mergeRequest,
  shouldUpload,
  uploadChunks,
  handleFileUpload
};
