import { RouteType } from '@/components/routers/types';
import Detail from './detail';
import List from './list';

const algorithmSettingRoutes: RouteType[] = [
  {
    key: 'list',
    path: '/list',
    component: List
  },
  {
    key: 'detail',
    path: '/:algorithmId',
    component: Detail
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/list'
  }
];

export default algorithmSettingRoutes;
