import {
  FileTextOutlined,
  LoadingOutlined,
  RedoOutlined
} from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { FC } from 'react';
import { find } from 'lodash';
import {
  IconWrapper,
  ItemName,
  UploadItemWrapper,
  UploadSpan,
  Progress,
  Retry
} from '../styles';
import { ChunkType, DatasetType, FileType, StatusMap } from '../../types';
import { handleFileUpload, openNotification } from '../utils';

interface UploadItemProps {
  fileList: FileType[];
  originFile: UploadFile<any>;
  fileId: string;
  setChunks: (value: ChunkType[]) => void;
  setFileStatus: (value: string) => void;
  dataset?: DatasetType;
}

const getPercent = (fileId: string, fileList: FileType[]) => {
  const file = find(fileList, { fileId });
  if (!file || !file.originFile || !file.chunks) return 0;
  const loaded = file.chunks
    .map((item) => item.size * item.progress)
    .reduce((acc, cur) => acc + cur);
  return parseInt((loaded / file.originFile.size).toFixed(2), 10);
};

const UploadItem: FC<UploadItemProps> = ({
  originFile,
  fileId,
  dataset,
  fileList,
  setChunks,
  setFileStatus
}) => {
  const file = find(fileList, { fileId });

  if (!file) {
    return <></>;
  }
  const { fileHash, chunks, label, status } = file;

  const onClick = () => {
    handleFileUpload({
      fileHash,
      fileName: originFile.name,
      chunks: chunks || [],
      setChunks,
      setFileStatus,
      subsetId: find(dataset?.subsets, { dataLabel: label })?.subsetId || ''
    })
      .then(() => {
        message.success('上传成功');
      })
      .catch((err) => {
        openNotification(String(err));
      });
  };
  const IconMap = {
    [StatusMap.success]: <FileTextOutlined className="icon" />,
    [StatusMap.wait]: <FileTextOutlined className="icon" />,
    [StatusMap.active]: <LoadingOutlined className="icon" />,
    [StatusMap.exception]: (
      <Retry onClick={onClick}>
        <Tooltip title="重试">
          <RedoOutlined className="icon" />
        </Tooltip>
      </Retry>
    )
  };
  return (
    <UploadItemWrapper>
      <UploadSpan>
        <IconWrapper>{IconMap[status]}</IconWrapper>
        <ItemName>{originFile.name}</ItemName>
        <Progress
          style={{ width: '60%' }}
          percent={getPercent(fileId, fileList)}
          status={status as any}
        />
      </UploadSpan>
    </UploadItemWrapper>
  );
};

export default UploadItem;
