import { PauseOutlined } from '@ant-design/icons';
import { Dispatch, SetStateAction } from 'react';
import { TrainType } from '../../../types';
import ActionComponent from './ActionComponent';
import { WaitingIcon, StoppedWrapper, Progress } from './styles';

interface TrainStatusProps {
  actionIconShown: boolean;
  handleShown: () => void;
  train: TrainType;
  setTrains: Dispatch<SetStateAction<TrainType[]>>;
}

const statusProgressMap = {
  DONE: 'success',
  ERROR: 'exception',
  RUNNING: 'active'
};

/*
操作：
1. 停止：WAITING，RUNNING => STOPPED
2. 重试：STOPPED => WAITING
*/

const Status = ({
  actionIconShown,
  handleShown,
  setTrains,
  train
}: TrainStatusProps) => {
  const { status, trainId, ratio } = train;
  if (
    actionIconShown &&
    (status === 'WAITING' || status === 'RUNNING' || status === 'STOPPED')
  ) {
    return (
      <ActionComponent
        trainId={trainId}
        trainStatus={status}
        actionIconShown={actionIconShown}
        handleShown={handleShown}
        setTrains={setTrains}
      />
    );
  }
  if (status === 'WAITING') {
    return (
      <div onMouseEnter={handleShown} onMouseLeave={handleShown}>
        <WaitingIcon />
        等待中
      </div>
    );
  }
  if (status === 'STOPPED') {
    return (
      <div onMouseEnter={handleShown} onMouseLeave={handleShown}>
        <StoppedWrapper>
          <span className="text">
            <PauseOutlined />
          </span>
        </StoppedWrapper>
      </div>
    );
  }
  return (
    <div onMouseEnter={handleShown} onMouseLeave={handleShown}>
      <Progress
        type="circle"
        trailColor="#eaecf2"
        width={70}
        percent={(ratio && +ratio.toFixed()) || 0}
        status={statusProgressMap[status] as any}
      />
    </div>
  );
};

export default Status;
