import { Card as CardBase } from 'antd';
import styled from '@/theme';

export const Card = styled(CardBase)`
  width: 33%;
  margin: 0 12px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  .ant-card-body {
    padding: 12px;
  }
  .ant-card-cover {
    width: 128px;
    height: 128px;
    padding: 12px;
    margin: 0 auto;
    overflow: hidden;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;
