import { useState, useEffect, FC, Dispatch } from 'react';
import { Form, Select } from 'antd';
import cs from 'classnames';
import { union } from 'lodash';
import request from '@/apps/utils';
import { ActionType, FileType } from '../../types';
import { actionCreators } from '../store';

interface MultiLabelInputProps {
  name: number;
  fileId: string;
  fileList: FileType[];
  dispatch: Dispatch<ActionType>;
}

const MultiLabelInput: FC<MultiLabelInputProps> = ({
  name,
  fileList,
  fileId,
  dispatch
}) => {
  const [selectOptions, setSelectOptions] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    request({
      url: '/labels',
      params: { taskType: 'multiLabel' },
      messageTitle: '获取标签名',
      config: { method: 'GET' }
    }).then(({ data }) => {
      const temp =
        data?.map((item: { title: string; name: string }) => ({
          label: item.title,
          value: item.name
        })) || [];
      setSelectOptions(temp);
    });
  }, []);

  const onSelectChange = (value: string[], id: string) => {
    const label = value.join(',');
    dispatch(actionCreators.setLabel(label, id));
  };
  return (
    <Form.Item
      className={cs('left', 'multiLabelLeft')}
      name={[name, 'label']}
      rules={[
        () => ({
          validator(_, value) {
            if (
              value === undefined ||
              (Array.isArray(value) && value.length < 1)
            ) {
              return Promise.reject(new Error('请选择标签'));
            }
            for (let i = 0; i < fileList.length; i += 1) {
              if (fileList[i].label && typeof fileList[i].label === 'string') {
                const label: string[] = fileList[i].label.split(',');
                if (
                  union(label, value).length ===
                    (value.length + label.length) / 2 &&
                  fileId !== fileList[i].fileId
                ) {
                  return Promise.reject(new Error('样本标签不能完全一样'));
                }
              }
            }
            return Promise.resolve();
          }
        })
      ]}
    >
      <Select
        mode="multiple"
        allowClear
        showArrow
        placeholder="请选择标签"
        onChange={(value) => onSelectChange(value, String(fileId))}
        options={selectOptions}
      />
    </Form.Item>
  );
};
export default MultiLabelInput;
