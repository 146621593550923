import { Dispatch, FC, SetStateAction, useState } from 'react';
import moment from 'moment';
import { Dictionary } from '@/apps/dashboard/dataset/types';
import { DescCard, Link } from '@/components';
import { PropertyType } from '@/components/properties/Properties';
import { TrainType } from '../../types';
import { toFixed } from '@/apps/utils/utils';
import DeleteModel from './DeleteModel';
import { StatusWrapper } from './styles';
import Status from './modelStatus';

interface ModelCardProps {
  train: TrainType;
  setTrains: Dispatch<SetStateAction<TrainType[]>>;
}

const ModelCard: FC<ModelCardProps> = ({ train, setTrains }) => {
  const [actionIconShown, setActionIconShown] = useState(false);
  const {
    trainId,
    description,
    createTime,
    updateTime,
    title,
    model,
    dataset,
    algorithm
  } = train;
  const properties: PropertyType[] = [
    {
      key: 'id',
      title: 'id',
      content: trainId
    },
    {
      key: 'description',
      title: '描述',
      content: description || '无'
    },
    {
      key: 'trainId',
      title: '训练',
      content: (
        <Link to={`${trainId}/detail`} permission="model">
          {`${train?.title}（${trainId}）`}
        </Link>
      )
    },
    {
      key: 'algorithmId',
      title: '算法',
      content:
        (algorithm && `${algorithm.title}（${algorithm.algorithmId}）`) || '无'
    },
    {
      key: 'title',
      title: '数据集',
      content:
        (dataset && !dataset.isDeleted && (
          <Link to={`${trainId}/dataset`} permission="model">
            {`${dataset.title}（${dataset.datasetId}）`}
          </Link>
        )) ||
        '无'
    },
    {
      key: 'taskType',
      title: '任务类型',
      content: (dataset && Dictionary[dataset.taskType]) || '无'
    },
    {
      key: 'dataType',
      title: '数据类型',
      content: (dataset && Dictionary[dataset.dataType]) || '无'
    },
    {
      key: 'score',
      title: '综合评分',
      content: model ? toFixed(+model.score, 4) : '无'
    },
    {
      key: 'createTime',
      title: '创建时间',
      content: moment(createTime).format('YYYY-MM-DD[\n]HH:mm:ss')
    },
    {
      key: 'updateTime',
      title: '更新时间',
      content: moment(updateTime).format('YYYY-MM-DD[\n]HH:mm:ss')
    }
  ];

  const handleShown = () => {
    setActionIconShown((pre) => !pre);
  };
  return (
    <DescCard
      className="card"
      title={title}
      key={trainId}
      properties={properties}
      extra={<DeleteModel title={title} id={trainId} setTrains={setTrains} />}
      actions={
        model && [
          <Link to={`${trainId}/evaluate`}>模型评估</Link>,
          <Link to={`${trainId}/model`}>模型使用</Link>
        ]
      }
    >
      <StatusWrapper>
        <Status
          actionIconShown={actionIconShown}
          handleShown={handleShown}
          setTrains={setTrains}
          train={train}
        />
      </StatusWrapper>
    </DescCard>
  );
};

export default ModelCard;
