import { AutoComplete, Form, FormInstance, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { find } from 'lodash';
import { DescRule, TitleRule } from '@/apps/utils/formRules';
import request from '@/apps/utils';
import { DebounceSelect } from '@/components';
import { DatasetType } from '../../dataset/types';
import { AlgorithmType } from './types';
import { Input, Select } from './styles';
import SearchSpace from './SearchSpace';

interface Props {
  form: FormInstance;
}

const defaultTrialNumber = ['1', '5', '10', '15', '20'].map((item) => ({
  value: item
}));

async function fetchDataset(value: string) {
  return request({
    url: '/dataset',
    config: { method: 'GET' },
    params: { keyword: value.trim(), verbose: true, isValid: true },
    messageTitle: '数据集列表'
  })
    .then(({ data }) => {
      return data.map((item: DatasetType) => ({
        label: `${item.title}(${item.datasetId})`,
        value: item.datasetId
      }));
    })
    .catch((err) => {
      console.log(err);
    });
}

const SettingForm: FC<Props> = ({ form }) => {
  const [dataset, setDataset] = useState<DatasetType[]>([]);
  const [algorithms, setAlgorithms] = useState<AlgorithmType[]>([]);
  const [trialNumber, setTrialNumber] = useState<{ value: string }[]>([
    ...defaultTrialNumber
  ]);
  const algorithmId = Form.useWatch('algorithmId', form);

  useEffect(() => {
    const selectedDataset = find(dataset, {
      datasetId: form.getFieldValue('datasetId')
    });
    const dataType = selectedDataset?.dataType;
    const tags = selectedDataset?.tags;
    const taskType = selectedDataset?.taskType;

    request({
      url: '/algorithms',
      params: {
        inputType: dataType,
        tags: tags && tags.join(','),
        taskType
      },
      config: {
        method: 'GET'
      }
    })
      .then(({ data }) => {
        setAlgorithms(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [form.getFieldValue('datasetId'), dataset]);

  useEffect(() => {
    request({
      url: '/dataset?verbose=true&isValid=true',
      config: { method: 'GET' }
    })
      .then(({ data }) => {
        setDataset(data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  const onSearch = (searchText: string) => {
    const num = Number(searchText);
    if (
      !searchText ||
      find(trialNumber, (o) => o.value === searchText) ||
      !/^\d+$/.test(searchText) ||
      num < 1 ||
      num > 20
    ) {
      return;
    }
    setTrialNumber([{ value: searchText }, ...defaultTrialNumber]);
  };

  const onChange = () => {
    form.setFieldsValue({ algorithmId: '' });
  };

  return (
    <>
      <Form.Item
        name="title"
        label={<Typography.Text type="secondary">模型名称</Typography.Text>}
        rules={[...TitleRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={<Typography.Text type="secondary">描述</Typography.Text>}
        rules={[...DescRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={<Typography.Text type="secondary">数据集</Typography.Text>}
        name="datasetId"
        rules={[
          {
            required: true,
            message: '请选择数据集'
          }
        ]}
      >
        <DebounceSelect onChange={onChange} fetchOptions={fetchDataset} />
      </Form.Item>
      <Form.Item
        label={<Typography.Text type="secondary">使用算法</Typography.Text>}
        name="algorithmId"
        rules={[
          {
            required: true,
            message: '请选择算法'
          }
        ]}
      >
        <Select disabled={!form.getFieldValue('datasetId')}>
          {algorithms.map((item) => (
            <Select.Option key={item.algorithmId} value={item.algorithmId}>
              {`${item.title}(${item.algorithmId})`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {algorithmId && (
        <Form.Item
          label={<Typography.Text type="secondary">试验次数</Typography.Text>}
          name="trialNumber"
          rules={[
            () => ({
              required: true,
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error('请选择试验次数'));
                }
                const v = Number(value);
                if (!/^\d+$/.test(value) || v < 1 || v > 20) {
                  return Promise.reject(new Error('请输入1-20的整数'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <AutoComplete options={trialNumber} onSearch={onSearch} />
        </Form.Item>
      )}
      <SearchSpace form={form} />
    </>
  );
};

export default SettingForm;
