import { FC, ReactNode } from 'react';
import { Wrapper } from './styles';

type AsideProps = {
  children: ReactNode;
  width?: number;
  bottom?: ReactNode;
  className?: string;
};

const Aside: FC<AsideProps> = ({ width, bottom, children, className }) => {
  return (
    <Wrapper width={width} className={className}>
      <div className="top">{children}</div>
      {bottom && <div className="bottom">{bottom}</div>}
    </Wrapper>
  );
};

export default Aside;
