import LoginForm from './LoginForm';
import riverLogo from '@/assets/images/logos/logo_red.png';
import { Wrapper, Logo, Container, Title, Version } from './styles';

const Login = () => {
  return (
    <Wrapper>
      <Container>
        <Logo>
          <img alt="logo" className="logo" src={riverLogo} />
        </Logo>
        <Title>自动化 AI 安全分析平台</Title>
        <Version>{process.env.VERSION}</Version>
        <LoginForm />
      </Container>
    </Wrapper>
  );
};

export default Login;
