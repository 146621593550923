import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import qs from 'qs';
import { SHA256 } from 'crypto-js';
import { useAuth } from '../hooks';

import {
  Input,
  InputForm,
  InputFormItem,
  FormWrapper,
  Button,
  Alert
} from './styles';

const LoginMessage: React.FC<{
  content: string;
}> = ({ content }) => <Alert message={content} type="error" showIcon />;

const LoginForm = () => {
  const { login, error } = useAuth();

  const handleSubmit = (values: any) => {
    const { username, password } = values;
    login({ username, password: SHA256(password).toString() }, () => {
      const search: any = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      });
      if (search.redirectUrl) {
        window.location.assign(search.redirectUrl);
      } else {
        window.location.assign('/');
      }
    });
  };
  return (
    <FormWrapper>
      <InputForm size="large" onFinish={handleSubmit}>
        {error && <LoginMessage content={error} />}
        <InputFormItem
          name="username"
          rules={[
            {
              required: true,
              type: 'email',
              message: '请输入邮箱'
            }
          ]}
        >
          <Input placeholder="邮箱" prefix={<UserOutlined />} />
        </InputFormItem>
        <InputFormItem
          name="password"
          rules={[
            {
              required: true,
              message: '请输入密码'
            }
          ]}
        >
          <Input.Password placeholder="密码" prefix={<LockOutlined />} />
        </InputFormItem>
        <InputFormItem>
          <Button type="primary" htmlType="submit">
            登录
          </Button>
        </InputFormItem>
      </InputForm>
    </FormWrapper>
  );
};

export default LoginForm;
