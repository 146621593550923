import { FC, useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AlgorithmType } from '@/apps/dashboard/model/modelCreate/types';
import { UniformInput } from '@/apps/dashboard/model/modelCreate/styles';
import { Title } from '@/components';
import { handleSearchSpace } from '@/apps/dashboard/model/modelCreate/utils';
import { getRules } from './utils';
import request from '@/apps/utils';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 12
  }
};

interface ParameterSettingProps {
  algorithm?: AlgorithmType;
  setAlgorithm?: (algorithm: AlgorithmType) => void;
}

const ParameterSetting: FC<ParameterSettingProps> = ({
  algorithm,
  setAlgorithm
}) => {
  const [form] = useForm();
  const [submitting, setSubmitting] = useState(false);
  if (!algorithm) {
    return <></>;
  }
  useEffect(() => {
    const modelParam = handleSearchSpace(algorithm?.modelParam);
    form.setFieldsValue({ ...modelParam });
  }, [algorithm]);

  const handleSubmit = (values: { [key: string]: any }) => {
    setSubmitting(true);
    const temp = {};
    try {
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          if (typeof values[key] === 'string') {
            temp[key] = values[key]
              .split(',')
              .filter((i: string) => i)
              .map((i: string) => (Number.isNaN(Number(i)) ? i : Number(i)));
          } else if (
            typeof values[key] === 'object' &&
            (values[key].min || values[key].max)
          ) {
            temp[key] = [values[key].min, values[key].max];
          } else {
            temp[key] = values[key];
          }
        }
      });
      request({
        url: `/algorithms/${algorithm.algorithmId}`,
        config: { method: 'PUT', body: JSON.stringify({ modelParam: temp }) },
        messageTitle: '修改算法参数'
      })
        .then(({ data }) => {
          if (setAlgorithm) {
            setAlgorithm({
              ...data,
              defaultModelParam: algorithm.defaultModelParam
            });
          }
          message.success('保存成功');
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  };

  const onReset = () => {
    if (!algorithm?.defaultModelParam) {
      return;
    }
    const modelParam = handleSearchSpace(algorithm.defaultModelParam);
    form.setFieldsValue({ ...modelParam });
  };

  return (
    <Title divider={false} title="参数设置">
      <Form
        {...layout}
        size="large"
        form={form}
        onFinish={(values: { [key: string]: any }) => handleSubmit(values)}
      >
        {algorithm.modelParam?.map((item) => {
          if (item.show === false) {
            return undefined;
          }
          return (
            <Form.Item
              {...item}
              key={item.key}
              name={item.type === 'uniform' ? undefined : item.key}
              label={
                <Typography.Text type="secondary">
                  {item.description}
                </Typography.Text>
              }
              rules={getRules(item.rules)}
            >
              {item.type === 'choice' ? (
                <Input
                  placeholder={item.placeholder || '输入枚举值，以“,”分隔'}
                />
              ) : item.type === 'single' ? (
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={item.placeholder}
                />
              ) : (
                <UniformInput>
                  <Form.Item
                    noStyle
                    {...item}
                    key="min"
                    name={[item.key, 'min']}
                    rules={getRules(item.rules)}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      step={0.1}
                      placeholder={item.placeholder || '输入最小值'}
                    />
                  </Form.Item>
                  &nbsp;&nbsp;~&nbsp;&nbsp;
                  <Form.Item
                    noStyle
                    {...item}
                    key="max"
                    name={[item.key, 'max']}
                    rules={getRules(item.rules)}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      step={0.1}
                      placeholder={item.placeholder || '输入最大值'}
                    />
                  </Form.Item>
                </UniformInput>
              )}
            </Form.Item>
          );
        })}
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
          <Button
            style={{ width: '112px', float: 'right' }}
            type="primary"
            htmlType="submit"
            disabled={submitting}
          >
            {submitting ? '保存中...' : '保存'}
          </Button>
          <Button
            style={{ marginRight: '14px', float: 'right' }}
            type="primary"
            onClick={onReset}
          >
            恢复默认值
          </Button>
        </Form.Item>
      </Form>
    </Title>
  );
};
export default ParameterSetting;
