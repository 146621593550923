import * as React from 'react';
import { EChartsOption, RegisteredSeriesOption } from 'echarts';
import { merge, noop } from 'lodash';
import ReactEcharts from 'echarts-for-react';
import numeral from 'numeral';

interface BasicHorizontalBarOption {
  series: RegisteredSeriesOption['bar'][];
}

const defaultSeriesOption: EChartsOption = {
  type: 'bar',
  itemStyle: {
    borderRadius: [2, 2, 0, 0]
  },
  barMaxWidth: 24
};

const defaultOption: EChartsOption = {
  tooltip: {
    trigger: 'item',
    padding: 10,
    borderColor: 'transparent'
  },
  toolbox: {
    show: false
  },
  legend: {
    left: 'center',
    bottom: 20,
    itemWidth: 16,
    itemHeight: 8,
    textStyle: {
      fontSize: 14,
      color: '#696E7B'
    }
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '5%'
  },
  yAxis: {
    type: 'category',
    axisLine: {
      lineStyle: {
        color: '#CCD0D9'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#696E7B',
      showMaxLabel: true
    },
    splitLine: {
      lineStyle: {
        type: 'dotted'
      }
    }
  },
  xAxis: {
    type: 'value',
    minInterval: 1,
    axisLine: {
      lineStyle: {
        color: '#CCD0D9'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#696E7B',
      formatter: (value: number) => {
        return numeral(value).format('0.[0]a');
      }
    }
  }
};

interface BasicHorizontalBarChartProps {
  className?: string;
  option: BasicHorizontalBarOption;
  onClick?: (params: any) => void;
  style?: React.CSSProperties;
}

const BasicHorizontalBarChart: React.FC<BasicHorizontalBarChartProps> = ({
  className,
  option,
  onClick,
  style
}) => {
  const getOption = () => {
    return merge({}, defaultOption, option, {
      series:
        option.series &&
        option.series.map((t) => merge({}, defaultSeriesOption, t))
    });
  };

  const onEvents = {
    click: onClick || noop
  };

  return (
    <ReactEcharts
      className={className}
      option={getOption()}
      onEvents={onEvents}
      style={{ minHeight: '350px', ...style }}
    />
  );
};

export default BasicHorizontalBarChart;
