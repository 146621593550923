import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Modal, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { RoleMap, UserType } from '@/apps/authentication/hooks/types';
import { useAuth } from '@/apps/authentication/hooks';

interface ChangeRoleProps {
  account: UserType;
  setAccountRole: Dispatch<SetStateAction<string>>;
  setIsUpdateUser: Dispatch<SetStateAction<boolean>>;
}

const ChangeRole: FC<ChangeRoleProps> = ({
  account: { role, displayName, email, accountId },
  setAccountRole,
  setIsUpdateUser
}) => {
  const [selectedRole, setSelectedRole] = useState<string>(role);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const handleChange = (value: any) => {
    setSelectedRole(value);
    setConfirmVisible(true);
  };
  const handleOk = () => {
    setConfirmVisible(false);
    setAccountRole(selectedRole);
    setIsUpdateUser(true);
  };
  const handleCancel = () => {
    setConfirmVisible(false);
  };

  const { currentUser } = useAuth();

  const options = ['admin', 'user', 'guest'];
  return (
    <>
      <Select
        value={role}
        onChange={handleChange}
        style={{ width: 120 }}
        optionLabelProp="label"
        disabled={accountId === currentUser?.accountId}
      >
        {options.map((item) => {
          return (
            <Select.Option
              key={item}
              value={item}
              label={
                <>
                  <UserOutlined />
                  &nbsp;
                  {RoleMap[item][0]}
                </>
              }
            >
              {RoleMap[item][0]}
            </Select.Option>
          );
        })}
      </Select>
      <Modal
        title="修改角色"
        visible={confirmVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
      >
        <p>{`将账号${email}（${displayName}）角色从“${RoleMap[role][0]}”修改为“${RoleMap[selectedRole][0]}”，
        权限变更为“${RoleMap[selectedRole][1]}”，是否继续？`}</p>
      </Modal>
    </>
  );
};

export default ChangeRole;
