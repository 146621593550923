import { Drawer as DrawerBase } from 'antd';
import styled from '@/theme';

export const Drawer = styled(DrawerBase)`
  .ant-drawer-title {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
