import { SortOrder } from 'antd/lib/table/interface';
import { DatasetType } from '../dataset/types';
import { ProjectType, AlgorithmType } from './modelCreate/types';

export const MAX_FILE_SIZE = 1024 * 1024 * 100;

export const DEFAULT_PAGE_SIZE = 10;

export interface ModelType {
  modelId: string;
  title: string;
  description: string;
  trainId: string;
  duration: number;
  score: string;
  createTime: string;
  updateTime: string;
}

export interface TrainType {
  trainId: string;
  title: string;
  description: string;
  createTime: string;
  updateTime: string;
  status: 'DONE' | 'RUNNING' | 'STOPPED' | 'WAITING' | 'ERROR';
  datasetId: string;
  modelId: string;
  ratio?: number;
  duration?: number;
  model?: ModelType;
  project?: ProjectType;
  dataset?: DatasetType;
  algorithm?: AlgorithmType;
}

interface evaluationMatrixType {
  precision: number;
  recall: number;
  'f1-score': number;
  support: number;
}

export interface ModelEvaluateType {
  modelId: string;
  parameter: string;
  learningCurve: {
    train: {
      accuracy: number[];
      loss: number[];
    };
    valid: {
      accuracy: number[];
      loss: number[];
    };
  };
  labels: string[];
  confusionMatrix: { [key: string]: number[][] };
  evaluationMatrix: evaluationMatrixType[];
  prcCurve: PrcCurveType[];
  rocCurve: RocCurveType[];
}

export interface UploadResultType {
  label: string[];
  prob: number[];
  data: string[];
  labels: string[]; // 预测结果中所包含的标签，用于筛选结果
}

export interface PrcCurveType {
  label: string;
  recall: number[];
  precision: number[];
}

export interface RocCurveType {
  label: string;
  fpr: number[];
  tpr: number[];
}

export interface RequestParams {
  pageSize: number;
  page: number;
  probSort?: SortOrder;
  dataFilter?: string;
  label?: string[];
}
