import { FC, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { Wrapper } from './styles';

interface TextEllipsisProps {
  text: string;
  tooltipProps?: TooltipProps;
}

const TextEllipsis: FC<TextEllipsisProps> = ({ text, tooltipProps }) => {
  const textElementRef = useRef<any>();
  const [hover, setHover] = useState(false);

  const compareSize = (mouseEnter: boolean) => {
    if (textElementRef && textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare && mouseEnter);
    }
  };

  return (
    <>
      <Tooltip title={text} {...tooltipProps} visible={hover}>
        <Wrapper
          ref={textElementRef}
          onMouseEnter={() => {
            compareSize(true);
          }}
          onMouseLeave={() => {
            compareSize(false);
          }}
        >
          {text}
        </Wrapper>
      </Tooltip>
    </>
  );
};

export default TextEllipsis;
