import React from 'react';
import { Spin, Wrapper } from './styles';

interface LoadingProps {
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Spin />
    </Wrapper>
  );
};

export default Loading;
