import { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Empty } from 'antd';
import { DescCard, Title } from '@/components';
import request from '@/apps/utils';
import { DatasetType, Dictionary } from '../types';
import Overview from './Overview';
import List from './List';
import { Wrapper } from './styles';

interface DetailProps {
  datasetId: string;
}

const Detail: FC<DetailProps> = ({ datasetId }) => {
  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState<DatasetType>();

  const timer = useRef<any>();
  const datasetRef = useRef<DatasetType>();
  datasetRef.current = dataset;

  const getDataset = () => {
    request({
      url: `/dataset/${datasetId}`,
      config: { method: 'GET' }
    })
      .then(({ data }) => {
        setDataset(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDataset();
    timer.current = setInterval(() => {
      getDataset();
    }, 5000);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (dataset?.status !== 'WAITING' && timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    } else if (!timer.current) {
      timer.current = setInterval(() => {
        getDataset();
      }, 5000);
    }
  }, [dataset]);

  const properties = dataset
    ? [
        {
          key: 'id',
          title: 'id',
          content: dataset?.datasetId
        },
        {
          key: 'title',
          title: '名称',
          content: dataset?.title
        },
        {
          key: 'description',
          title: '描述',
          content: dataset?.description || '无'
        },
        {
          key: 'taskType',
          title: '任务类型',
          content: dataset && Dictionary[dataset.taskType]
        },
        {
          key: 'dataType',
          title: '数据类型',
          content: dataset && Dictionary[dataset.dataType]
        },
        {
          key: 'createTime',
          title: '创建时间',
          content: moment(dataset?.createTime).format('YYYY-MM-DD[\n]HH:mm:ss')
        }
      ]
    : [];

  return (
    <DescCard loading={loading} properties={properties}>
      {dataset ? (
        <Wrapper>
          <Title title="数据集概览">
            <Overview datasetId={datasetId} />
          </Title>
          <List
            datasetId={datasetId}
            dataset={dataset}
            setDataset={setDataset}
          />
        </Wrapper>
      ) : (
        <Empty description="数据集不存在或已删除" />
      )}
    </DescCard>
  );
};

export default Detail;
