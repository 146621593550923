import { Form } from 'antd';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageWrapper } from '@/components';
import request from '@/apps/utils';
import SettingForm from './SettingForm';
import { Button, FormWrapper } from './styles';
import { FormValueType } from './types';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 12
  }
};

const ModelCreate = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { state } = useLocation<{ datasetId: string }>();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (values: FormValueType) => {
    setSubmitting(true);
    try {
      const searchSpace = {};
      Object.keys(values.searchSpace).forEach((key) => {
        if (values.searchSpace[key]) {
          if (typeof values.searchSpace[key] === 'string') {
            searchSpace[key] = values.searchSpace[key]
              .split(',')
              .filter((i: string) => i)
              .map((i: string) => Number(i));
          } else if (
            typeof values.searchSpace[key] === 'object' &&
            (values.searchSpace[key].min || values.searchSpace[key].max)
          ) {
            searchSpace[key] = [
              values.searchSpace[key].min,
              values.searchSpace[key].max
            ];
          }
        }
      });
      const body = JSON.stringify({
        ...values,
        trialNumber: +values.trialNumber,
        searchSpace
      });

      request({
        url: '/models/create',
        config: { method: 'POST', body },
        messageTitle: '新建模型'
      })
        .then(({ data }) => {
          history.push(`/manage-model/${data.trainId}/detail`);
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err);
        });
    } catch (err) {
      setSubmitting(false);
      console.log(err);
    }
  };

  return (
    <PageWrapper
      headerProps={{
        title: '新建模型'
      }}
    >
      <FormWrapper hoverable>
        <Form<FormValueType>
          form={form}
          {...layout}
          size="large"
          onFinish={(values: FormValueType) => handleSubmit(values)}
          initialValues={{ datasetId: state?.datasetId }}
        >
          {() => {
            return (
              <>
                <SettingForm form={form} />
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={submitting}
                  >
                    {submitting ? '保存中...' : '保存'}
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};

export default ModelCreate;
