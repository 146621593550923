import AvatarComponent from './avatar';
import { HeaderWrapper, RightContent } from './styles';

const Header = () => {
  return (
    <HeaderWrapper>
      <RightContent>
        <AvatarComponent />
      </RightContent>
    </HeaderWrapper>
  );
};

export default Header;
