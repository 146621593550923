import { FormInstance, Form, Input, Radio, Typography, Tooltip } from 'antd';
import { FC } from 'react';
import { DescRule, TitleRule } from '@/apps/utils/formRules';
import { FormValueType } from '../types';
import TaskType from './taskType';

interface BaseFormProps {
  form: FormInstance<FormValueType>;
}
const BaseForm: FC<BaseFormProps> = ({ form }) => {
  const onDataTypeChange = () => {
    form.setFieldsValue({ tags: undefined, taskType: undefined });
  };
  return (
    <>
      <Form.Item
        name="title"
        label={<Typography.Text type="secondary">数据集名称</Typography.Text>}
        rules={[...TitleRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={<Typography.Text type="secondary">描述</Typography.Text>}
        rules={[...DescRule]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="dataType"
        label={<Typography.Text type="secondary">元数据类型</Typography.Text>}
        rules={[
          {
            required: true,
            message: '请选择元数据类型'
          }
        ]}
      >
        <Radio.Group onChange={onDataTypeChange}>
          <Radio value="text">
            <Tooltip title="仅支持后缀名为txt、log、csv、json的四类文本">
              文本（text）
            </Tooltip>
          </Radio>
          <Radio value="file">文件（file）</Radio>
        </Radio.Group>
      </Form.Item>
      <TaskType form={form} />
    </>
  );
};

export default BaseForm;
