import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Col, Empty, Row, Statistic, Typography, Skeleton } from 'antd';
import { formatSeconds } from '@/apps/utils/utils';
import request from '@/apps/utils';
import { TrainType } from '../types';
import { TrainStatusMap, TrainStatusType, TrialType } from './types';
import HyperParamsTrace from './HyperParamsTrace';
import DurationStatistic from './DurationStatistic';
import ProjectDetail from './ProjectDetail';
import { Progress, WaitingIcon, DCard, GrayCard, ModalBtn } from './styles';

const statusProgressMap = {
  DONE: 'success',
  ERROR: 'exception',
  STOPPED: 'exception',
  RUNNING: 'active'
};

const getHyperParamsTraceData = (trials: TrialType[]) => {
  if (trials.length < 1) {
    return { values: [], ids: [] };
  }
  const values: any[][] = [];
  const ids: string[] = [];
  for (let i = 0; i < trials.length; i += 1) {
    values[i] = Object.values(JSON.parse(trials[i].hyperParameters));
    values[i].push(trials[i].score);
    ids.push(trials[i].sequenceId);
  }
  return { values, ids };
};

const TrainDetail = () => {
  const [trainStatus, setTrainStatus] = useState<TrainStatusType>({
    status: 'RUNNING',
    ratio: 0,
    trialStat: {
      total: 0,
      succeeded: 0,
      failed: 0,
      stopped: 0,
      running: 0,
      waiting: 0
    }
  });
  const [train, setTrain] = useState<TrainType>();
  const [loading, setLoading] = useState(true);
  const timer = useRef<any>();
  const statusRef = useRef<TrainStatusType>();
  statusRef.current = trainStatus;
  const params = useParams<{ trainId: string }>();

  const [trials, setTrials] = useState<TrialType[]>([]);
  const [projectModalShown, setProjectModalShown] = useState(false);

  useEffect(() => {
    if (
      trainStatus.status !== 'RUNNING' &&
      trainStatus.status !== 'WAITING' &&
      timer.current
    ) {
      clearInterval(timer.current);
    }
  }, [trainStatus]);

  const getStatus = () => {
    if (
      statusRef.current &&
      (statusRef.current.status === 'RUNNING' ||
        statusRef.current.status === 'WAITING')
    ) {
      request({
        url: `/train/${params.trainId}`,
        config: { method: 'GET' },
        messageTitle: '训练进度请求'
      })
        .then(({ data }) => {
          setTrainStatus(data);
        })
        .catch((err) => {
          console.error(err);
          if (timer.current) {
            clearInterval(timer.current);
          }
        });
    }
  };

  useEffect(() => {
    let isMount = true;
    request({
      url: `/train/${params.trainId}?verbose=true`,
      config: { method: 'GET' },
      messageTitle: '训练详情请求'
    })
      .then(({ data }) => isMount && setTrain(data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => isMount && setLoading(false));

    // 检查训练进度
    getStatus();

    timer.current = setInterval(() => {
      getStatus();
    }, 5000);
    return () => {
      isMount = false;
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  const handleProjectModelShown = () => {
    setProjectModalShown(true);
  };

  const properties = [
    {
      key: 'id',
      title: 'id',
      content: train?.trainId
    },
    {
      key: 'title',
      title: '名称',
      content: train?.title
    },
    {
      key: 'description',
      title: '描述',
      content: train?.description || '无'
    },
    {
      key: 'createTime',
      title: '创建时间',
      content:
        (train?.createTime &&
          moment(train.createTime).format('YYYY-MM-DD[\n]HH:mm:ss')) ||
        '无'
    },
    {
      key: 'updateTime',
      title: '更新时间',
      content:
        (train?.updateTime &&
          moment(train.updateTime).format('YYYY-MM-DD[\n]HH:mm:ss')) ||
        '无'
    },
    {
      key: 'duration',
      title: '训练时长',
      content:
        (trainStatus.duration && formatSeconds(trainStatus.duration / 1000)) ||
        '无'
    },
    {
      key: 'projectId',
      content: (
        <ModalBtn onClick={handleProjectModelShown}>模型创建参数</ModalBtn>
      )
    }
  ];
  return (
    <Skeleton active loading={loading}>
      {train ? (
        <>
          <Row gutter={[16, 16]} wrap>
            <Col span={12}>
              <DCard
                hoverable={false}
                properties={properties}
                propertyWidth="100%"
              />
            </Col>
            <Col span={12}>
              <GrayCard
                title="状态"
                style={{
                  height: '100%',
                  maxHeight: '314px',
                  overflow: 'scroll'
                }}
              >
                {trainStatus.status === 'WAITING' ? (
                  <>
                    <WaitingIcon />
                    等待中
                  </>
                ) : (
                  <Progress
                    style={{ width: '70%', marginBottom: '15px' }}
                    percent={
                      (trainStatus.ratio && +trainStatus.ratio.toFixed()) || 0
                    }
                    status={statusProgressMap[trainStatus.status] as any}
                  />
                )}
                <Row gutter={[16, 8]} wrap>
                  {trainStatus.errorMsg && (
                    <Typography.Text
                      type="danger"
                      style={{ padding: '0 8px 8px' }}
                    >
                      {trainStatus.errorMsg}
                    </Typography.Text>
                  )}
                </Row>
                <Row gutter={[16, 8]} wrap>
                  {trainStatus.trialStat &&
                    Object.keys(trainStatus.trialStat).map((key) => (
                      <Col span={8} key={key}>
                        <Statistic
                          title={TrainStatusMap[key]}
                          value={trainStatus.trialStat[key]}
                        />
                      </Col>
                    ))}
                </Row>
              </GrayCard>
            </Col>
            {trainStatus.hyperParamsTrace && (
              <Col span={24}>
                <HyperParamsTrace
                  data={getHyperParamsTraceData(trials)}
                  keys={trainStatus.hyperParamsTrace.keys}
                />
              </Col>
            )}
            <DurationStatistic
              trainId={params.trainId}
              trainStatus={trainStatus}
              trials={trials}
              setTrials={setTrials}
            />
          </Row>
          {train.project && (
            <ProjectDetail
              project={train.project}
              visible={projectModalShown}
              onCancel={() => {
                setProjectModalShown(false);
              }}
            />
          )}
        </>
      ) : (
        <Empty description="暂无数据" />
      )}
    </Skeleton>
  );
};

export default TrainDetail;
