import {
  CheckCircleOutlined,
  DatabaseOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { RouteType } from '@/components/routers/types';
import Model from './model';
import Dataset from './dataset';
import Account from './account';
import Setting from './setting';
import AlgorithmSetting from './setting/Algorithm';

const dashboardRoutes: RouteType[] = [
  {
    key: 'dataset',
    path: '/manage-dataset',
    component: Dataset,
    sidebarName: '数据集',
    icon: <DatabaseOutlined />,
    permission: 'dataset'
  },
  {
    key: 'model',
    path: '/manage-model',
    component: Model,
    sidebarName: '模型',
    icon: <CheckCircleOutlined />,
    permission: 'model'
  },
  {
    key: 'account',
    path: '/manage-account',
    component: Account,
    sidebarName: '用户',
    icon: <UserOutlined />,
    permission: 'account'
  },
  {
    key: 'account',
    path: '/setting',
    component: Setting,
    sidebarName: '设置',
    icon: <SettingOutlined />,
    permission: 'account',
    children: [
      {
        key: 'account',
        path: '/manage-algorithm',
        component: AlgorithmSetting,
        sidebarName: '算法设置',
        permission: 'account'
      }
    ]
  },
  {
    key: 'redirect',
    redirect: true,
    path: '/',
    to: '/manage-dataset'
  }
];

export default dashboardRoutes;
