import { FC } from 'react';
import { AlgorithmType } from '@/apps/dashboard/model/modelCreate/types';
import { PropertyType } from '@/components/properties/Properties';
import { DescCard, Link } from '@/components';

interface Props {
  algorithm: AlgorithmType;
}

const InputType = {
  file: '文件',
  text: '文本'
};

const AlgorithmCard: FC<Props> = ({ algorithm }) => {
  const { algorithmId, title, name, inputType, description } = algorithm;
  const properties: PropertyType[] = [
    {
      key: 'id',
      title: 'id',
      content: algorithmId
    },
    {
      key: 'name',
      title: '名称',
      content: name
    },
    {
      key: 'inputType',
      title: '类型',
      content: InputType[inputType]
    },
    {
      key: 'description',
      title: '描述',
      content: description
    }
  ];
  return (
    <DescCard
      className="card"
      title={title}
      key={algorithmId}
      properties={properties}
      actions={[
        <Link to={`${algorithmId}/description`}>算法介绍</Link>,
        <Link to={`${algorithmId}/parameter`}>参数设置</Link>
      ]}
    />
  );
};

export default AlgorithmCard;
