import { Button, Form, Input, Select, Typography, Modal } from 'antd';
import { useState } from 'react';
import { Copy, PageWrapper } from '@/components';
import { FormWrapper } from './styles';
import request from '@/apps/utils';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 12
  }
};

const CreateAccount = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (values: any) => {
    try {
      const body = JSON.stringify(values);
      request({
        url: '/account',
        config: { method: 'POST', body },
        messageTitle: '新建账号'
      })
        .then(({ data }) => {
          form.resetFields();
          Modal.success({
            title: '新建账号成功',
            content: (
              <>
                账号{data.email}（{data.displayName}）的密码为
                <Copy content={data.initialPassword} />
                ，请妥善保存，通知该用户及时登录并修改密码。
              </>
            )
          });
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setSubmitting(false);
        });
    } catch (err) {
      setSubmitting(false);
      console.log(err);
    }
  };

  return (
    <PageWrapper
      headerProps={{
        title: '新建账号'
      }}
    >
      <FormWrapper>
        <Form
          {...layout}
          form={form}
          size="large"
          onFinish={(values) => handleSubmit(values)}
        >
          <Form.Item
            name="email"
            label={<Typography.Text type="secondary">邮箱</Typography.Text>}
            rules={[
              {
                required: true,
                type: 'email',
                message: '请输入邮箱'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="displayName"
            label={<Typography.Text type="secondary">姓名</Typography.Text>}
            rules={[
              {
                required: true,
                message: '请输入姓名'
              },
              {
                max: 16,
                message: '姓名不能超过16个字符，请重新输入'
              },
              {
                pattern: /^([\u4e00-\u9fa5\w.@-]+\s)*[\u4e00-\u9fa5\w.@-]+$/,
                message:
                  '长度为1~16个字符并且首尾不能为空格。由中文、大小写字母、数字、特殊符号（-_.@和空格，但不包含连续空格）四类字符中某一类或多个类别组成。'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label={<Typography.Text type="secondary">角色</Typography.Text>}
            rules={[
              {
                required: true,
                message: '请选择角色'
              }
            ]}
          >
            <Select
              options={[
                { label: '管理员', value: 'admin' },
                { label: '用户', value: 'user' },
                { label: '访客', value: 'guest' }
              ]}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 20 }}>
            <Button type="primary" htmlType="submit" disabled={submitting}>
              {submitting ? '保存中...' : '保存'}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};

export default CreateAccount;
