import { ReactNode, FC } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '@/apps/authentication/hooks';
import { ComponentRoute } from './types';

interface PrivateRouteProps {
  path: string;
  route: ComponentRoute;
  checkPermission: (userPermissions: string[]) => boolean | undefined;
  noMatch?: ReactNode;
  permissions?: string[];
  routeProp?: object;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  route,
  noMatch,
  routeProp,
  checkPermission,
  ...rest
}) => {
  const { currentUser } = useAuth();
  return (
    <Route
      {...rest}
      render={(routeComponentProps: RouteComponentProps) =>
        checkPermission(currentUser?.permissions || []) ? (
          <route.component {...routeComponentProps} {...routeProp} />
        ) : (
          noMatch || (
            <Redirect
              to={`/manage-${currentUser?.permissions[0] || 'model'}`}
            />
          )
        )
      }
    />
  );
};

export default PrivateRoute;
