import { Tooltip } from 'antd';
import { FC, ReactNode, useCallback, useState } from 'react';
import { Wrapper, ToolboxItem } from './styles';

type BaseToolboxItem = {
  key: string;
  icon: ReactNode;
  tooltip?: string;
};

type NormalToolboxItemType = {
  toggle?: false;
  onClick?: () => unknown;
} & BaseToolboxItem;

type ToggleToolboxItemType = {
  toggle: true;
  activeIcon?: ReactNode;
  activeTooltip?: string;
  onClick?: () => unknown;
} & BaseToolboxItem;

export type ToolboxItemType = NormalToolboxItemType | ToggleToolboxItemType;

type ToolboxProps = {
  items: ToolboxItemType[];
  reversed?: boolean;
  className?: string;
};

const Toolbox: FC<ToolboxProps> = ({ items, reversed, className }) => {
  const [activeStatus, setActiveStatus] = useState<boolean[]>(
    new Array(items.length).fill(false)
  );

  const onClick = useCallback(
    (index: number) => {
      const item = items[index];
      if (item.toggle) {
        item.onClick?.();
        setActiveStatus((m) => {
          const n = [...m];
          n.splice(index, 1, !m[index]);
          return n;
        });
      } else {
        item.onClick?.();
      }
    },
    [items, activeStatus]
  );

  return (
    <Wrapper className={className} reversed={reversed}>
      {items.map((item, index) => {
        return (
          <Tooltip title={item.tooltip} key={item.key}>
            <ToolboxItem
              reversed={reversed}
              active={item.toggle && !item.activeIcon && activeStatus[index]}
              onClick={() => onClick(index)}
            >
              {item.icon}
            </ToolboxItem>
          </Tooltip>
        );
      })}
    </Wrapper>
  );
};

export default Toolbox;
