import styled from '@/theme';

export const Wrapper = styled.div<{ reversed?: boolean }>`
  font-size: 16px;
  line-height: 1;
  display: flex;
  flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
  align-items: center;
`;

export const ToolboxItem = styled.a<{ active?: boolean; reversed?: boolean }>`
  cursor: pointer;
  color: ${(props) => props.theme.palette.typography.text.color};
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
  & + & {
    ${(props) => `margin-${props.reversed ? 'right' : 'left'}: 12px};`}
  }
`;
