import { Layout } from 'antd';
import styled from '@/theme';

export const AppsWrapper = styled(Layout)`
  min-height: 100vh;
`;

export const SiteLayout = styled(Layout)`
  width: fit-content !important;
`;

export const AppsMainContent = styled(Layout.Content)`
  min-width: 1200px;
  margin: ${(props) => 6 * props.theme.spacing}px;
`;
