import {
  Button as ButtonBase,
  Progress as ProgressBase,
  Spin as SpinBase
} from 'antd';
import {
  HourglassOutlined,
  SyncOutlined,
  PlusOutlined,
  StopOutlined
} from '@ant-design/icons';
import styled from '@/theme';

export const Button = styled(ButtonBase).attrs({ type: 'primary' })``;

export const ButtonWrapper = styled.div`
  margin-bottom: ${(props) => 4 * props.theme.spacing}px;
  float: right;
  margin-left: auto;
  display: flex;
`;

export const AddIcon = styled(PlusOutlined)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .card {
    margin-bottom: ${(props) => 4 * props.theme.spacing}px;
    &:last-child {
      margin-bottom: 0;
    }
    .properties {
      width: 80%;
    }
  }
  .cardBody {
    display: flex;
  }
`;

export const WaitingIcon = styled(HourglassOutlined).attrs({ spin: true })`
  font-size: 16px;
  margin-right: ${(props) => 2 * props.theme.spacing}px;
`;

export const Progress = styled(ProgressBase)``;

export const Spin = styled(SpinBase)``;

export const Stop = styled(StopOutlined)`
  font-size: 70px;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const Retry = styled(SyncOutlined)`
  font-size: 70px;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const ExtraWrapper = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.45);
`;

export const StoppedWrapper = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  border: 4px solid ${(props) => props.theme.palette.primary.error};
  position: relative;
  .text {
    font-size: 16.5px;
    position: absolute;
    left: 50%;
    top: 50%;
    white-space: normal;
    text-align: center;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.palette.primary.error};
  }
`;

export const ModelWrapper = styled.div`
  .ul {
    max-height: 300px;
    overflow: scroll;
  }
`;
