/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { NameValues, ArgumentType, PropertyType } from '../types';

import Argument from './Argument';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > .property-name {
    width: 80px;
    flex: none;
    text-align: right;
    padding: 8px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > .property-values {
    margin-left: 12px;
    flex: auto;
    width: 244px;
  }

  & + & {
    margin-top: 12px;
  }
`;

type PropertyProps = NameValues<ArgumentType | PropertyType> & {
  expand?: boolean;
  showNodeDocumentation?: () => unknown;
};

const Property: FunctionComponent<PropertyProps> = ({
  name,
  values,
  expand,
  showNodeDocumentation
}) => {
  return (
    <Wrapper>
      <label className="property-name" title={name}>
        {name}
      </label>
      <div className="property-values">
        {values.map((value, index) => {
          return (
            <Argument
              key={index}
              value={value}
              expand={expand}
              showNodeDocumentation={showNodeDocumentation}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Property;
