import { ReactNode } from 'react';
import { Typography, Divider, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Wrapper, Spacing, TitleWrapper } from './styles';

interface TitleProps {
  title: ReactNode;
  children: ReactNode;
  divider?: boolean;
  className?: string;
  tooltip?: string;
  id?: string;
}

const Title = (props: TitleProps) => {
  const { children, title, className, divider = true, tooltip, id } = props;
  return (
    <Wrapper id={id} className={className}>
      {divider ? <Divider /> : <Spacing />}
      <TitleWrapper>
        <Typography.Title level={5}>{title}</Typography.Title>
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined className="icon" />
          </Tooltip>
        )}
      </TitleWrapper>
      {children}
    </Wrapper>
  );
};

export default Title;
